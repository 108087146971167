import React, { useContext } from "react"
import { Context } from "./upload_template_container.jsx"
import css from "./upload_template.module.css"
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Upload } from 'react-bootstrap-icons';
import Spinner from 'react-bootstrap/Spinner';

const Uploadtemplate = () => {

    const { types, topics, handleSubmit, errors, form, setField, isUploadingFetch, isCategoriesFetch } = useContext(Context)

    return (
        !isCategoriesFetch ?
            <Form noValidate onSubmit={handleSubmit} className={css.form} >
                <h4 className={css.title}>Upload template</h4>
                <Form.Group className={css.input} controlId="name">
                    <Form.Label>Name*</Form.Label>
                    <Form.Control
                        value={form.name} 
                        onChange={e => setField('name', e.target.value)}
                        isValid={form.name && form.name !== '' && !errors.name}
                        isInvalid={!!errors.name}
                        placeholder="Enter name"
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className={css.input} controlId="price">
                    <Form.Label>Price*</Form.Label>
                    <Form.Control
                        type="number"
                        min="0"
                        placeholder="Enter price"
                        value={form.price}
                        onChange={e => setField('price', e.target.value)}
                        isValid={form.price && form.price !== '' && !errors.price}
                        isInvalid={!!errors.price}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">{errors.price}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="file" className={css.input}>
                    <Form.Label>File*</Form.Label>
                    <Form.Control
                        type="file"
                        onChange={e => setField('file', e.target.files[0])}
                        isValid={form.file && form.file !== '' && !errors.file}
                        isInvalid={!!errors.file}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">{errors.file}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className={css.input} controlId="version">
                        <Form.Label>Version</Form.Label>
                        <Form.Control
                            value={form.version}
                            onChange={e => setField('version', e.target.value)}
                            isValid={form.version !== undefined && form.version !== '' && !errors.version}
                            isInvalid={!!errors.version}
                            placeholder="Enter version"
                        />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">{errors.version}</Form.Control.Feedback>
                </Form.Group>
                <Form.Select
                    className={css.select}
                    value={form.type_id}
                    onChange={e => setField('type_id', e.target.value)}
                    isValid={form.type_id && form.type_id !== 0 && !errors.type_id}
                    isInvalid={!!errors.type_id}
                >
                    {[
                        { id: 0, name: 'Select type' },
                        ...types.map(el => ({ ...el }))
                    ].map((el, ind) => (
                        <option hidden={el.id === 0} key={ind} value={el.id}>{el.name}</option>
                    ))}
                </Form.Select>
                <Form.Select
                    className={css.select}
                    value={form.topic_id}
                    onChange={e => setField('topic_id', e.target.value)}
                    isValid={form.topic_id && form.topic_id !== 0 && !errors.topic_id}
                    isInvalid={!!errors.topic_id}
                >
                    {[
                        { id: 0, name: 'Select topic' },
                        ...topics.map(el => ({ ...el }))
                    ].map((el, ind) => (
                        <option hidden={el.id === 0} key={ind} value={el.id}>{el.name}</option>
                    ))}
                </Form.Select>
                <Form.Group className={css.input} controlId="description">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                        as="textarea"
                        placeholder="Enter Description"
                        value={form.description}
                        onChange={e => setField('description', e.target.value)}
                        isValid={form.description && form.description !== '' && !errors.description}
                        isInvalid={!!errors.description}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">{errors.description}</Form.Control.Feedback>
                </Form.Group>
                <Button disabled={isUploadingFetch} variant="primary" className={css.btn_submit} type="submit"><div className={css.btn_submit_inner}>Upload{isUploadingFetch ? <Spinner size="sm" /> : <Upload size="1.25rem" />}</div></Button>
                <Form.Text >
                    <br />After submission, your product will be subject to review for some time.
                </Form.Text>
            </Form>
            : 
            <div className={css.info_fetch}>
                <Spinner size="lg" />
            </div>
    )
}

export default Uploadtemplate;
 