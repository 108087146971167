import React, { useContext } from "react"
import css from "./header.module.css"
import Nav from 'react-bootstrap/Nav';
import LogoImg from '../../images/logo_white.png'
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import { Cart, PersonCircle } from 'react-bootstrap-icons';
import { Link } from "react-router-dom";
import { Context } from "./header_container";
import Spinner from "react-bootstrap/Spinner";

const Header = () => { 

    const { name, email, isAuth, isAuthFetch } = useContext(Context)

    return (
        <Navbar collapseOnSelect={false} sticky="top" expand="md" data-bs-theme="dark" className={css.navbar}>
            <Container className={css.container}>
                <Navbar.Brand>
                    <Image src={LogoImg} className={css.logo_img}></Image>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse className={css.collapse} id="responsive-navbar-nav">
                    <Nav className={`me-auto ${css.nav}`}>
                        <Nav.Link as={Link} className={css.link} to="/">Main</Nav.Link>
                        <Nav.Link as={Link} className={css.link} to="/categories?page=1">Categories</Nav.Link>
                        <Nav.Link as={Link} className={css.link} to="/about-us">About us</Nav.Link>
                        <Nav.Link as={Link} className={css.link} to="/support">Support</Nav.Link>
                    </Nav>
                    <Nav className={css.nav}>
                        <Nav.Link as={Link} className={css.link} to={isAuth ? "/basket" : "/login"}>
                            <Cart size="1.75rem" className={css.basket_img} />
                            Cart
                        </Nav.Link>
                        {!isAuthFetch 
                            ?
                            <Nav.Link as={Link} className={`${css.name}`} to={isAuth ? "/account?likes=1" : "/login"}>
                                <PersonCircle size="1.75rem" className={css.acc_img} /><div className={css.name_inner}>{isAuth ? name == "" ? email : name : "Log/Reg"}</div>
                            </Nav.Link>
                            :
                            <Nav.Link className={css.spinner_wrap}>
                                <Spinner className={css.spinner} size="sm" />
                            </Nav.Link>
                        }
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default Header