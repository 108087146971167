import { configureStore } from "@reduxjs/toolkit"
import authSlice from "./slices/authSlice.js"
import categoriesSlice from "./slices/categoriesSlice.js"
import basketSlice from "./slices/basketSlice.js"
import accountSlice from "./slices/accountSlice.js"
import adminSlice from "./slices/adminSlice.js"
import toastsSlice from "./slices/toastsSlice.js"
import constantsSlice from "./slices/constantsSlice.js"
 
export const store = configureStore({
     reducer: {
          auth: authSlice,
          account: accountSlice,
          admin: adminSlice,
          categories: categoriesSlice,
          basket: basketSlice,
          toasts: toastsSlice,
          constants: constantsSlice
     },
     middleware: (getDefaultMiddleware) =>
     getDefaultMiddleware({
       serializableCheck: false,
     }),
}) 
