import React, { useContext } from "react"
import { Context } from "./templates_container.jsx"
import css from "./templates.module.css"
import RegisterAuthorContainer from "./register_author/register_author_container.jsx"
import TemplatesMap from "./templates_map/templates_map.jsx"
import Button from 'react-bootstrap/Button';
import { Upload, CreditCard, PencilSquare, HourglassSplit } from 'react-bootstrap-icons';
import Badge from 'react-bootstrap/Badge';
import Placeholder from 'react-bootstrap/Placeholder';
import { Link } from "react-router-dom";
import Notification from './notification/notification.jsx'
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';

const Templates = () => {

    const { isAuthorRegistered, uploadStatus, balance, currency, isAuthFetch, productsNotFound, isFetch, isInfoFetch, isUploadStatusFetch,
        products, isProductDeletingFetch, isAuth, isStatusFetch, status, infoModalShow, setInfoModalShow,
        details, convertedCreated, dispatchApiGetAuthorStripeLink, isEditingFetch, isProductsFetch
    } = useContext(Context) 

    const InfoModal = () => { 
        return (
            <Modal
                show={infoModalShow}
                onHide={() => setInfoModalShow(false)}
                size="md"
                className={css.modal}
                centered
                animation={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Information
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body> 
                    {details.individual?.account && <div className={css.line}><span>Author ID </span>{details.individual.account}</div>}
                    <div className={css.line}><span>Created </span> {convertedCreated}</div>
                    <div className={css.payouts}>
                        <div className={css.payouts_header}>Payouts<CreditCard /></div>
                        <hr /> 
                        <div className={css.line}><span>Interval </span> {details.settings.payouts.schedule.interval}</div>
                        <div className={css.line}><span>Delay days </span> {details.settings.payouts.schedule.delay_days}</div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={isEditingFetch} className={css.edit_btn} variant="primary" onClick={() => dispatchApiGetAuthorStripeLink("edit")}>Edit{isEditingFetch ? <Spinner size="sm" /> : <PencilSquare size="1.25rem" />}</Button>
                    <Button className="btn-prime" onClick={() => setInfoModalShow(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    return (
        <>
            {!isAuthFetch ?
                isAuthorRegistered && isAuth ?
                    <>
                        <div className={css.info}>
                            <div className={css.count}>Items created:{!isProductsFetch && !isProductDeletingFetch && !isFetch ? <Badge bg="primary">{products?.length}</Badge> : <Placeholder as={Badge}>0</Placeholder>}</div>
                            {!isUploadStatusFetch && !isFetch && (status == "completed" || status == "enabled") ?
                                uploadStatus == "no_author_offer_upload" ?
                                <Button as={Link} to="/account/upload" variant="primary" className={css.upload_btn}>Upload<Upload size="1.25rem" /></Button>
                                :
                                <Button disabled variant="outline-primary" className={css.upload_btn}>Under review<HourglassSplit size="1.25rem" /></Button>
                                :
                                <Button disabled variant="primary" className={css.upload_btn}>Upload<Upload size="1.25rem" /></Button>
                            }
                            {!isInfoFetch && !isFetch ?
                                <div className={css.balance}>Balance: {balance + " " + currency}</div>
                                :
                                <Placeholder as="div" className={css.balance} animation="glow">
                                    Balance:<Placeholder className={css.balance_placeholder} />
                                </Placeholder>
                            }
                            <Button disabled={isInfoFetch || isFetch} onClick={() => setInfoModalShow(true)} className="btn-prime">Info</Button>
                        </div>
                        {!isFetch && !isStatusFetch ? <Notification /> : <></>}
                        {!productsNotFound || isFetch ?
                            <TemplatesMap />
                            :
                            <div className={css.not_found}>
                                <h4>You have no created products.</h4>
                            </div>
                        }
                    </>
                    :
                    <RegisterAuthorContainer />
                :
                <div className={css.author_fetch}>
                    <Spinner size="lg" />
                </div>}
            <InfoModal />
        </>
    )
}


export default Templates  