import React, { useContext } from "react";
import { Context } from "./account_container.jsx"
import css from "./account.module.css";
import { Link, Outlet, useLocation } from "react-router-dom";
import Nav from 'react-bootstrap/Nav';

const Account = () => {

    const { activeKey } = useContext(Context)

    return (
        <>
            <Nav className={css.nav} variant="tabs" activeKey={activeKey}>
                <Nav.Item className={css.item}>
                    <Nav.Link as={Link} to="/account?likes=1" eventKey="">Profile</Nav.Link>
                </Nav.Item>
                <Nav.Item className={css.item}>
                    <Nav.Link as={Link} to="/account/purchased?page=1" eventKey="purchased">Purchased</Nav.Link>
                </Nav.Item> 
                <Nav.Item className={css.item}>
                    <Nav.Link as={Link} to="/account/author?page=1" eventKey="author">Author</Nav.Link>
                </Nav.Item>
                <Nav.Item className={css.item}>
                    <Nav.Link as={Link} to="/account/settings" eventKey="settings">Settings</Nav.Link>
                </Nav.Item>
            </Nav>

            <Outlet className={css.outlet} /> 
        </>
    );
}

export default Account;
