import React, { useContext } from "react";
import css from "./types_and_topics.module.css"
import { Link } from "react-router-dom";
import TypesAndTopicsMap from "./types_and_topics_map/types_and_topics_map.jsx"
import Pagination from 'react-bootstrap/Pagination';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import { Context } from "./types_and_topics_container.jsx";
import Spinner from "react-bootstrap/Spinner";

const TypesAndTopics = () => {

    const { setCategorie, pageFirst, pagePrev, pageSelect, pageNext, pageLast,
        queryPage, pages, categorie, categoriesNotFound, isCategoriesFetch } = useContext(Context)

    return (
        <>
            <div className={css.control}>
                <Form.Select
                    className={css.select}
                    value={categorie}
                    onChange={e => setCategorie(e.target.value)}
                >
                    {[
                        { n: 'Types', v: 'type' },
                        { n: 'Topics', v: 'topic' },
                    ].map((el, ind) => (
                        <option key={ind} value={el.v}>{el.n}</option>
                    ))}
                </Form.Select>
                <Button variant="primary" className={css.add_btn} as={Link} to="/admin/templates/typesandtopics/add">Add categorie</Button>
            </div>
            {!categoriesNotFound ?
                !isCategoriesFetch ?
                    <>
                        <div className={css.table_wrap}>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>#ID</th>
                                        <th>Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <TypesAndTopicsMap />
                                </tbody>
                            </Table>
                        </div>
                        <Pagination className={css.pagination}>
                            {queryPage > 1 &&
                                <>
                                    <Pagination.First className={css.pag_item} onClick={() => pageFirst()} />
                                    <Pagination.Prev className={css.pag_item} onClick={() => pagePrev()} />
                                </>
                            }
                            {pages
                                .filter(p => p >= +queryPage - 2 && p <= +queryPage + 2)
                                .map(p => (
                                    <Pagination.Item className={queryPage == p ? css.pag_item_active : css.pag_item} onClick={() => pageSelect(p)} key={p}>{p}</Pagination.Item>
                                ))
                            }
                            {queryPage < pages.length &&
                                <>
                                    <Pagination.Next className={css.pag_item} onClick={() => pageNext()} />
                                    <Pagination.Last className={css.pag_item} onClick={() => pageLast()} />
                                </>
                            }
                        </Pagination>
                    </>
                    :
                    <div className={css.spinner_wrap}>
                        <Spinner size="lg" />
                    </div>
                :
                <div className={css.not_found}>
                    <h4>Categories not found.</h4>
                </div>}
        </>
    )
}

export default TypesAndTopics