import React, { useContext } from "react";
import { Context } from "../profile_container.jsx"
import css from "./profile_history_map.module.css"
import ListGroup from 'react-bootstrap/ListGroup';
import { Link } from 'react-router-dom'
import Nav from 'react-bootstrap/Nav';
import Placeholder from 'react-bootstrap/Placeholder';

const ProfileHistoryMap = () => {

    const { historyConverted, isHistoryFetch, historyPlaceholders, isFetch } = useContext(Context)

    return (
        !isHistoryFetch && !isFetch ?  
        historyConverted.map(p =>
            <ListGroup.Item key={p.id} className={css.item}>
                <Nav.Link className={`link ${css.name}`} as={Link} to={`/categories/product?id=${p.id}`}>{p.name}</Nav.Link>
                <span className={css.created}>{p.created}</span>
            </ListGroup.Item>
        )
          :
          Array.from({ length: historyPlaceholders }).map((_, i) =>
            <ListGroup.Item key={i} className={css.item}>
            <Placeholder className={`link ${css.name_placeholder}`} as={Nav.Link} animation="glow">
                <Placeholder xs={i % 2 == 0 ? 6 : i % 3 == 0 ? 7 : 8} />  
            </Placeholder>  
            <Placeholder className={css.created_placeholder} as="span" animation="glow">
                 <Placeholder xs={8} />
            </Placeholder>
        </ListGroup.Item> 
        )   
    )
}
 
export default ProfileHistoryMap 