import React, { createContext, useEffect, useRef } from "react"
import Categories from "./categories.jsx"
import { useDispatch, useSelector } from "react-redux"
import { apiGetProducts, apiGetCategories, addCategorie, deleteCategorie, setSearch, setSort } from "../../../store/slices/categoriesSlice.js"
import { apiAddProductToBasket, setAddingId } from "../../../store/slices/basketSlice"
import useAuthRedirect from "../../../middleware/isAuth.jsx"
import { useSearchParams } from 'react-router-dom'

const Context = createContext() 
 
const CategoriesContainer = () => {

        const dispatch = useDispatch()

        const { isAuthDispatch } = useAuthRedirect() 
    
        const {
            products: { limit, products, count, productsNotFound,
                setting: { categories, search, sort },
                navigation: { types, topics }, 
                placeholders: { productsPlaceholders, typesPlaceholders, topicsPlaceholders },
                connection: { isProductsFetch, isCategoriesFetch },
            },
        } = useSelector((state) => state.categories);
    
        const { addingId, connection: { isAddingFetch } } = useSelector((state) => state.basket)
    
        const dispatchApiGetProducts = (search, categories, sort, offset, limit) => {
            dispatch(apiGetProducts({ search, categories, sort, offset, limit }))
        }
    
        const dispatchApiGetCategories = () => {
            dispatch(apiGetCategories())
        }
    
        const dispatchSetSearch = (search) => {
            dispatch(setSearch(search))
        }
    
        const dispatchSetSort = (sort) => {
            dispatch(setSort(sort))
        }
    
        const dispatchAddCategorie = (categorie, categorie_id, name) => {
            dispatch(addCategorie({categorie, categorie_id, name}))
        }
    
        const dispatchDeleteCategorie = (name) => {
            dispatch(deleteCategorie(name))
        }
    
        const dispatchApiAddProductToBasket = (id) => {
            isAuthDispatch(apiAddProductToBasket, { id }) 
        }
    
        const dispatchSetAddingId = (id) => {
            dispatch(setAddingId(id))
        }

        const [searchParams, setSearchParams] = useSearchParams()

        const handleSearchParams = (page) => {
            setSearchParams({ page: page })
        } 

        let queryPage = searchParams.get("page")
    
        let pagesCount = Math.ceil(count / limit)
    
        const offset = (+queryPage - 1) * limit
    
        let pages = [];
        for (let i = 1; i <= pagesCount; i++) {
            pages.push(i);
        }

        useEffect(() => { 
            setTimeout(() => {window.scrollTo(0, 0)}, 0)
            dispatchApiGetCategories()
        }, [])
    
        useEffect(() => {
            dispatchApiGetProducts(search, categories, sort, offset, limit)
        }, [searchParams, sort, categories])

        const focusedElementRef = useRef(null);
    
        const handleFocusCategorie = (event) => {
            focusedElementRef.current = event.currentTarget;
        };
    
        const handleDeleteCategorie = (name) => {
            if (focusedElementRef.current) {
                focusedElementRef.current.blur();
            }
            dispatchDeleteCategorie(name);
            setSearchParams({ page: 1 });
        };
     
        function pageFirst() {
            setSearchParams({ page: 1 })
            setTimeout(() => {window.scrollTo(0, 0)}, 0)
        }
    
        function pagePrev() {
            setSearchParams({ page: +queryPage - 1 })
            setTimeout(() => {window.scrollTo(0, 0)}, 0)
        }
    
        function pageSelect(p) {
            setSearchParams({ page: queryPage = p })
            setTimeout(() => {window.scrollTo(0, 0)}, 0)
        }
    
        function pageNext() {
            setSearchParams({ page: +queryPage + 1 })
            setTimeout(() => {window.scrollTo(0, 0)}, 0)
        }
    
        function pageLast() {
            setSearchParams({ page: pagesCount })
            setTimeout(() => {window.scrollTo(0, 0)}, 0)
        }

    const contextData = { dispatchApiGetProducts, dispatchApiGetCategories, dispatchSetSearch, dispatchSetSort, dispatchAddCategorie,
        dispatchDeleteCategorie, dispatchApiAddProductToBasket, dispatchSetAddingId, categories, search, sort, limit,
        products, productsNotFound, types, topics, isProductsFetch, isCategoriesFetch, productsPlaceholders,
        typesPlaceholders, topicsPlaceholders, addingId, isAddingFetch, handleSearchParams, queryPage, offset, pages,
        handleFocusCategorie, handleDeleteCategorie, pageFirst, pagePrev, pageSelect, pageNext, pageLast }

    return (
        <Context.Provider value={contextData}>
            <Categories />
        </Context.Provider>
    )
}

export { Context }
export default CategoriesContainer