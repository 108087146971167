import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    form: {
        max_user_name: 24,
        min_user_name: 6,

        max_password: 16,
        min_password: 4,

        max_product_name: 100,

        max_price: 100,
        min_price: 5,

        max_discount: 100,

        max_description: 500,

        max_categorie: 36,

        max_file_bytes: 500000000,
        max_file_megabytes: 500,
        file_extension: ["rar", "zip", "7z"] 
    }
}

export const constantsSlice = createSlice({
    name: "constants",
    initialState,
})  


export default constantsSlice.reducer 