import React, { createContext, useEffect, useState } from "react"
import EmailVerify from "./email_verify.jsx"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "../../../../../../store/slices/toastsSlice.js"
import { apiEmailVerification } from "../../../../../../store/slices/authSlice.js"
import { unwrapResult } from "@reduxjs/toolkit"
import { useNavigate } from "react-router-dom"

const Context = createContext()

const EmailVerifyContainer = () => {
 
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const {
        connection: { isEmailVerificationFetch }
    } = useSelector((state) => state.auth);
    

    const [form, setForm] = useState({})
    const [errors, setErrors] = useState({})

    const dispatchApiEmailVerification = () => { 
        return dispatch(apiEmailVerification(form))
    }

    const setField = (field, value) => {
        setForm({
            ...form,
            [field]: value
        });

        const fieldErrors = validateField(field, value);

        setErrors({
            ...errors,
            [field]: fieldErrors[field]
        });
    }

    const validateField = (field, value) => {
        const newError = {};

        switch (field) {
            case 'email':
                if (value == undefined || value == '') newError.email = 'Please enter email'
                else if (value && !value.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) newError.email = 'The entered email is not valid'
                break;
        }

        return newError;
    }

    const validateForm = () => {
        const newErrors = {};

        const fields = ['email'];

        fields.forEach(field => {
            const fieldErrors = validateField(field, form[field]);
            Object.assign(newErrors, fieldErrors);
        });

        return newErrors;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation()

        const formErrors = validateForm()

        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors)
            dispatch(toast("warning", "Invalid form"))
        }
         else {
            const actionRes = await dispatchApiEmailVerification()
            const promiseRes = unwrapResult(actionRes)

            const setError = (err) => {
                setErrors(err)
                dispatch(toast("warning", "Invalid form"))
            }

            switch (promiseRes.status) {
                case "email_not_found":
                    setError({email: "Email not found"})
                    break
                case "email_found_another_method":
                    setError({email: "This email uses a different authentication method"})
                    break
                case "attempts_exceeded":
                    dispatch(toast("warning", "Number of attempts exceeded. Try again later"))
                    navigate('/login')
                    break
                case "success":
                    navigate('/login/verify/check')
                    break
            } 
        }
    }

    useEffect(() => {
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
    }, [])

    const contextData = { handleSubmit, form, errors, setField, isEmailVerificationFetch }

    return (
        <Context.Provider value={contextData}>
            <EmailVerify />
        </Context.Provider>
    )
}

export { Context }
export default EmailVerifyContainer