import React, { createContext, useEffect, useState } from "react"
import Login from "./login.jsx"
import { apiLogin, apiIsUser } from "../../../../store/slices/authSlice"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { toast } from "../../../../store/slices/toastsSlice.js"
import { unwrapResult } from "@reduxjs/toolkit"
import { useGoogleLogin } from "@react-oauth/google"

const Context = createContext()

const LoginContainer = () => {

    const dispatch = useDispatch()

    const navigate = useNavigate() 

    const {
       connection: { isLoginingFetch }
    } = useSelector((state) => state.auth);

    const { 
        form: {
           max_password, min_password,
        }
    } = useSelector((state) => state.constants)

    const [form, setForm] = useState({
        auth_method: "default"
    })
    const [errors, setErrors] = useState({}) 

    const setField = (field, value) => {
        setForm({
            ...form,
            [field]: value
        });

        const fieldErrors = validateField(field, value);
        
        setErrors({
            ...errors,
            [field]: fieldErrors[field]
        });
    }

    const validateField = (field, value) => {
        const newError = {};
     
        switch (field) {
            case 'email':
                if (value == undefined || value == '') newError.email = 'Please enter email'
                else if (value && !value.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) newError.email = 'The entered email is not valid'
                break;
            case 'password':
                if (value == undefined || value == '') newError.password = 'Please enter password' 
                else if (value && value.length < min_password) newError.password = `Old password must be more than ${min_password} characters.`
                else if (value && value.length > max_password) newError.password = `Old password must be less than ${max_password} characters.`
                break;
        }
     
        return newError;
    }
    
    const validateForm = () => {
        const newErrors = {};
    
        const fields = ['email', 'password'];
    
        fields.forEach(field => {
            const fieldErrors = validateField(field, form[field]);
            Object.assign(newErrors, fieldErrors);
        });
    
        return newErrors;
    }

    const dispatchApiLogin = (form) => { 
        return dispatch(apiLogin(form))
    }

    const dispatchApiIsUser = () => { 
        return dispatch(apiIsUser()) 
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        const formErrors = validateForm()

        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors)
            dispatch(toast("warning", "Invalid form"))
        } else { 
            const actionRes = await dispatchApiLogin(form) 
            const promiseRes = unwrapResult(actionRes)

            const setError = (err) => {
                setErrors(err)
                dispatch(toast("warning", "Invalid form"))
            }

            switch (promiseRes.status) { 
                case "email_not_found":
                    setError({email: "Email not found"})
                    break
                case "email_found_another_method":
                    setError({email: "This email uses a different authentication method"})
                    break
                case "not_verified":
                    dispatch(toast("warning", "Email not verified"))
                    break
                case "incorrect_password":
                    setError({password: "Incorrect password"})
                    break
                case "success":
                    await dispatchApiIsUser()  
                    navigate('/account?likes=1')
                    break
            }
        }
    } 

    const googleLogin = useGoogleLogin({
        onSuccess: async tokenResponse => { 

            const actionRes = await dispatchApiLogin({ token: tokenResponse.access_token, auth_method: "google" })
            const promiseRes = unwrapResult(actionRes)

            switch (promiseRes.status) { 
                case "email_not_found":
                    dispatch(toast("warning", "Email not found"))
                    break
                case "email_found_another_method":
                    dispatch(toast("warning", "This email uses a different authentication method"))
                    break
                case "not_verified":
                    dispatch(toast("warning", "Email not verified"))
                    break
                case "success":
                    await dispatchApiIsUser() 
                    navigate('/account?likes=1')
                    break
            } 

        }
      })

    useEffect(() => {
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
    }, [])

    const contextData = { handleSubmit, form, errors, setField, isLoginingFetch, googleLogin }

    return (
        <Context.Provider value={contextData}>
            <Login />
        </Context.Provider>
    )
}

export { Context }
export default LoginContainer