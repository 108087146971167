import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { authApi, accountApi } from "../../api/api.js"
import { toast } from "./toastsSlice.js"
import handleError from "./error/error.js"

const initialState = {
    id: "",
    name: "",
    email: "",
    isAuth: false,
    isAuthorRegistered: false,
    auth_method: "",
    authMethods: {
        default: "default",
        google: "google"
    },
    connection: {
        isAuthFetch: true,
        isRegisteringFetch: false,
        isRegisterVerificationFetch: false,
        isEmailVerificationFetch: false,
        isPasswordRecoveringFetch: false,
        isLoginingFetch: false,
        isLogoutingFetch: false
    }
}

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: { 
        setIsAuth: (state, action) => {
            state.isAuth = action.payload
        },
        setIsAuthorRegistered: (state, action) => {
            state.isAuthorRegistered = action.payload
        },
    },
    extraReducers(builder) {
        builder
            .addCase(apiIsUser.pending, (state, _) => {
                state.isAuth = false
                state.connection.isAuthFetch = true
            })
            .addCase(apiIsUser.fulfilled, (state, action) => { 
                if(action.payload.status == "success") {
                    const { user, author } = action.payload.data
                    Object.assign(state, user)
                    if(author == "registered") {
                        state.isAuthorRegistered = true
                    } else {
                        state.isAuthorRegistered = false
                    }
                    state.isAuth = true
                }
                state.connection.isAuthFetch = false
            })
            .addCase(apiRegister.pending, (state, _) => {
                state.connection.isRegisteringFetch = true
            })
            .addCase(apiRegister.fulfilled, (state, _) => { 
                state.connection.isRegisteringFetch = false
            })
            .addCase(apiRegister.rejected, (state, _) => { 
                state.connection.isRegisteringFetch = false
            })
            .addCase(apiRegisterVerification.pending, (state, _) => {
                state.connection.isRegisterVerificationFetch = true
            })
            .addCase(apiRegisterVerification.fulfilled, (state, _) => { 
                state.connection.isRegisterVerificationFetch = false
            })
            .addCase(apiRegisterVerification.rejected, (state, _) => { 
                state.connection.isRegisterVerificationFetch = false
            })
            .addCase(apiEmailVerification.pending, (state, _) => {
                state.connection.isEmailVerificationFetch = true
            })
            .addCase(apiEmailVerification.fulfilled, (state, _) => { 
                state.connection.isEmailVerificationFetch = false
            })
            .addCase(apiEmailVerification.rejected, (state, _) => { 
                state.connection.isEmailVerificationFetch = false
            })
            .addCase(apiRecoverPassword.pending, (state, _) => {
                state.connection.isPasswordRecoveringFetch = true
            })
            .addCase(apiRecoverPassword.fulfilled, (state, _) => { 
                state.connection.isPasswordRecoveringFetch = false
            })
            .addCase(apiRecoverPassword.rejected, (state, _) => { 
                state.connection.isPasswordRecoveringFetch = false
            })
            .addCase(apiLogin.pending, (state, _) => {
                state.connection.isLoginingFetch = true
            })
            .addCase(apiLogin.fulfilled, (state, _) => { 
                state.connection.isLoginingFetch = false
            })
            .addCase(apiLogin.rejected, (state, _) => { 
                state.connection.isLoginingFetch = false
            })
            .addCase(apiLogout.pending, (state, _) => {
                state.connection.isLogoutingFetch = true
            })
            .addCase(apiLogout.fulfilled, (state, _) => { 
                state.connection.isLogoutingFetch = false
            }) 
            .addCase(apiLogout.rejected, (state, _) => { 
                state.connection.isLogoutingFetch = false
            })
    }
})
 
export const apiRegister = createAsyncThunk(
    "auth/apiRegister",
    async (data, { dispatch, rejectWithValue }) => {
        try {
            const res = await authApi.Register(data)

            return res.data
        } catch (err) {
            handleError(dispatch, err)
            return rejectWithValue(err.message)
        }
    } 
)

export const apiRegisterVerification = createAsyncThunk(
    "auth/apiRegisterVerification",
    async (data, { dispatch, rejectWithValue }) => {
        try {
            const res = await authApi.RegisterVerification(data)

            return res.data
        } catch (err) {
            handleError(dispatch, err)
            return rejectWithValue(err.message)
        }
    } 
)

export const apiEmailVerification = createAsyncThunk(
    "auth/apiEmailVerification",
    async (data, { dispatch, rejectWithValue }) => {
        try {
            const res = await authApi.EmailVerification(data)

            return res.data
        } catch (err) {
            handleError(dispatch, err)
            return rejectWithValue(err.message)
        }
    } 
)

export const apiRecoverPassword = createAsyncThunk(
    "auth/apiRecoverPassword",
    async (data, { dispatch, rejectWithValue }) => {
        try {
            const res = await authApi.RecoverPassword(data)

            return res.data
        } catch (err) {
            handleError(dispatch, err)
            return rejectWithValue(err.message)
        }
    } 
)

export const apiLogin = createAsyncThunk(
    "auth/apiLogin",
    async (data, { dispatch, rejectWithValue }) => {
        try {
            const res = await authApi.Login(data)

            return res.data
        } catch (err) {
            handleError(dispatch, err)
            return rejectWithValue(err.message)
        }
    }
)

export const apiLogout = createAsyncThunk(
    "auth/apiLogout",
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const res = await authApi.Logout()

            if(res.data.status == "success") {
                dispatch(toast("primary", "Logged out of account"))
            }

            return res.data 
        } catch (err) {
            handleError(dispatch, err)
            return rejectWithValue(err.message)
        }
    }
)

export const apiIsUser = createAsyncThunk(
    "auth/apiIsUser",
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const res = await accountApi.IsUser()

            return res.data
        } catch (err) {
            handleError(dispatch, err)
            return rejectWithValue(err.message)
        }
    }
)

export const { setIsAuth, setIsAuthorRegistered } = authSlice.actions
export default authSlice.reducer