import React from "react"
import css from "./page_not_found.module.css"

const PageNotFound = () => {

    return (
        <div className={css.not_found_wrap}>
            <h4>Page not found.</h4>
        </div>
    )
}

export default PageNotFound

