import React, { createContext, useState, useEffect } from "react"
import Account from "./account.jsx"
import { useLocation } from "react-router-dom";

const Context = createContext()

const AccountContainer = () => {

    const location = useLocation();
    const [activeKey, setActiveKey] = useState("");

    useEffect(() => {
        const path = location.pathname.split('/')[2]; 
        setActiveKey(path ? path.split('?')[0] : "");
    }, [location]);

    const contextData = {
        activeKey
    }

    return (
        <Context.Provider value={contextData}>
            <Account />
        </Context.Provider>
    )
} 

export { Context }
export default AccountContainer