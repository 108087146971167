import React, { createContext, useEffect, useState } from "react";
import TypesAndTopics from "./types_and_topics.jsx"
import { useSelector } from "react-redux";
import { apiGeCategories, apiDeleteCategorie } from "../../../../../store/slices/adminSlice.js"
import { useSearchParams } from "react-router-dom";
import useAuthRedirect from "../../../../../middleware/isAdminAuth.jsx";

const Context = createContext()

const TypesAndTopicsContainer = () => {

    const { isAuthDispatch } = useAuthRedirect()

    const { 
        products: {
            categories: {
                categories, limit, count,
                connection: {
                    isCategoriesFetch,
                    isDeltetingFetch,
                },
                categoriesNotFound
            },
        }
    } = useSelector((state) => state.admin)

    const [moreModalShow, setMoreModalShow] = useState({ id: '', name: '', show: false });
    const [confirmDeletion, setConfirmDeletion] = useState(false); 

    const onShowMoreModal = (id, name) => {
        setMoreModalShow({ id, name, show: true })
    }

    const onHideMoreModal = (id, name) => {
        setConfirmDeletion(false) 
        setMoreModalShow({ id, name, show: false })
    }
    
    const [categorie, setCategorie] = useState('type');

    const [searchParams, setSearchParams] = useSearchParams()

    let queryPage = searchParams.get("page")

    let pagesCount = Math.ceil(count / limit)

    const offset = (+queryPage - 1) * limit

    let pages = []; 
    for (let i = 1; i <= pagesCount; i++) {
        pages.push(i);
    }

    function pageFirst() {
        setSearchParams({ page: 1 })
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
    }

    function pagePrev() {
        setSearchParams({ page: +queryPage - 1 })
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
    }

    function pageSelect(p) {
        setSearchParams({ page: queryPage = p })
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
    }

    function pageNext() {
        setSearchParams({ page: +queryPage + 1 })
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
    }

    function pageLast() {
        setSearchParams({ page: pagesCount })
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
    }

    const dispatchApiGetCategories = () => {
        isAuthDispatch(apiGeCategories, { offset, limit, categorie })
    }

    const dispatchApiDeleteCategorie = async(categorie, id) => {
        setConfirmDeletion(false)
        await isAuthDispatch(apiDeleteCategorie, { categorie, id })
        setMoreModalShow({ id: '', user_id: '', show: false })
        dispatchApiGetCategories()
    }

    useEffect(() => {
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
        dispatchApiGetCategories()
    }, [searchParams, categorie])

    const contextData = { dispatchApiGetCategories, setCategorie, pageFirst, pagePrev, pageSelect, pageNext, pageLast, 
        queryPage, pages, categories,isCategoriesFetch, categorie, isDeltetingFetch, onShowMoreModal,
        onHideMoreModal, moreModalShow, confirmDeletion, setConfirmDeletion, dispatchApiDeleteCategorie, categoriesNotFound, isCategoriesFetch
     }

    return (
        <Context.Provider value={contextData}>
            <TypesAndTopics/>
        </Context.Provider>
    )
}

export { Context }
export default TypesAndTopicsContainer