import React, { createContext, useEffect, useState } from "react";
import AddUser from "./add_user.jsx"
import { apiAddUser } from "../../../../../store/slices/adminSlice.js"
import { useDispatch, useSelector } from "react-redux";
import useAuthRedirect from "../../../../../middleware/isAdminAuth.jsx";
import { toast } from "../../../../../store/slices/toastsSlice.js";
import { unwrapResult } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";

const Context = createContext()

const AddUserContainer = () => {

    const { isAuthDispatch } = useAuthRedirect()

    const navigate = useNavigate()

    const dispatch = useDispatch()

    const {
       users: {
            connection: { 
                isUserAddingFetch
            }
       }
    } = useSelector((state) => state.admin)

    const {
        form: {
            max_user_name, min_user_name
        }
    } = useSelector((state) => state.constants)

    const dispatchApiAddUser = (form) => {
        return isAuthDispatch(apiAddUser, form)
    } 

    const [form, setForm] = useState({})
    const [errors, setErrors] = useState({})

    const setField = (field, value) => {
        setForm({
            ...form,
            [field]: value
        });

        const fieldErrors = validateField(field, value);

        setErrors({
            ...errors,
            [field]: fieldErrors[field]
        });
    }

    const validateField = (field, value) => {
        const newError = {};

        switch (field) { 
            case 'name':
                if(value == undefined || value == "") newError.name = 'Please enter name'
                if (value && value.length < min_user_name) newError.name = `Name must be more than ${min_user_name} characters.`
                else if (value && value.length > max_user_name) newError.name = `Name must be less than ${max_user_name} characters.`
                else if (value && value.match(/[^a-zA-Z0-9 ]/g)) newError.name = 'Only characters a-z, A-Z and 0-9 are allowed'
                break;
        }

        return newError;
    }

    const validateForm = () => { 
        const newErrors = {};

        const fields = ['name'];

        fields.forEach(field => {
            const fieldErrors = validateField(field, form[field]);
            Object.assign(newErrors, fieldErrors);
        })

        return newErrors;
    }
 
    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        const formErrors = validateForm()

        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors)
            dispatch(toast("warning", "Invalid form"))
        } else { 
            const resAction = await dispatchApiAddUser(form)
            const resPromise = unwrapResult(resAction)  

            const setError = (err) => {
                setErrors(err)
                dispatch(toast("warning", "Invalid form"))
            }

            switch (resPromise.status) { 
                case "name_already_exists":
                    setError({ name: "This name already exists" })
                    break
                case "name_another_session":
                    setError({ name: "This name is currently taken by another session, please try again later or enter another name." })
                    break
                case "success":
                    dispatch(toast("succes", "User added"))
                    navigate("/admin/users?page=1")
                    break
            } 
        }
    }

    useEffect(() => { 
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
    }, [])

    const contextData = { form, errors, setField, handleSubmit, isUserAddingFetch }

    return (
        <Context.Provider value={contextData}>
            <AddUser/>
        </Context.Provider>
    )
}

export { Context }
export default AddUserContainer 