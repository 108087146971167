import React, { createContext, useEffect, useRef } from "react"
import Profile from "./profile.jsx"
import { useSelector, useDispatch } from "react-redux"
import { apiGetProfileInfo, apiGetHistory, apiGetLikes, setIsProfileFetch } from "../../../../store/slices/accountSlice.js"
import { apiAddProductToBasket, setAddingId } from "../../../../store/slices/basketSlice"
import { apiLogout, setIsAuth } from "../../../../store/slices/authSlice.js"
import useAuthRedirect from "../../../../middleware/isAuth.jsx"
import { useNavigate, useSearchParams } from "react-router-dom"
import { unwrapResult } from "@reduxjs/toolkit"
import { googleLogout } from '@react-oauth/google';

const Context = createContext()

export const useDidMount = () => {
    const isMountRef = useRef(true);
    useEffect(() => {
      isMountRef.current = false; 
    }, []);
    return isMountRef.current;
  };

const ProfileContainer = () => {

    const dispatch = useDispatch()

    const { isAuthDispatch, isAuthLineDispatch } = useAuthRedirect()

    const didMount = useDidMount()

    const navigate = useNavigate()

    const {
        profile: { id, name, email, avatar, created, purchased, products_created, sold,
            likes: { likes, limit, count, likesNotFound },
            history: { history, historyNotFound },
            connection: { isLikesFetch, isInfoFetch, isHistoryFetch, isFetch },
            placeholders: { likesPlaceholders, historyPlaceholders } 
        },
    } = useSelector((state) => state.account);

    const { addingId, connection: { isAddingFetch } } = useSelector((state) => state.basket)

    const { connection: { isLogoutingFetch } } = useSelector((state) => state.auth)

    const [searchParams, setSearchParams] = useSearchParams()

    let queryLikesPage = searchParams.get("likes")

    let likesPagesCount = Math.ceil(count / limit)

    const offset = (+queryLikesPage - 1) * limit

    let likesPages = [];
    for (let i = 1; i <= likesPagesCount; i++) {
        likesPages.push(i);
    }

    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

    const parsedDate = new Date(created);
    const year = parsedDate.getFullYear();
    const month = parsedDate.getMonth();

    const historyConverted = history.map(el => {
        const parsedDate = new Date(el.created);
        const year = parsedDate.getFullYear();
        const month = ("0" + (parsedDate.getMonth() + 1)).slice(-2);
        const date = ("0" + (parsedDate.getDate())).slice(-2);
        const hours = ("0" + (parsedDate.getHours())).slice(-2);
        const minutes = ("0" + (parsedDate.getMinutes())).slice(-2);
        const seconds = ("0" + (parsedDate.getSeconds())).slice(-2);
        const str = `${hours}:${minutes}:${seconds} | ${date}.${month}.${year}`
        return { id: el.id, name: el.name, created: str }
    })

    function pageFirst() {
        setSearchParams({ likes: 1 })
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
    }

    function pagePrev() {
        setSearchParams({ likes: +queryLikesPage - 1 })
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
    }

    function pageSelect(p) {
        setSearchParams({ likes: queryLikesPage = p })
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
    }

    function pageNext() {
        setSearchParams({ likes: +queryLikesPage + 1 })
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
    }

    function pageLast() {
        setSearchParams({ likes: likesPagesCount })
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
    }

    const dispatchApiGetProfileInfo = () => {
        return isAuthDispatch(apiGetProfileInfo)
    }

    const dispatchApiGetHistory = () => {
        return isAuthDispatch(apiGetHistory)
    }

    const dispatchApiGetLikes = () => {
        return isAuthDispatch(apiGetLikes, { limit, offset })
    }

    const dispatchApiAddProductToBasket = (id) => {
        isAuthDispatch(apiAddProductToBasket, { id })
    }

    const dispatchSetAddingId = (id) => {
        dispatch(setAddingId(id))
    }

    const dispatchSetIsProfileFetch = (bool) => {
        dispatch(setIsProfileFetch(bool)) 
    }

    const dispatchApiLogout = async () => {
        const actionRes = await dispatch(apiLogout())
        const promiseRes = unwrapResult(actionRes)
        if(promiseRes.status == "success") {
            dispatch(setIsAuth(false))
            googleLogout()
            navigate("/login")
        } 
    }

    const pageLoad = async () => {
        dispatchSetIsProfileFetch(true)
        await isAuthLineDispatch([dispatchApiGetProfileInfo, dispatchApiGetLikes, dispatchApiGetHistory])
        dispatchSetIsProfileFetch(false)
    }

    useEffect(() => {
        setTimeout(() => {window.scrollTo(0, 0)}, 0) 
        pageLoad()
    }, [])

    useEffect(() => {
        if (!didMount) {
            dispatchApiGetLikes()
        } 
    }, [queryLikesPage])

    const contextData = {
        id, name, email, avatar, created, historyConverted, historyNotFound, likes, likesNotFound, months, year, month,
        likesPlaceholders, addingId, isAddingFetch, isLikesFetch, isHistoryFetch, dispatchApiAddProductToBasket,
        dispatchSetAddingId, pageFirst, pagePrev, pageSelect, pageNext, pageLast, queryLikesPage, likesPages, isFetch,
        isInfoFetch, historyPlaceholders, purchased, products_created, sold, dispatchApiLogout, isLogoutingFetch
    }

    return (
        <Context.Provider value={contextData}>
            <Profile />
        </Context.Provider>
    )
}

export { Context }
export default ProfileContainer