import React, { useContext, useState } from "react"
import css from "./option_add.module.css"
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Context } from "./option_add_container";
import Spinner from "react-bootstrap/esm/Spinner";

const OptionAdd = () => {

    const { form, handleSubmit, setField, errors, isAddingFetch } = useContext(Context)

    return (
        <Form noValidate onSubmit={handleSubmit} className={css.form} >
            <h4 className={css.title}>Add categorie</h4>
            <Form.Group className={css.input} controlId="categories">
                <Form.Select
                    className={css.select}
                    value={form.categorie}
                    onChange={e => setField('categorie', e.target.value)}
                    isValid={form.categorie && form.categorie !== 0 && !errors.categorie}
                    isInvalid={!!errors.categorie}
                >
                    {[
                        { n: 'Select categories', v: '' },
                        { n: 'Types', v: 'type' },
                        { n: 'Topics', v: 'topic' },
                    ].map((el, ind) => (
                        <option hidden={el.v === ''} key={ind} value={el.v}>{el.n}</option>
                    ))}
                </Form.Select>
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">{errors.categorie}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className={css.input} controlId="name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                    value={form.name}
                    onChange={e => setField('name', e.target.value)}
                    isValid={form.name && form.name !== '' && !errors.name}
                    isInvalid={!!errors.name}
                    placeholder="Enter name"
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
            </Form.Group>
            <Button disabled={isAddingFetch} className={css.btn_submit} type="submit" variant="primary"><div className={css.btn_submit_inner}>Add{isAddingFetch && <Spinner size="sm" />}</div></Button>
        </Form>
    )
}

export default OptionAdd