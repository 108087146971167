import React, { useContext } from "react";
import css from "./add_user.module.css"
import { Context } from "./add_user_container.jsx";
import Spinner from "react-bootstrap/Spinner";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

const AddUser = () => {

    const { form, errors, setField, handleSubmit, isUserAddingFetch } = useContext(Context)

    return (
        <div className={css.form_wrap}>
            <Form noValidate onSubmit={handleSubmit} className={css.form} >
                <h3 className={css.title}>Add user</h3>
                <Form.Group className={css.input} controlId="name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                        value={form.name}
                        onChange={e => setField('name', e.target.value)}
                        isValid={form.name && form.name !== '' && !errors.name}
                        isInvalid={!!errors.name}
                        placeholder="Enter name"
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                </Form.Group>
                <Button disabled={isUserAddingFetch} className={css.btn_submit} type="submit" variant="primary"><div className={css.btn_submit_inner}>Add{isUserAddingFetch && <Spinner size="sm" />}</div></Button>
            </Form>
        </div>
    )
}

export default AddUser