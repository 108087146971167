import './App.css'
import Header from "./components/header/header_container.jsx"
import Register from "./components/header/auth/register/register_container.jsx"
import RegisterCodeVerification from "./components/header/auth/register/code_verification/code_verification_container.jsx"
import Login from "./components/header/auth/login/login_container.jsx"
import LoginCodeVerification from "./components/header/auth/login/change_password/email_verify/email_verify_container.jsx"
import CheckEmailMessage from "./components/header/auth/login/change_password/email_verify/check_email/check_email_message.jsx"
import NewPassword from "./components/header/auth/login/change_password/new_password/new_password_container.jsx"
import Basket from "./components/header/basket/basket_container.jsx"
import BasketSuccess   from "./components/header/basket/check_purchases/check_purchases.jsx"
import Categories from "./components/header/categories/categories_container.jsx"
import ProductInfo from "./components/header/categories/product_info/product_info_container.jsx"
import Account from "./components/header/account/account_container.jsx"
import Admin from "./components/admin/admin_container.jsx"
import AdminStatistics from "./components/admin/admin_panel/statistics/statistics_container.jsx"
import AdminUsers from "./components/admin/admin_panel/users/users_container.jsx"
import AdminAddUser from "./components/admin/admin_panel/users/add_user/add_user_container.jsx"
import AdminAuthors from "./components/admin/admin_panel/authors/authors_container.jsx"
import AdminTemplates from "./components/admin/admin_panel/templates/templates_container.jsx"
import AdminTemplatesAdd from "./components/admin/admin_panel/templates/templates_add/templates_add_container.jsx"
import AdminTemplatesEdit from "./components/admin/admin_panel/templates/templates_edit/templates_edit_container.jsx"
import AdminTemplatesAuthor from "./components/admin/admin_panel/templates/templates_author/templates_author_container.jsx"
import AdminTemplatesCount from "./components/admin/admin_panel/templates/templates_count/templates_count_container.jsx"
import AdminTypesAndTopics from "./components/admin/admin_panel/templates/types_and_topics/types_and_topics_container.jsx"
import AdminOptionAdd from "./components/admin/admin_panel/templates/option_add/option_add_container.jsx"
import UploadTemplate from "./components/header/account/templates/upload_template/upload_template_container.jsx"
import Purchased from "./components/header/account/purchased/purchased_container.jsx"
import Templates from "./components/header/account/templates/templates_container.jsx"
import Settings from "./components/header/account/settings/settings_container.jsx"
import Profile from "./components/header/account/profile/profile_container.jsx"
import Main from "./components/header/main/main_container.jsx"
import Footer from "./components/footer/footer.jsx"
import Support from "./components/header/support/support_container.jsx" 
import AdminSupport from "./components/admin/admin_panel/support/support_container.jsx"
import AccountEdit from "./components/header/account/profile/edit/edit_container.jsx"
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom"
import CookieConsent from "react-cookie-consent";
import { Cookie } from 'react-bootstrap-icons';
import Nav from 'react-bootstrap/Nav';
import Toasts from './components/toasts/toasts_container.jsx'
import AboutUs from './components/header/about_us/about_us.jsx'
import PageNotFound from './components/page_not_found/page_not_found.jsx'
import FAQ from './components/footer/faq/faq.jsx'
import PrivacyPolicy from './components/footer/documents/privacy_policy/privacy_policy.jsx'
import TermsAndConditions from './components/footer/documents/terms_and_conditions/terms_and_conditions.jsx'
import CookieConcent from './components/footer/documents/cookie_concent/cookie_concent.jsx'

import { Provider } from 'react-redux';
import { store } from "./store/redux.js"

function App() {
  return (
    <Provider store={store}>
    <div className="App"> 
      <Router >
        <div className="router_inner">
        <Header />
          <div className="content_wrap">
            <div className="content">
              <Routes>
                <Route path="/register" element={<Register />} />
                <Route path="/register/verify" element={<RegisterCodeVerification />} />
                <Route path="/login" element={<Login />} />
                <Route path="/login/verify/check" element={<CheckEmailMessage />} />
                <Route path="/login/verify" element={<LoginCodeVerification />} />
                <Route path="/login/new-password" element={<NewPassword />} />
                <Route path="/" element={<Main />} />
                <Route path="/basket" element={<Basket />} />
                <Route path="/basket/success" element={<BasketSuccess />} />
                <Route path="/categories" element={<Categories />} />
                <Route path="/categories/product" element={<ProductInfo />} />
                <Route path="/account" element={<Account />}>
                  <Route path="" element={<Profile />} exact />
                  <Route path="purchased" element={<Purchased />} />
                  <Route path="author" element={<Templates />} />
                  <Route path="settings" element={<Settings />} />
                </Route>
                <Route path="/account/upload" element={<UploadTemplate />} />
                <Route path="/account/edit" element={<AccountEdit />} />
                <Route path="/admin" element={<Admin />}>
                  <Route path="" element={<AdminStatistics />} exact />
                  <Route path="users" element={<AdminUsers />} />
                  <Route path="users/add" element={<AdminAddUser />} />
                  <Route path="authors" element={<AdminAuthors />} />
                  <Route path="authors/view" element={<AdminTemplatesAuthor />} />
                  <Route path="templates" element={<AdminTemplates />}>
                    <Route path="" exact element={<AdminTemplatesCount />} />
                    <Route path="edit" element={<AdminTemplatesEdit />} />
                    <Route path="add" element={<AdminTemplatesAdd />} />
                    <Route path="typesandtopics" element={<AdminTypesAndTopics />} />
                    <Route path="typesandtopics/add" element={<AdminOptionAdd />} />
                  </Route>
                  <Route path="support" element={<AdminSupport />} />
                </Route>
                <Route path="/support" element={<Support />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/faq" element={<FAQ />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                <Route path="/cookie-concent" element={<CookieConcent />} />
                <Route path="*" element={<PageNotFound />} />
              </Routes>
            </div>
          </div> 
          <Footer />
          <CookieConsent
            disableStyles={true}
            buttonClasses="btn btn-primary"
            containerClasses="alert alert-warning"
            cookieName="CookieConsent"
            buttonText="Accept Cookies"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              position: "fixed",
              width: "100%",
              zIndex: "99",
              gap: "0.5rem"
            }} 
            expires={30}
          > 
          We use cookies to enhance your browsing experience, serve personalized ads or content, and analyze our traffic. <Cookie size="1rem"></Cookie> 
          &nbsp;<Nav.Link as={Link} style={{display: "inline"}} to="/cookie-concent" className="link">Cookie Concent</Nav.Link> | <a style={{display: "inline"}} target="_blank" href="https://en.wikipedia.org/wiki/HTTP_cookie" className="link">What is this?</a>
          </CookieConsent> 
          <Toasts />
        </div> 
      </Router>
    </div>
    </Provider>
  );
}

export default App
