import React, { createContext, useEffect, useState } from "react"
import { apiRegisterVerification, apiIsUser } from "../../../../../store/slices/authSlice"
import { useDispatch, useSelector } from "react-redux"
import { unwrapResult } from "@reduxjs/toolkit"
import { toast } from "../../../../../store/slices/toastsSlice.js"
import { useNavigate } from "react-router-dom"
import CodeVerification from "./code_verification.jsx"

const Context = createContext()

const CodeVerificationContainer = () => {

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const {
        connection: { isRegisterVerificationFetch }
    } = useSelector((state) => state.auth);

    const [code, setCode] = useState("")
    const [error, setError] = useState("")

    const handleCode = (n) => {
        setCode(n)
        setError("")
    }

    const dispathcApiRegisterVerification = () => {
        return dispatch(apiRegisterVerification({ code: Number(code) }))
    }

    const dispatchApiIsUser = () => {
        return dispatch(apiIsUser())
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        event.stopPropagation()

        setError("")

        if (code.length < 6) {
            dispatch(toast("warning", "Invalid form"))
            setError("Please enter code")
        } else {
            const actionRes = await dispathcApiRegisterVerification()
            const promiseRes = unwrapResult(actionRes)

            switch (promiseRes.status) {
                case "session_expired":
                    dispatch(toast("warning", "Session expired"))
                    navigate('/register')
                    break
                case "incorrect_code":
                    setError("Incorrect code")
                    break 
                case "attempts_exceeded":
                    dispatch(toast("warning", "Number of attempts exceeded. Try again later"))
                    navigate('/register')
                    break
                case "success":
                    await dispatchApiIsUser()
                    navigate('/account?likes=1')
                    break
            }
        }
    }

    useEffect(() => {
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
    }, [])

    const contextData = { handleSubmit, code, handleCode, isRegisterVerificationFetch, error }

    return (
        <Context.Provider value={contextData}>
            <CodeVerification />
        </Context.Provider>
    )
}

export { Context }
export default CodeVerificationContainer