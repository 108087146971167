import React, { useContext } from "react"
import { Context } from "./categories_container";
import css from "./categories.module.css"
import CategoriesProductsMap from "./categories_products_map/categories_products_map"
import { Funnel, Search } from 'react-bootstrap-icons';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import CloseButton from 'react-bootstrap/CloseButton';
import Placeholder from 'react-bootstrap/Placeholder';

//testing
import { useDispatch } from "react-redux";
import { apiGetProducts } from "../../../store/slices/categoriesSlice.js"

const Categories = () => { 

    const dispatch = useDispatch()

    const { dispatchApiGetProducts, dispatchSetSearch, dispatchSetSort, dispatchAddCategorie,
        categories, search, sort, limit, productsNotFound, types, topics, isCategoriesFetch, typesPlaceholders,
        topicsPlaceholders, handleSearchParams, offset, handleFocusCategorie,
        handleDeleteCategorie } = useContext(Context)

    return ( 
        <>
            <section className={css.setting}>
                <InputGroup className={css.search_input_xsm}>
                    <Form.Control
                        data-testid="search-input"
                        placeholder="I'm looking for.."
                        onChange={e => dispatchSetSearch(e.target.value)}
                        value={search}
                    />
                    <Button data-testid="search-button" onClick={() => { dispatch(apiGetProducts({search, categories, sort, offset, limit})); handleSearchParams(1) }} className={css.search_btn} >
                        Search<Search className={css.search_img} size="1rem"></Search>
                    </Button>
                </InputGroup>
                <div className={css.setting_inner}>
                    <Dropdown>
                        <Dropdown.Toggle className={css.drop_toggle_categories}>
                            Categories
                        </Dropdown.Toggle>
                        <Dropdown.Menu className={css.drop_menu}>
                            <Dropdown drop="end">
                                <Dropdown.Toggle className={css.drop_toggle_in_categories}>
                                    Select type
                                </Dropdown.Toggle>
                                <Dropdown.Menu className={css.drop_menu}>
                                    {!isCategoriesFetch ?
                                        types.map((el, i) => (
                                            <Dropdown.Item onClick={() => { dispatchAddCategorie("type", el.id, el.name); handleSearchParams(1) }} key={i} className={css.drop_item}>{el.name}</Dropdown.Item>
                                        ))
                                        :
                                        Array.from({ length: typesPlaceholders }).map((_, i) => (
                                            <Placeholder className={css.drop_item_placeholder_wrap} key={i} animation="glow">
                                                <Placeholder className={css.drop_item_placeholder} as={Dropdown.Item}></Placeholder>
                                            </Placeholder>
                                        ))
                                    }
                                </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown drop='end'>
                                <Dropdown.Toggle className={css.drop_toggle_filter}>
                                    Select topic
                                </Dropdown.Toggle>
                                <Dropdown.Menu className={css.drop_menu}>
                                    {!isCategoriesFetch ? 
                                        topics.map((el, i) => (
                                            <Dropdown.Item key={i} onClick={() => { dispatchAddCategorie("topic", el.id, el.name); handleSearchParams(1) }} className={css.drop_item}>{el.name}</Dropdown.Item>
                                        ))
                                        :
                                        Array.from({ length: topicsPlaceholders }).map((_, i) => (
                                            <Placeholder className={css.drop_item_placeholder_wrap} key={i} animation="glow">
                                                <Placeholder className={css.drop_item_placeholder} as={Dropdown.Item}></Placeholder>
                                            </Placeholder>
                                        ))
                                    }
                                </Dropdown.Menu>
                            </Dropdown>
                        </Dropdown.Menu>
                    </Dropdown>
                    <InputGroup className={css.search_input_sm}>
                        <Form.Control
                            placeholder="I'm looking for.."
                            onChange={e => dispatchSetSearch(e.target.value)}
                            value={search}
                        />
                        <Button onClick={() => { dispatchApiGetProducts(search, categories, sort, offset, limit); handleSearchParams(1) }} className={css.search_btn} >
                            Search<Search className={css.search_img} size="1rem"></Search>
                        </Button>
                    </InputGroup>
                    <Dropdown className={css.drop_filter} drop="start">
                        <Dropdown.Toggle className={css.drop_toggle_filter}>
                            {sort}
                            <Funnel className={css.funne_img} size={"1.5rem"} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className={css.drop_menu}>
                            {["The newest", "The oldest", "Lowest price"].map((el, i) => (
                                <Dropdown.Item onClick={() => { dispatchSetSort(el); handleSearchParams(1) }} key={i} className={css.drop_item}>{el}</Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </section>
            <section className={css.categories}>
                {categories?.map((el, i) =>
                    <div key={i} className={css.categorie}><span>{el.name}</span><CloseButton onClick={() => handleDeleteCategorie(el.name)} onFocus={handleFocusCategorie} /></div>
                )}
            </section>
            {!productsNotFound ?
                <>
                    <CategoriesProductsMap />
                </>
                :
                <div className={css.not_found_wrap}>
                    <h4>Products not found.</h4>
                </div>
            }
        </>
    )
}

export default Categories

