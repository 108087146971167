import React, { createContext, useEffect, useRef } from "react";
import Statistics from "./statistics.jsx"
import { apiGetStatistics } from "../../../../store/slices/adminSlice.js"
import { useSelector } from "react-redux";
import useAuthRedirect from "../../../../middleware/isAdminAuth.jsx";
import Chart from "chart.js/auto";

const Context = createContext()

const StatisticsContainer = () => {

    const { isAuthDispatch } = useAuthRedirect()
 
    const { 
        statistics: {
            statistics: {
                counts,
                charts: {
                    months,
                    intervals
                }
            },
            connection: {
                isStatisticsFetch
            }
        }
    } = useSelector((state) => state.admin);

    const dispatchApiGetStatistics = () => {
        isAuthDispatch(apiGetStatistics)
    }  

    const chartUsersRef = useRef(null);
    const chartProductsRef = useRef(null);
    const chartPurchasesRef = useRef(null);
    const chartUploadsRef = useRef(null);

    useEffect(() => {
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
        dispatchApiGetStatistics()
    }, [])
 
    useEffect(() => { 
        if(!isStatisticsFetch) {
            var charts = [ {title: "Registered users", interval: intervals.users, ref: chartUsersRef},
            {title: "Created products", interval: intervals.products, ref: chartProductsRef},
            {title: "Purchases", interval: intervals.purchases, ref: chartPurchasesRef}, 
            {title: "Author uploads", interval: intervals.uploads, ref: chartUploadsRef} ]

            charts.forEach((el) =>{
                const data = {
                    labels: months, 
                    datasets: [ 
                        {
                            label: el.title, 
                            backgroundColor: 'rgba(13, 110, 253, .5)',
                            borderColor: "rgb(13, 110, 253)",
                            borderWidth: 4,
                            fill: true, 
                            pointBackgroundColor: "rgb(13, 110, 253)",
                            data: el.interval,
                        },
                    ],
                }; 
    
                const options = {
                    legend: {
                        display: false, 
                    },
                    responsive: true,
                    maintainAspectRatio: false, 
                    scales: {
                        y: {
                            beginAtZero: true,
                            max: el.interval[ el.interval.length - 1] * 2
                        },
                    },
                };
    
                const chart = new Chart(el.ref.current, {
                    type: "line", 
                    data: data, 
                    options: options,
                });
    
                return () => {
                    chart.destroy();
                };
            })
        }
    }, [isStatisticsFetch]);

    const contextData = { counts, isStatisticsFetch, chartUsersRef, chartProductsRef, chartPurchasesRef, chartUploadsRef }

    return (
        <Context.Provider value={contextData}>
            <Statistics/>
        </Context.Provider>
    )
}

export { Context }
export default StatisticsContainer