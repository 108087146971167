import React, { useContext } from "react"
import css from "./email_verify.module.css"
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Context } from "./email_verify_container.jsx";
import Spinner from "react-bootstrap/Spinner";

const EmailVerify = () => {

    const { handleSubmit, form, errors, setField, isEmailVerificationFetch } = useContext(Context)

    return (
        <Form noValidate onSubmit={handleSubmit} className={css.form} >
            <h3 className={css.title}>Recover password</h3>
            <Form.Group className={css.input} controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                    value={form.email}
                    onChange={e => setField('email', e.target.value)}
                    isValid={form.email && form.email !== '' && !errors.email}
                    isInvalid={!!errors.email}
                    placeholder="Enter email"
                /> 
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
            </Form.Group>
            <Button disabled={isEmailVerificationFetch} type="submit" variant="primary" className={css.btn_submit}><div className={css.btn_submit_inner}>Verify{isEmailVerificationFetch && <Spinner size="sm" />}</div></Button>
        </Form>
    )
}

export default EmailVerify 