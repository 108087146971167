import React, { useState, useEffect, createContext } from "react"
import Main from "./main.jsx"
import { useDispatch } from "react-redux"
import { setSearch } from "../../../store/slices/categoriesSlice.js" 

const Context = createContext()

const MainContainer = () => {

    const [localSearch, setLocalSearch] = useState("")

    const dispatch = useDispatch()

    const dispatchSetSearch = () => {
        dispatch(setSearch(localSearch))
    }
 
    useEffect(() => {
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
    }, [])

    const contextData = { dispatchSetSearch, localSearch, setLocalSearch }

    return ( 
        <Context.Provider value={contextData}>
            <Main/>
        </Context.Provider>
    )
}

export { Context }
export default MainContainer

