import React, { useEffect } from "react"
import css from "./check_purchases.module.css"
import { BagCheck } from 'react-bootstrap-icons';
import Alert from 'react-bootstrap/Alert';

const CheckEmailMessage = () => {

    useEffect(() => {
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
    }, [])

    return (
        <div className={css.alert_wrap}>
            <Alert className={css.alert} variant="success">
                <Alert.Heading className={css.header}>Products purchased successfully<BagCheck size="1.75rem" /></Alert.Heading>
                <hr />
                <p className={css.additional}>
                    It may take a few minutes before they appear on the purchased page.
                </p>
            </Alert>
        </div>
    )
}

export default CheckEmailMessage  