import React, { useState, useEffect, createContext } from "react";
import Users from "./users.jsx"
import { apiGetUsers, apiDeleteUser, apiDeleteAuthor } from "../../../../store/slices/adminSlice.js"
import { useSelector } from "react-redux";
import useAuthRedirect from "../../../../middleware/isAdminAuth.jsx";
import { useSearchParams } from "react-router-dom";

const Context = createContext()

const UsersContainer = () => {

    const { isAuthDispatch } = useAuthRedirect() 

    const {
       users: {
            users, limit, count, usersNotFound,
            connection: { 
                isUsersFetch,
                isUserDeletingFetch,
                isAuthorDeletingFetch
            }
       }
    } = useSelector((state) => state.admin)

    const [moreModalShow, setMoreModalShow] = useState({ id: "", name: "", show: false });

    const [confirmAccountDeletion, setConfirmAccountDeletion] = useState(false); 
    const [confirmAuthorDeletion, setConfirmAuthorDeletion] = useState(false); 

    const onShowMoreModal = (id, name) => {
        setMoreModalShow({ id, name, show: true })
    }

    const onHideMoreModal = (id, name) => {
        setConfirmAccountDeletion(false) 
        setConfirmAuthorDeletion(false)
        setMoreModalShow({ id, name, show: false })
    }

    const [searchParams, setSearchParams] = useSearchParams()

    let queryPage = searchParams.get("page")

    let pagesCount = Math.ceil(count / limit)

    const offset = (+queryPage - 1) * limit

    let pages = [];
    for (let i = 1; i <= pagesCount; i++) {
        pages.push(i);
    } 

    function pageFirst() {
        setSearchParams({ page: 1 })
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
    }

    function pagePrev() {
        setSearchParams({ page: +queryPage - 1 })
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
    }

    function pageSelect(p) {
        setSearchParams({ page: queryPage = p })
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
    }

    function pageNext() {
        setSearchParams({ page: +queryPage + 1 })
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
    }

    function pageLast() {
        setSearchParams({ page: pagesCount })
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
    }

    const dispatchApiGetUsers = () => {
        isAuthDispatch(apiGetUsers, {offset, limit})
    }

    const dispatchApiDeleteUser = async (id) => {
        setConfirmAccountDeletion(false)
        await isAuthDispatch(apiDeleteUser, { id })
        setMoreModalShow({ id: '', name: '', show: false })
        dispatchApiGetUsers()
    }

    const dispatchApiDeleteAuthor = async (id) => {
        setConfirmAuthorDeletion(false)
        await isAuthDispatch(apiDeleteAuthor, { id })
        setMoreModalShow({ id: '', name: '', show: false })
    }

    useEffect(() => {
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
        dispatchApiGetUsers()
    }, [queryPage])

    const contextData = { users, usersNotFound, isUsersFetch, pages, queryPage, pageFirst, pagePrev, pageSelect, pageNext, pageLast, 
        moreModalShow, onShowMoreModal, onHideMoreModal, isUserDeletingFetch, dispatchApiDeleteUser, confirmAccountDeletion, setConfirmAccountDeletion,
        confirmAuthorDeletion, setConfirmAuthorDeletion, isAuthorDeletingFetch, dispatchApiDeleteAuthor
     }

    return (
        <Context.Provider value={contextData}>
            <Users/>
        </Context.Provider>
    )
}

export { Context }
export default UsersContainer 