import React, { createContext, useEffect, useState } from "react"
import RegisterAuthor from "./register_author.jsx"
import { useDispatch, useSelector } from "react-redux"
import { apiRegisterAuthor } from "./../../../../../store/slices/accountSlice.js"
import useAuthRedirect from "../../../../../middleware/isAuth.jsx"
import { unwrapResult } from "@reduxjs/toolkit"
import { apiIsUser } from "../../../../../store/slices/authSlice.js"
import { toast } from "../../../../../store/slices/toastsSlice.js"

const Context = createContext()

const RegisterAuthorContainer = () => {
     
    const { isAuthDispatch } = useAuthRedirect()

    const dispatch = useDispatch()
 
    const { 
        author: {
            connection: { isRegisteringFetch }
        }
    } = useSelector((state) => state.account)

    const {
        name
    } = useSelector((state) => state.auth)

    const {
        form: {
            max_user_name, min_user_name
        }
    } = useSelector((state) => state.constants)

    const dispatchApiRegisterAuthor = (form) => {
        return isAuthDispatch(apiRegisterAuthor, form)
    } 

    const [form, setForm] = useState({})
    const [errors, setErrors] = useState({})

    const setField = (field, value) => {
        setForm({
            ...form,
            [field]: value
        });

        const fieldErrors = validateField(field, value);

        setErrors({
            ...errors,
            [field]: fieldErrors[field]
        });
    }

    const validateField = (field, value) => {
        const newError = {};

        switch (field) {
            case 'name':
                if(name == "" && (value == undefined || value == "")) newError.name = 'Please enter name'
                if (value && value.length < min_user_name) newError.name = `Name must be more than ${min_user_name} characters.`
                else if (value && value.length > max_user_name) newError.name = `Name must be less than ${max_user_name} characters.`
                else if (value && value.match(/[^a-zA-Z0-9 ]/g)) newError.name = 'Only characters a-z, A-Z and 0-9 are allowed'
                break;
            case 'country':
                if (value == undefined || value == '') newError.country = 'Please select country'
                break;
        }

        return newError;
    }

    const validateForm = () => { 
        const newErrors = {};

        const fields = ['name', 'country'];

        fields.forEach(field => {
            const fieldErrors = validateField(field, form[field]);
            Object.assign(newErrors, fieldErrors);
        })

        return newErrors;
    }

    const dispatchApiIsUser = () => {
        isAuthDispatch(apiIsUser)
    }
 
    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        const formErrors = validateForm()

        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors)
            dispatch(toast("warning", "Invalid form"))
        } else { 
            const resAction = await dispatchApiRegisterAuthor(form)
            const resPromise = unwrapResult(resAction)  

            const setError = (err) => {
                setErrors(err)
                dispatch(toast("warning", "Invalid form"))
            }

            switch (resPromise.status) { 
                case "author_already_exists":
                    dispatch(toast("warning", "Author account already created"))
                    break
                case "name_already_exists":
                    setError({ name: "This name already exists" })
                    break
                case "name_another_session":
                    setError({ name: "This name is currently taken by another session, please try again later or enter another name." })
                    break
                case "name_is_empty":
                    dispatch(toast("warning", "Name is required. Please reload page"))
                    break
                case "success":
                    dispatchApiIsUser()
                    break
            } 
        }
    }

    useEffect(() => { 
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
    }, [])

    const contextData = {
        form, errors, setField, handleSubmit, isRegisteringFetch, name
    }
    
    return (
        <Context.Provider value={contextData}>
            <RegisterAuthor /> 
        </Context.Provider>
    ) 
}

export { Context }
export default RegisterAuthorContainer 