import React, { useContext } from "react"
import css from "./messages_map.module.css"
import Button from 'react-bootstrap/Button';
import { ThreeDotsVertical, Copy } from "react-bootstrap-icons"
import Modal from 'react-bootstrap/Modal';
import { Context } from "../support_container";
import Spinner from "react-bootstrap/Spinner";
import Nav from 'react-bootstrap/Nav';
 
const UsersMap = () => {

 const { dispatchApiDeleteSupport, messages, isDeletingFetch, moreModalShow, confirmDeletion,
   setConfirmDeletion, onShowMoreModal, onHideMoreModal, messageModalShow, setMessageModalShow, dispatchToast } = useContext(Context)

  const MoreModal = () => { 
    return (
      <Modal
        show={moreModalShow.show}
        onHide={() => onHideMoreModal("", "", "")}
        size="md"
        className={css.modal}
        centered 
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Information
          </Modal.Title> 
        </Modal.Header>
        <Modal.Body>
          <div className={css.item}>Message <Nav.Link className={`link ${css.item_link}`}>#{moreModalShow.id}</Nav.Link>from user<Nav.Link className={`link ${css.item_link}`}>#{moreModalShow.user_id}</Nav.Link></div>
          <div className={css.action}>
              {!confirmDeletion ?
              <>
              <div>Delete message</div>
              <div className={css.action_btn_wrap}>
              <Button disabled={isDeletingFetch} onClick={() => setConfirmDeletion(true)} className={css.del_btn} variant="danger">Delete{isDeletingFetch && <Spinner size="sm"/>}</Button>
              </div>
              </>
              :
              <>
                  <div>Confim deletion?</div>
                  <div className={css.action_btn_wrap}>
                  <Button onClick={() => dispatchApiDeleteSupport(moreModalShow.id)} className={css.del_btn} variant="danger">Confirm</Button>
                  <Button onClick={() => setConfirmDeletion(false)} className={`btn-second ${css.cancel_btn}`}>Cancel</Button>
                  </div>  
              </>
              } 
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-prime" onClick={() => onHideMoreModal("", "", "")}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  const MessageModal = () => {
    return (
      <Modal
        show={messageModalShow.show}
        onHide={() => setMessageModalShow({id: "", email: "", show: false})}
        size="lg"
        className={css.modal}
        centered 
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Message
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className={css.message}>Message <Nav.Link className={`link ${css.item_link}`}>#{messageModalShow.id}</Nav.Link>from user<Nav.Link className={`link ${css.item_link}`}>#{messageModalShow.user_id}</Nav.Link>with email<Nav.Link className={`link ${css.item_link}`}>{messageModalShow.email}</Nav.Link></div>
            <div>{messageModalShow.description}</div>
        </Modal.Body> 
        <Modal.Footer className={css.modal_message_footer}>
          <Button disabled={isDeletingFetch} onClick={() => dispatchApiDeleteSupport(messageModalShow.id)} className={css.answered_btn} variant="primary">Answered{isDeletingFetch && <Spinner size="sm"/>}</Button>
          <div className={css.modal_message_btns_wrap}>
            <Button className={css.copy_btn} variant="outline-secondary" onClick={() => (navigator.clipboard.writeText(messageModalShow.email), dispatchToast("success", "Email copied"))}>Copy email <Copy /></Button>
            <Button className="btn-prime" onClick={() => setMessageModalShow({id: "", email: "", show: false})}>Close</Button>
          </div>
        </Modal.Footer>
      </Modal> 
    );
  }
 
  return (
    <>
      {messages.map(m => 
        <tr key={m.id}>
          <td className="align-middle">{m.id}</td>
          <td className="align-middle">{m.user_id}</td>
          <td className="align-middle"><div className={css.inner}>{m.email}<div className={css.btn_wrap}><Button onClick={() => setMessageModalShow({ id: m.id, email: m.email, description: m.description, user_id: m.user_id, show: true })} className={css.info_btn} variant="info">View</Button><Button onClick={() => onShowMoreModal( m.id, m.email, m.user_id, true )} className={`btn-second ${css.more_btn}`}><ThreeDotsVertical size={"1.25rem"} /></Button></div></div></td>
        </tr>

      )}
      <MoreModal/> 
      <MessageModal/>
    </>
  )
}

export default UsersMap