import React, { useContext } from "react";
import css from "./templates_count.module.css"
import TemplatesMap from "./templates_map/templates_map.jsx"
import Table from 'react-bootstrap/Table';
import Pagination from 'react-bootstrap/Pagination';
import { Context } from "./templates_count_container.jsx";
import Spinner from "react-bootstrap/Spinner"; 

const TemplatesCount = () => {

    const { queryPage, pages, pageFirst, pagePrev, pageSelect, pageNext, pageLast, productsNotFound, isProductsFetch } = useContext(Context)

    return (
        <div className={css.templates}>
            {!productsNotFound ?
                !isProductsFetch ?
                    <> 
                        <div className={css.table_wrap}>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>#ID</th>
                                        <th>Name</th>
                                        <th>Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <TemplatesMap />
                                </tbody>
                            </Table>
                        </div>
                        <Pagination className={css.pagination}>
                            {queryPage > 1 &&
                                <>
                                    <Pagination.First className={css.pag_item} onClick={() => pageFirst()} />
                                    <Pagination.Prev className={css.pag_item} onClick={() => pagePrev()} />
                                </>
                            }
                            {pages
                                .filter(p => p >= +queryPage - 2 && p <= +queryPage + 2)
                                .map(p => (
                                    <Pagination.Item className={queryPage == p ? css.pag_item_active : css.pag_item} onClick={() => pageSelect(p)} key={p}>{p}</Pagination.Item>
                                ))
                            }
                            {queryPage < pages.length &&
                                <>
                                    <Pagination.Next className={css.pag_item} onClick={() => pageNext()} />
                                    <Pagination.Last className={css.pag_item} onClick={() => pageLast()} />
                                </>
                            }
                        </Pagination>
                    </>
                    :
                    <div className={css.spinner_wrap}>
                        <Spinner size="lg" />
                    </div>
                :
                <div className={css.not_found}>
                    <h4>Products not found.</h4>
                </div>
            }
        </div>
    )
}

export default TemplatesCount