import React, { createContext, useEffect, useState } from "react"
import Register from "./register.jsx"
import { apiRegister, apiIsUser } from "../../../../store/slices/authSlice"
import { useDispatch, useSelector } from "react-redux"
import { unwrapResult } from "@reduxjs/toolkit"
import { toast } from "../../../../store/slices/toastsSlice.js"
import { useNavigate } from "react-router-dom"
import { useGoogleLogin } from '@react-oauth/google';

const Context = createContext()

const RegisterContainer = () => {
 
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const googleLoginHook = useGoogleLogin({
        onSuccess: async tokenResponse => {
            
            const actionRes = await dispatchApiRegister({ token: tokenResponse.access_token, auth_method: "google" })
            const promiseRes = unwrapResult(actionRes) 

            switch (promiseRes.status) { 
                case "email_already_exists":
                    dispatch(toast("warning", "This email already exists"))
                    break
                case "attempts_exceeded":
                    dispatch(toast("warning", "Number of attempts exceeded. Try again later."))
                    break
                case "success":
                    await dispatchApiIsUser()
                    navigate('/register/verify')
                    break
            }

        }
    })

    const {
        connection: { isRegisteringFetch }
    } = useSelector((state) => state.auth);

    const {
        form: {
            max_user_name, min_user_name, max_password, min_password,
        }
    } = useSelector((state) => state.constants)

    const [form, setForm] = useState({
        auth_method: "default"
    })
    const [errors, setErrors] = useState({})

    const setField = (field, value) => {
        setForm({
            ...form,
            [field]: value
        });

        const fieldErrors = validateField(field, value);

        setErrors({
            ...errors,
            [field]: fieldErrors[field]
        });
    }

    const validateField = (field, value) => {
        const newError = {};

        switch (field) {
            case 'name':
                if (value && value.length < min_user_name) newError.name = `Name must be more than ${min_user_name} characters.`
                else if (value && value.length > max_user_name) newError.name = `Name must be less than ${max_user_name} characters.`
                else if (value && value.match(/[^a-zA-Z0-9 ]/g)) newError.name = 'Only characters a-z, A-Z and 0-9 are allowed'
                break; 
            case 'email':
                if (value == undefined || value == '') newError.email = 'Please enter email'
                else if (value && !value.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) newError.email = 'The entered email is not valid'
                break;
            case 'password':
                if (value == undefined || value == '') newError.password = 'Please enter password'
                else if (value && value.length < min_password) newError.password = `Old password must be more than ${min_password} characters.`
                else if (value && value.length > max_password) newError.password = `Old password must be less than ${max_password} characters.`
                break;
            case "agreement":
                if (value == undefined || value == false) newError.agreement = 'You must agree before submitting'
                break
        }

        return newError;
    }

    const validateForm = () => {
        const newErrors = {};

        const fields = ['name', 'email', 'password', "agreement"];

        fields.forEach(field => {
            const fieldErrors = validateField(field, form[field]);
            Object.assign(newErrors, fieldErrors);
        });

        return newErrors;
    }

    const validateGoogleOauth = () => {
        const newErrors = {};

        const fields = ["agreement"];

        fields.forEach(field => {
            const fieldErrors = validateField(field, form[field]);
            Object.assign(newErrors, fieldErrors);
        });

        return newErrors;
    }

    const dispatchApiRegister = (form) => {
        return dispatch(apiRegister(form))
    }

    const dispatchApiIsUser = () => {
        return dispatch(apiIsUser())
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation()

        const formErrors = validateForm()

        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors)
            dispatch(toast("warning", "Invalid form"))
        } else {
            const actionRes = await dispatchApiRegister(form)
            const promiseRes = unwrapResult(actionRes)

            const setError = (err) => {
                setErrors(err)
                dispatch(toast("warning", "Invalid form"))
            }
 
            switch (promiseRes.status) {
                case "name_already_exists":
                    setError({ name: "This name already exists" })
                    break
                case "name_another_session":
                    setError({ name: "This name is currently taken by another session, please try again later or enter another name." })
                    break
                case "email_already_exists":
                    setError({ email: "This email already exists" })
                    break
                case "attempts_exceeded":
                    dispatch(toast("warning", "Number of attempts exceeded. Try again later"))
                    break
                case "success":
                    await dispatchApiIsUser()
                    navigate('/register/verify')
                    break
            }
        }
    }

    const googleRegister = () => {
        
        const formErrors = validateGoogleOauth()

        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors)
            dispatch(toast("warning", "Invalid form"))
        } else { 
            googleLoginHook() 
        }
    }

    useEffect(() => {
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
    }, [])

    const contextData = { handleSubmit, form, errors, setField, isRegisteringFetch, googleRegister }

    return (
        <Context.Provider value={contextData}>
            <Register />
        </Context.Provider>
    )
}

export { Context }
export default RegisterContainer