import React, { useContext } from "react"
import css from "./admin.module.css"
import AdminLogin from "./admin_login/admin_login_container.jsx"
import { Link, Outlet } from "react-router-dom";
import Nav from 'react-bootstrap/Nav';
import { Context } from "./admin_container.jsx";
import Spinner from "react-bootstrap/Spinner";

const Admin = () => {
    
    const { isAuth, activeKey, isAuthFetch } = useContext(Context)

    return (   
        !isAuthFetch ?
        isAuth ? 
            <div className={css.admin}>
                <Nav className={css.nav} variant="pills" activeKey={activeKey}> 
                    <Nav.Item className={css.item}>
                        <Nav.Link as={Link} to="/admin" eventKey="">Statistics</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className={css.item}>
                        <Nav.Link as={Link} to="/admin/users?page=1" eventKey="users">Users</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className={css.item}>
                        <Nav.Link as={Link} to="/admin/authors?page=1" eventKey="authors">Authors</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className={css.item}>
                        <Nav.Link as={Link} to="/admin/templates?page=1" eventKey="templates">Templates</Nav.Link>
                    </Nav.Item> 
                    <Nav.Item className={css.item}>
                        <Nav.Link as={Link} to="/admin/support?page=1" eventKey="support">Support</Nav.Link>
                    </Nav.Item> 
                </Nav> 

                <Outlet className={css.outlet} /> 
            </div>
            :
            <AdminLogin /> 
            :
            <div className={css.spinner_wrap}> 
                <Spinner size="lg" />
            </div>
    )
}

export default Admin  