import React, { createContext, useEffect, useState } from "react"
import Templates from "./templates.jsx"
import { useLocation } from "react-router-dom";

const Context = createContext()

const TemplatesContainer = () => {

    const location = useLocation();
    const [activeKey, setActiveKey] = useState("");

    useEffect(() => {
        const path = location.pathname.split('/')[3];
        if(path) { 
            if (path == "edit") {
                setActiveKey("")
            } else {
                setActiveKey(path.split('?')[0])
            }
        } else {
            setActiveKey("")
        }
    }, [location]); 

    const contextData = { activeKey } 

    return (
        <Context.Provider value={contextData}>
            <Templates/>
        </Context.Provider>
    )
}
 
export { Context }
export default TemplatesContainer   