import React, { useContext } from "react"
import { Context } from "./register_author_container.jsx"
import css from "./register_author.module.css";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

const RegisterAuthor = () => {

    const {
        form, errors, setField, handleSubmit, isRegisteringFetch, name
    } = useContext(Context)

    return (
        <Form noValidate onSubmit={handleSubmit} className={css.form} > 
            <h3 className={css.title}>Become an author</h3>
            {name == "" &&
                <Form.Group className={css.input} controlId="name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                        value={form.name}
                        onChange={e => setField('name', e.target.value)}
                        isValid={form.name && form.name !== '' && !errors.name}
                        isInvalid={!!errors.name}
                        placeholder="Enter name"
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                </Form.Group>
            }
            <Form.Group className={css.input} controlId="country">
                <Form.Select
                    className={css.select}
                    id="country"
                    value={form.country}
                    onChange={e => setField('country', e.target.value)}
                    isValid={form.country && form.country != '' && !errors.country}
                    isInvalid={!!errors.country}
                >
                    {[
                        { n: 'Select country', v: '' },
                        { n: 'Austria', v: 'AT' },
                        { n: 'Belgium', v: 'BE' },
                        { n: 'Bulgaria', v: 'BG' },
                        { n: 'Croatia', v: 'HR' },
                        { n: 'Cyprus', v: 'CY' },
                        { n: 'Czech Republic', v: 'CZ' },
                        { n: 'Denmark', v: 'DK' },
                        { n: 'Estonia', v: 'EE' },
                        { n: 'Finland', v: 'FI' },
                        { n: 'France', v: 'FR' },
                        { n: 'Germany', v: 'DE' },
                        { n: 'Gibraltar', v: 'GI' },
                        { n: 'Greece', v: 'GR' },
                        { n: 'Hungary', v: 'HU' },
                        { n: 'Ireland', v: 'IE' },
                        { n: 'Italy', v: 'IT' },
                        { n: 'Latvia', v: 'LV' },
                        { n: 'Liechtenstein', v: 'LI' },
                        { n: 'Lithuania', v: 'LT' },
                        { n: 'Luxembourg', v: 'LU' },
                        { n: 'Malta', v: 'MT' },
                        { n: 'Netherlands', v: 'NL' },
                        { n: 'Norway', v: 'NO' },
                        { n: 'Poland', v: 'PL' },
                        { n: 'Portugal', v: 'PT' },
                        { n: 'Romania', v: 'RO' },
                        { n: 'Slovakia', v: 'SK' },
                        { n: 'Slovenia', v: 'SI' },
                        { n: 'Spain', v: 'ES' },
                        { n: 'Sweden', v: 'SE' },
                        { n: 'Switzerland', v: 'CH' },
                        { n: 'United Kingdom', v: 'GB' },        
                    ].map((el, ind) => (
                        <option hidden={el.v == '' ? true : false} key={ind} value={el.v}>{el.n}</option>
                    ))}
                </Form.Select>
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">{errors.country}</Form.Control.Feedback>
            </Form.Group>
            <Button disabled={isRegisteringFetch} className={css.btn_submit} type="submit" variant="primary"><div className={css.btn_submit_inner}>Register an author account{isRegisteringFetch && <Spinner size="sm" />}</div></Button>
            <Form.Text >
                <br />Only countries in the SEPA zone are available.
            </Form.Text>
        </Form>
    );
}

export default RegisterAuthor;