import React, { useEffect } from "react"
import Accordion from 'react-bootstrap/Accordion';
import css from "./faq.module.css"
import { PatchQuestion } from 'react-bootstrap-icons';

const FAQ = () => {

    useEffect(() => {
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
    }, [])

    return (
        <div className={css.faq}>
            <h4>Frequently asked questions<PatchQuestion size="1.5rem"/></h4>
            <Accordion defaultActiveKey="1" flush>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>1. How can I purchase a product?</Accordion.Header>
                    <Accordion.Body>
                        To purchase a product, simply select the one you like, add it to your cart and follow the checkout instructions. Once payment is completed, it may take some time for the item to appear in the 'purchased' tab.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>2. Can I sell my product on your platform?</Accordion.Header>
                    <Accordion.Body>
                        Yes! If you have your own products, you can become an author and sell them on our platform. To get started, simply register, create an author account, and upload your first product.
                    </Accordion.Body> 
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header>3. How can I install WordPress items downloaded from our platform on my website?</Accordion.Header>
                    <Accordion.Body>
                    To install WordPress items downloaded from ProDucks on your website, you will need to do so manually, either via FTP Upload or through the WordPress Dashboard.
                    </Accordion.Body> 
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header>4. How can I become an author?</Accordion.Header>
                    <Accordion.Body>
                        To become an author, register on our platform and create an author account. Given the preliminary indication of the country, we will require additional contact information and a bank account, which must be provided through the intermediary platform <a target="_blank" className="link" href="https://stripe.com/">Stripe</a>. This will ensure the strict confidentiality of the data you enter.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                    <Accordion.Header>5. What payment methods do you accept?</Accordion.Header>
                    <Accordion.Body>
                        We accept major payment methods including credit and debit cards, e-wallets, and other popular payment systems. All transactions are secured with modern encryption techniques.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                    <Accordion.Header>6. What are the payout terms for authors?</Accordion.Header>
                    <Accordion.Body>
                        Authors receive 90%, excluding conversion fees, of the original price of the product for each sale.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                    <Accordion.Header>8. What should I do if I encounter problems with a template?</Accordion.Header>
                    <Accordion.Body>
                        If you have any problems installing or using the template, you can contact our support team.
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    )
}

export default FAQ
