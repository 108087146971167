import React, { useContext } from "react"
import css from "./new_password.module.css"
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Context } from "./new_password_container.jsx";
import Spinner from "react-bootstrap/Spinner";

const EmailVerify = () => {

    const { handleSubmit, form, errors, setField, isPasswordRecoveringFetch } = useContext(Context)

    return (
        <Form noValidate onSubmit={handleSubmit} className={css.form} >
            <h3 className={css.title}>Recover password</h3>
            <Form.Group className={css.input} controlId="new_password"> 
                <Form.Label>New password</Form.Label>
                <Form.Control
                    value={form.new_password}
                    onChange={e => setField('new_password', e.target.value)}
                    isValid={form.new_password && form.new_password !== '' && !errors.new_password}
                    isInvalid={!!errors.new_password}
                    placeholder="Enter new password"
                    type="password"
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">{errors.new_password}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className={css.input} controlId="repeat_new_password"> 
                <Form.Label>Repeat new password</Form.Label>
                <Form.Control
                    value={form.repeat_new_password}
                    onChange={e => setField('repeat_new_password', e.target.value)}
                    isValid={form.repeat_new_password && form.repeat_new_password !== '' && !errors.repeat_new_password}
                    isInvalid={!!errors.repeat_new_password}
                    placeholder="Enter new password"
                    type="password"
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">{errors.repeat_new_password}</Form.Control.Feedback>
            </Form.Group>
            <Button disabled={isPasswordRecoveringFetch} type="submit" variant="primary" className={css.btn_submit}><div className={css.btn_submit_inner}>Recover{isPasswordRecoveringFetch && <Spinner size="sm" />}</div></Button>
            <Form.Text >
                <br />Session expires 10 minutes after confirming email.
            </Form.Text>
        </Form>
    )
}

export default EmailVerify 