import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import './bootstrap.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App'
import reportWebVitals from './reportWebVitals'
import { store } from "./store/redux.js"
import { Provider } from 'react-redux'
import { GoogleOAuthProvider } from '@react-oauth/google';

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
 
    <GoogleOAuthProvider clientId="512704376247-2gst95n0rfaj38tccudoak1ffhg3bgvq.apps.googleusercontent.com">
      <App />
    </GoogleOAuthProvider>
)
 
reportWebVitals()
