import React, { createContext, useEffect, useRef, useState } from "react"
import Templates from "./templates.jsx"
import { useDispatch, useSelector } from "react-redux"
import {
    apiGetAuthorTemplates, apiDeleteAuthorProduct, apiAuthorGetInfo, apiGetAuthorStatus,
    apiGetAuthorOfferUploadsStatus, setIsAuthorFetch, apiGetAuthorStripeLink
} from "../../../../store/slices/accountSlice.js"
import useAuthRedirect from "../../../../middleware/isAuth.jsx"
import { useSearchParams } from "react-router-dom"

const Context = createContext()

export const useDidMount = () => {
    const isMountRef = useRef(true);
    useEffect(() => {
        isMountRef.current = false; 
    }, []);
    return isMountRef.current;
};

const TemplatesContainer = () => {

    const { isAuthDispatch, isAuthLineDispatch } = useAuthRedirect()

    const dispatch = useDispatch()

    const didMount = useDidMount()

    const {
        author: { status, uploadStatus, products, balance, currency, productsNotFound, limit, count, details,
            connection: { isProductDeletingFetch, isUploadStatusFetch, isInfoFetch, isProductsFetch,
                isFetch, isStatusFetch, isAuthorStatusFetch, isOnboardingFetch, isEditingFetch
            },
            placeholders: { productsPlaceholders }
        },
    } = useSelector((state) => state.account);

    const {
        isAuth, isAuthorRegistered,
        connection: { isAuthFetch }
    } = useSelector((state) => state.auth);

    const dispatchApiGetAuthorTemplates = () => {
        return isAuthDispatch(apiGetAuthorTemplates, { limit, offset })
    }

    const dispatchApiGetAuthorStatus = () => {
        return isAuthDispatch(apiGetAuthorStatus)
    }

    const dispatchApiGetAuthorOfferUploadsStatus = () => {
        return isAuthDispatch(apiGetAuthorOfferUploadsStatus)
    }

    const dispatchApiAuthorGetBalance = () => {
        return isAuthDispatch(apiAuthorGetInfo)
    }

    const dispatchSetIsAuthorFetch = (bool) => {
        dispatch(setIsAuthorFetch(bool))
    }

    const pageLoad = async () => {
        if (isAuthorRegistered && isAuth) {
            dispatchSetIsAuthorFetch(true)
            await isAuthLineDispatch([ 
                dispatchApiGetAuthorOfferUploadsStatus,
                dispatchApiGetAuthorTemplates,
                dispatchApiGetAuthorStatus,
                dispatchApiAuthorGetBalance])
            dispatchSetIsAuthorFetch(false)
        }
    }

    useEffect(() => {
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
        pageLoad()
    }, [isAuthFetch]);

    const [moreModalShow, setMoreModalShow] = useState({ id: "", name: "", show: false });

    const onShowMoreModal = (id, name) => {
        setMoreModalShow({ id, name, show: true })
    }

    const onHideMoreModal = (id, name) => {
        setConfirmDeletion(false)
        setMoreModalShow({ id, name, show: false })
    }

    const [infoModalShow, setInfoModalShow] = useState(false);

    const [confirmDeletion, setConfirmDeletion] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams()

    let queryPage = searchParams.get("page")

    let pagesCount = Math.ceil(count / limit)

    const offset = (+queryPage - 1) * limit

    let pages = [];
    for (let i = 1; i <= pagesCount; i++) {
        pages.push(i);
    }

    function pageFirst() {
        setSearchParams({ page: 1 })
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
    }

    function pagePrev() {
        setSearchParams({ page: +queryPage - 1 })
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
    }

    function pageSelect(p) {
        setSearchParams({ page: queryPage = p })
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
    }

    function pageNext() {
        setSearchParams({ page: +queryPage + 1 })
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
    }

    function pageLast() {
        setSearchParams({ page: pagesCount })
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
    }

    useEffect(() => {
        if (!didMount) {
            dispatchApiGetAuthorTemplates()
        }
    }, [queryPage])

    const dispatchApiDeleteAuthorProduct = async (id) => {
        setConfirmDeletion(false)
        await isAuthDispatch(apiDeleteAuthorProduct, { id })
        setMoreModalShow({ id: '', name: '', show: false })
        dispatchApiGetAuthorTemplates()
    }

    const dispatchApiGetAuthorStripeLink = (point) => {
        isAuthDispatch(apiGetAuthorStripeLink, {point})
    }

    const parsedDate = new Date(details.created * 1000); 
    const year = parsedDate.getFullYear(); 
    const month = ("0" + (parsedDate.getMonth() + 1)).slice(-2);
    const date = ("0" + (parsedDate.getDate())).slice(-2);
    const convertedCreated = `${date}.${month}.${year}`

    const contextData = {
        products, moreModalShow, pages, dispatchApiDeleteAuthorProduct, isProductDeletingFetch, isProductsFetch,
        pageFirst, pagePrev, pageSelect, pageNext, pageLast, queryPage, isStatusFetch, productsPlaceholders,
        confirmDeletion, setConfirmDeletion, onShowMoreModal, onHideMoreModal, isAuthorRegistered, uploadStatus, balance, currency, isAuthFetch,
        productsNotFound, isFetch, isUploadStatusFetch, isInfoFetch, isAuth, isAuthorStatusFetch, status, dispatchApiGetAuthorStripeLink,
        isOnboardingFetch, infoModalShow, setInfoModalShow, details, convertedCreated, isEditingFetch
    }

    return (
        <Context.Provider value={contextData}>
            <Templates />
        </Context.Provider>
    )
}

export { Context }
export default TemplatesContainer