import React, { useContext } from "react"
import css from "./settings.module.css"
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Context } from "./settings_container.jsx";
import Spinner from "react-bootstrap/Spinner";
import Collapse from 'react-bootstrap/Collapse';

const Settings = () => {

    const { isAccountDeletingFetch, isChangingPasswordFetch,
        formChangePassword, errorsChangePassword, handleSubmitChangePassword, handleCollapseShowChangePassword, setFieldChangePassword, isCollapseShowChangePassword,
        formDeleteAuthor, errorsDeleteAuthor, handleSubmitDeleteAuthor, setFieldDeleteAuthor, confirmAuthorDeletion, handleConfirmAuthorDeletion,
        formDeleteAccount, errorsDeleteAccount, handleSubmitDeleteAccount, setFieldDeleteAccount, confirmAccountDeletion, setConfirmAccountDeletion,
        isAuthorDeletingFetch } = useContext(Context)

    return (
        <>
            <section className={css.area}>
                <h4>Security</h4>
                <div className={css.setting}>
                    <div className={css.setting_inner}>Change password 
                        <Button
                            variant="outline-secondary"
                            onClick={() => handleCollapseShowChangePassword(!isCollapseShowChangePassword)}
                            aria-controls="passwords"
                            aria-expanded={isCollapseShowChangePassword}
                        >
                            Change
                        </Button></div>
                    <Collapse className={css.collapse} in={isCollapseShowChangePassword}>
                        <Form onSubmit={handleSubmitChangePassword} noValidate>
                            <div id="passwords">
                                <Form.Group className={css.input} controlId="old_password">
                                    <Form.Label>Old password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="Enter old password"
                                        value={formChangePassword.old_password}
                                        onChange={e => setFieldChangePassword('old_password', e.target.value)}
                                        isValid={formChangePassword.old_password !== undefined && formChangePassword.old_password !== '' && !errorsChangePassword.old_password}
                                        isInvalid={!!errorsChangePassword.old_password}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">{errorsChangePassword.old_password}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className={css.input} controlId="new_password">
                                    <Form.Label>New password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="Enter new password"
                                        value={formChangePassword.new_password}
                                        onChange={e => setFieldChangePassword("new_password", e.target.value)}
                                        isValid={formChangePassword.new_password !== undefined && formChangePassword.new_password !== '' && !errorsChangePassword.new_password}
                                        isInvalid={!!errorsChangePassword.new_password}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">{errorsChangePassword.new_password}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className={css.input} controlId="repeat_new_password">
                                    <Form.Label>Repeat new password</Form.Label>
                                    <Form.Control
                                        value={formChangePassword.repeat_new_password}
                                        onChange={e => setFieldChangePassword('repeat_new_password', e.target.value)}
                                        isValid={formChangePassword.repeat_new_password && formChangePassword.repeat_new_password !== '' && !errorsChangePassword.repeat_new_password}
                                        isInvalid={!!errorsChangePassword.repeat_new_password}
                                        placeholder="Enter new password"
                                        type="password"
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">{errorsChangePassword.repeat_new_password}</Form.Control.Feedback>
                                </Form.Group>
                            </div>
                            <Button disabled={isChangingPasswordFetch} type="submit" variant="primary" className={css.btn_submit}><div className={css.btn_submit_inner}>Change{isChangingPasswordFetch && <Spinner size="sm" />}</div></Button>
                        </Form>
                    </Collapse>
                </div>
            </section>
            <section className={css.area}>
                <h4>Account</h4>
                <div className={css.setting}>
                    {!confirmAuthorDeletion ?
                        <div className={css.setting_inner}> 
                            <div>Delete author account</div>
                            <Button disabled={isAuthorDeletingFetch} onClick={() => handleConfirmAuthorDeletion(true)} variant="warning"><div className={css.btn_submit_inner}>Delete{isAuthorDeletingFetch && <Spinner size="sm" />}</div></Button>
                        </div>
                        :
                        <div className={css.setting_inner}>
                            <div>Confirm deletion. All data will be permanently deleted</div>
                            <></>
                            <Button onClick={() => handleConfirmAuthorDeletion(false)}  className="btn-second">Cancel</Button>
                        </div>
                    }
                    <Collapse className={css.collapse} in={confirmAuthorDeletion}>
                        <Form onSubmit={handleSubmitDeleteAuthor} noValidate>
                            <Form.Group className={css.input} controlId="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    value={formDeleteAuthor.email}
                                    onChange={e => setFieldDeleteAuthor('email', e.target.value)}
                                    isValid={formDeleteAuthor.email && formDeleteAuthor.email !== '' && !errorsDeleteAuthor.email}
                                    isInvalid={!!errorsDeleteAuthor.email}
                                    placeholder="Enter email"
                                    type="email"
                                />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">{errorsDeleteAuthor.email}</Form.Control.Feedback>
                            </Form.Group>
                            <Button type="submit" className={css.btn_submit} variant="warning">Confirm</Button>
                        </Form>
                    </Collapse> 
                    <hr />
                    {!confirmAccountDeletion ?
                        <div className={css.setting_inner}>
                            <div>Delete account</div>
                            <Button disabled={isAccountDeletingFetch} onClick={() => setConfirmAccountDeletion(true)} variant="danger"><div className={css.btn_submit_inner}>Delete{isAccountDeletingFetch && <Spinner size="sm" />}</div></Button>
                        </div>
                        :
                        <div className={css.setting_inner}>
                            <div>Confirm deletion. All data will be permanently deleted</div>
                            <></>
                            <Button onClick={() => setConfirmAccountDeletion(false)} className="btn-second">Cancel</Button>
                        </div>
                    }
                     <Collapse className={css.collapse} in={confirmAccountDeletion}>
                        <Form onSubmit={handleSubmitDeleteAccount} noValidate>
                            <Form.Group className={css.input} controlId="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    value={formDeleteAccount.email}
                                    onChange={e => setFieldDeleteAccount('email', e.target.value)}
                                    isValid={formDeleteAccount.email && formDeleteAccount.email !== '' && !errorsDeleteAccount.email}
                                    isInvalid={!!errorsDeleteAccount.email}
                                    placeholder="Enter email"
                                    type="email"
                                />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">{errorsDeleteAccount.email}</Form.Control.Feedback>
                            </Form.Group>
                            <Button type="submit" className={css.btn_submit} variant="danger">Confirm</Button>
                        </Form>
                    </Collapse> 
                </div>
            </section>
        </>
    )
}


export default Settings