import { React, useContext } from "react";
import { Context } from "../basket_container.jsx";
import css from "./basket_products_map.module.css"
import Card from 'react-bootstrap/Card';
import Ratio from 'react-bootstrap/Ratio';
import { CurrencyDollar, Percent } from 'react-bootstrap-icons';
import CloseButton from 'react-bootstrap/CloseButton';
import { Link } from 'react-router-dom'
import Button from "react-bootstrap/esm/Button";
import Placeholder from 'react-bootstrap/Placeholder';

const BasketProductsMap = () => {

    const { products, isBasketFetch, isRemovingFetch, productsPlaceholders,
        dispatchApiRemoveProductsFromBasket } = useContext(Context)

    return (
        !isBasketFetch && !isRemovingFetch ?
            products?.map((p, i) =>
                <Card key={i} className={css.card}>
                    <Ratio className={css.ratio} aspectRatio="16x9">
                        <Card.Img className={css.img} src={`https://storageplatform0.blob.core.windows.net/products/${p.id}/image/${p.img}?${new Date().getTime()}`} />
                    </Ratio>
                    <Card.Body className={css.info}>
                        <div className={css.text}>
                            <Card.Title className={css.name}>{p.name}</Card.Title>
                            <Card.Text className={css.author}><i>{p.author != "" ? "by " + p.author : "no author specified"}</i></Card.Text>
                        </div>
                        <div className={css.btns}>
                            <Button as={Link} className="btn-prime" to={`/categories/product?id=${p.id}`}>Info</Button>
                            <div className={css.price}>{p.discount != 0 ?  p.price_with_discount.toString().replace(".", ",") : p.price.toString().replace(".", ",")}<CurrencyDollar size="1rem" /></div>
                            <CloseButton onClick={() => dispatchApiRemoveProductsFromBasket(p.id)} className={css.close_btn_xsm} />
                        </div>
                    </Card.Body>
                    <CloseButton onClick={() => dispatchApiRemoveProductsFromBasket(p.id)} className={css.close_btn_sm} />
                    {p.discount != 0 && <span className={css.discount}>-{p.discount}<Percent size={"1.25rem"} /></span>}
                </Card>
            )
            :
            Array.from({ length: productsPlaceholders }).map((_, i) =>
                <Card key={i} className={css.card}>
                    <Placeholder className={css.ratio} animation="glow">
                        <Ratio aspectRatio="16x9">
                            <Placeholder className={css.img_placeholder} />
                        </Ratio>
                    </Placeholder>
                    <Card.Body className={css.info}>
                        <div className={css.text}>
                            <Placeholder className={css.name} as={Card.Title} animation="glow">
                                <Placeholder xs={9} />
                            </Placeholder>
                            <Placeholder className={css.author} as={Card.Text} animation="glow">
                                <Placeholder xs={5} />
                            </Placeholder>
                        </div>
                        <div className={css.btns}>
                            <Placeholder.Button className="btn-prime">Info</Placeholder.Button>
                            <Placeholder className={css.price} animation="glow">
                                <Placeholder className={css.price_placeholder} />
                            </Placeholder>
                            <CloseButton disabled className={css.close_btn_xsm} />
                        </div>
                    </Card.Body>
                    <CloseButton disabled className={css.close_btn_sm} />
                </Card>
            )
    )
}

export default BasketProductsMap 