import React, { useContext } from "react"
import css from "./users_map.module.css"
import Button from 'react-bootstrap/Button';
import { ThreeDotsVertical } from "react-bootstrap-icons"
import Modal from 'react-bootstrap/Modal';
import { Context } from "../users_container";
import Spinner from 'react-bootstrap/Spinner';
import Nav from 'react-bootstrap/Nav';

const UsersMap = () => {

  const { users, moreModalShow, onShowMoreModal, onHideMoreModal, isUserDeletingFetch, confirmAccountDeletion, setConfirmAccountDeletion,
    confirmAuthorDeletion, setConfirmAuthorDeletion, dispatchApiDeleteUser, isAuthorDeletingFetch, dispatchApiDeleteAuthor
   } = useContext(Context)

  const MoreModal = () => {
    return (
      <Modal 
        show={moreModalShow.show}
        onHide={() => onHideMoreModal("", "")}
        size="md"
        className={css.modal}
        centered 
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Information
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={css.item}>User <Nav.Link className={`link ${css.item_link}`}>#{moreModalShow.id}</Nav.Link></div>
          <div className={css.action}>
            {!confirmAccountDeletion ?
            <>
               <div>Delete user</div>
               <div className={css.action_btns}>
               <Button disabled={isUserDeletingFetch} onClick={() => setConfirmAccountDeletion(true)} className={css.del_btn} variant="danger">Delete{isUserDeletingFetch && <Spinner size="sm"/>}</Button>
               </div>
            </>
              :
              <>
                <div>Confim deletion?</div>
                <div className={css.action_btns}>
                <Button onClick={() => dispatchApiDeleteUser(moreModalShow.id)} className={css.del_btn} variant="danger">Confirm</Button>
                <Button onClick={() => setConfirmAccountDeletion(false)} className={`btn-second ${css.cancel_btn}`}>Cancel</Button>
                </div>  
              </>
            } 
          </div>
          <div className={css.action}>
            {!confirmAuthorDeletion ?
            <>
               <div>Delete author</div>
               <div className={css.action_btns}>
               <Button disabled={isAuthorDeletingFetch} onClick={() => setConfirmAuthorDeletion(true)} className={css.del_btn} variant="warning">Delete{isAuthorDeletingFetch && <Spinner size="sm"/>}</Button>
               </div>
            </>
              :
              <> 
                <div>Confim deletion?</div>
                <div className={css.action_btns}>
                <Button onClick={() => dispatchApiDeleteAuthor(moreModalShow.id)} className={css.del_btn} variant="warning">Confirm</Button>
                <Button onClick={() => setConfirmAuthorDeletion(false)} className={`btn-second ${css.cancel_btn}`}>Cancel</Button>
                </div>  
              </>
            } 
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-prime" onClick={() => onHideMoreModal("", "")}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <>
      {users.map(u =>
        <tr key={u.id}>
          <td className="align-middle">{u.id}</td>
          <td className="align-middle">{u.name == "" ? <i>-</i> : u.name}</td>
          <td className="align-middle"><div className={css.inner}>{u.email == "" ? <i>-</i> : u.email}<Button onClick={() => onShowMoreModal(u.id, u.name)} className={`btn-second ${css.more_btn}`}><ThreeDotsVertical size={"1.25rem"} /></Button></div></td>
        </tr>

      )}
      <MoreModal />
    </>
  )
}

export default UsersMap