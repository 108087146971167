import React from "react";
import Toasts from "./toasts.jsx"
import { useDispatch, useSelector } from "react-redux"
import { close } from "../../store/slices/toastsSlice.js"

const ToastsContainer = () => {

    const dispatch = useDispatch() 

    const toasts = useSelector((state) => state.toasts.toasts)

    const dispatchClose = (data) => { 
        dispatch(close(data))
    }
 
    return (
        <Toasts 
            toasts={toasts}
            dispatchClose={dispatchClose}
        />
    )
}

export default ToastsContainer