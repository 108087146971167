import React, { createContext, useEffect, useState } from "react";
import Admin from "./admin.jsx"
import { useDispatch, useSelector } from "react-redux"
import { apiIsAdmin } from "../../store/slices/adminSlice.js"
import { useLocation } from "react-router-dom";

const Context = createContext()

const AdminContainer = () => {

    const dispatch = useDispatch() 
 
    const dispatchApiIsAdmin = () => {
        dispatch(apiIsAdmin()) 
    }
 
    const {
        isAuth, connection: {
            isAuthFetch
        }
    } = useSelector((state) => state.admin)

    useEffect(() => {
        dispatchApiIsAdmin();
    }, []);

    const location = useLocation();
    const [activeKey, setActiveKey] = useState("");
 
    useEffect(() => {
        const path = location.pathname.split('/')[2];
        setActiveKey(path ? path.split('?')[0] : "");
    }, [location]); 

    const contextData = { isAuth, activeKey, isAuthFetch }

    return (
        <Context.Provider value={contextData}>
            <Admin />
        </Context.Provider>
    )
}

export { Context }
export default AdminContainer