import { React, useContext } from "react";
import { Context } from "../purchased_container.jsx"
import css from "./purchased_map.module.css"
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Ratio from 'react-bootstrap/Ratio';
import { ThreeDotsVertical, Download } from 'react-bootstrap-icons';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import Pagination from 'react-bootstrap/Pagination';
import Placeholder from 'react-bootstrap/Placeholder';
import Spinner from 'react-bootstrap/Spinner';
import Nav from 'react-bootstrap/Nav';

const PurchasedMap = () => {

  const { products, moreModalShow, pages, dispatchApiPurchaseDelete, isPurchaseDeletingFetch,
    pageFirst, pagePrev, pageSelect, pageNext, pageLast, queryPage, isPurchasesFetch, purchasesPlaceholders,
    confirmDeletion, setConfirmDeletion, onShowMoreModal, onHideMoreModal, isDownloadingFetch, downloadingId,
    dispatchApiGetBody } = useContext(Context)

  const MoreModal = () => {
    return (
      <Modal
        show={moreModalShow.show}
        onHide={() => onHideMoreModal("", "")}
        size="md"
        className={css.modal}
        centered
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Information
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={css.item}>Product <Nav.Link className={`link ${css.item_link}`} as={Link} to={`/categories/product?id=${moreModalShow.id}`}>{moreModalShow.name}</Nav.Link></div>
          <div className={css.action}>
            {!confirmDeletion ?
              <>
                <div>Delete product from your purchases</div>
                <div className={css.action_btn_wrap}>
                  <Button disabled={isPurchaseDeletingFetch} onClick={() => setConfirmDeletion(true)} className={css.del_btn} variant="danger">Delete{isPurchaseDeletingFetch && <Spinner size="sm" />}</Button>
                </div>
              </>
              :
              <>
                <div>Confim deletion?</div>
                <div className={css.action_btn_wrap}>
                  <Button onClick={() => dispatchApiPurchaseDelete(moreModalShow.id)} className={css.del_btn} variant="danger">Confirm</Button>
                  <Button onClick={() => setConfirmDeletion(false)} className={`btn-second ${css.cancel_btn}`}>Cancel</Button>
                </div>
              </>
            }
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-prime" onClick={() => onHideMoreModal("", "")}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  console.log(products)

  return (
    <>
      {!isPurchasesFetch && !isPurchaseDeletingFetch ?
        <>
          <section className={css.cards}>
            {products?.map(p =>
              <Card key={p.id} className={css.card}>
                <Ratio className={css.ratio} aspectRatio="16x9">
                  <Card.Img className={css.img} src={`https://storageplatform0.blob.core.windows.net/purchases/${p.product_id}/image/${p.img}?${new Date().getTime()}`} />
                </Ratio>
                <Card.Body className={css.info}>
                  <div className={css.text}>
                    <Card.Title className={css.name}>{p.name}</Card.Title>
                    <Card.Text className={css.author}><i>{p.author != "" ? "by " + p.author : "no author specified"}</i></Card.Text>
                  </div>
                  <div className={css.btns}>
                    <Button as={Link} className={`btn-second ${css.info_btn}`} to={`/categories/product?id=${p.product_id}`}>Info</Button>
                    <Button disabled={isDownloadingFetch} onClick={() => dispatchApiGetBody(p.product_id)} className={`btn-prime ${css.download_btn}`}>Download{isDownloadingFetch ? downloadingId == p.product_id ? <Spinner className={css.spinner} size="sm" /> : <Download className={css.download_img} size={"1.25rem"} /> : <Download className={css.download_img} size={"1.25rem"} />}</Button>
                    <Button onClick={() => onShowMoreModal(p.product_id, p.name)} className={`btn-second ${css.more_btn_xsm}`}><ThreeDotsVertical size={"1.25rem"} /></Button>
                  </div>
                </Card.Body>
                <Button onClick={() => onShowMoreModal(p.product_id, p.name)} className={`btn-second ${css.more_btn_sm}`}><ThreeDotsVertical size={"1.25rem"} /></Button>
              </Card>
            )}
          </section>
          <section className={css.pagination}>
            <Pagination>
              {queryPage > 1 &&
                <>
                  <Pagination.First className={css.pag_item} onClick={() => pageFirst()} />
                  <Pagination.Prev className={css.pag_item} onClick={() => pagePrev()} />
                </>
              }
              {pages
                .filter(p => p >= +queryPage - 2 && p <= +queryPage + 2)
                .map(p => (
                  <Pagination.Item className={queryPage == p ? css.pag_item_active : css.pag_item} onClick={() => pageSelect(p)} key={p}>{p}</Pagination.Item>
                ))
              }
              {queryPage < pages.length &&
                <>
                  <Pagination.Next className={css.pag_item} onClick={() => pageNext()} />
                  <Pagination.Last className={css.pag_item} onClick={() => pageLast()} />
                </>
              }
            </Pagination>
          </section>
        </>
        :
        <>
          <section className={css.cards}>
            {Array.from({ length: purchasesPlaceholders }).map((_, i) =>
              <Card key={i} className={css.card}>
                <Placeholder className={css.ratio} animation="glow">
                  <Ratio className={css.ratio} aspectRatio="16x9">
                    <Placeholder className={css.img_placeholder} />
                  </Ratio>
                </Placeholder>
                <Card.Body className={css.info}>
                  <div className={css.text}>
                    <Placeholder className={css.name} as={Card.Title} animation="glow">
                      <Placeholder xs={9} />
                    </Placeholder>
                    <Placeholder className={css.author} as={Card.Text} animation="glow">
                      <Placeholder xs={5} />
                    </Placeholder>
                  </div>
                  <div className={css.btns}>
                    <Placeholder.Button className={`btn-second ${css.info_btn}`}>Info</Placeholder.Button>
                    <Placeholder.Button className={`btn-prime ${css.download_btn}`}>Download<Download className={css.download_img} size={"1.25rem"} /></Placeholder.Button>
                    <Placeholder.Button className={`btn-second ${css.more_btn_xsm}`}><ThreeDotsVertical size={"1.25rem"} /></Placeholder.Button>
                  </div>
                </Card.Body>
                <Placeholder.Button className={`btn-second ${css.more_btn_sm}`}><ThreeDotsVertical size={"1.25rem"} /></Placeholder.Button>
              </Card>
            )}
          </section>
          <section className={css.pagination}>
            <Pagination>
              <Pagination.Item disabled className={css.pag_item}>1</Pagination.Item>
            </Pagination>
          </section>
        </>
      }
      <MoreModal />
    </>
  )
}

export default PurchasedMap 