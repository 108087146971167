import React, { useContext } from "react"
import css from "./templates_add.module.css"
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Percent } from "react-bootstrap-icons";
import { Context } from "./templates_add_container";
import Spinner from "react-bootstrap/Spinner";

const TemplatesAdd = () => {

    const { isCategoriesFetch, isCreatingFetch, form, setField, errors, handleSubmit, types, topics } = useContext(Context)

    return (
        !isCategoriesFetch ?
            <Form noValidate onSubmit={handleSubmit} className={css.form} > 
                <h4 className={css.title}>Create template</h4>
                <Form.Group className={css.input} controlId="name">
                    <Form.Label>Name*</Form.Label>
                    <Form.Control
                        value={form.name}
                        onChange={e => setField('name', e.target.value)}
                        isValid={form.name !== undefined && form.name !== '' && !errors.name}
                        isInvalid={!!errors.name}
                        placeholder="Enter name"
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className={css.input} controlId="price">
                    <Form.Label>Price*</Form.Label>
                    <Form.Control
                        type="number"
                        min="0"
                        placeholder="Enter price"
                        value={form.price}
                        onChange={e => setField('price', e.target.value)}
                        isValid={form.price !== undefined && form.price !== '' && !errors.price}
                        isInvalid={!!errors.price}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">{errors.price}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className={css.input} controlId="discount">
                <Form.Label className={css.discount_label}>Discount in <Percent size="1rem"></Percent>*</Form.Label>
                    <Form.Control
                        type="number"
                        min="0"
                        placeholder="Enter discount"
                        value={form.discount} 
                        onChange={e => setField('discount', e.target.value)}
                        isValid={form.discount !== undefined && form.discount !== '' && !errors.discount}
                        isInvalid={!!errors.discount}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">{errors.discount}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="file" className={css.input}>
                    <Form.Label>File*</Form.Label>
                    <Form.Control
                        type="file"
                        onChange={e => setField('file', e.target.files[0])}
                        isValid={form.file !== undefined && form.file !== '' && !errors.file}
                        isInvalid={!!errors.file}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">{errors.file}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className={css.input} controlId="version">
                    <Form.Label>Version</Form.Label>
                    <Form.Control
                        value={form.version}
                        onChange={e => setField('version', e.target.value)}
                        isValid={form.version !== undefined && form.version !== '' && !errors.version}
                        isInvalid={!!errors.version}
                        placeholder="Enter version"
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">{errors.version}</Form.Control.Feedback>
                </Form.Group>
                <Form.Select
                    className={css.select}
                    value={form.type_id}
                    onChange={e => setField('type_id', e.target.value)}
                    isValid={form.type_id !== undefined && form.type_id !== '' && !errors.type_id}
                    isInvalid={!!errors.type_id}
                >
                    {[
                        { id: 0, name: 'Select type' },
                        ...types.map(el => ({ ...el }))
                    ].map((el, ind) => (
                        <option hidden={el.id === 0} key={ind} value={el.id}>{el.name}</option>
                    ))}
                </Form.Select>
                <Form.Select
                    className={css.select}
                    value={form.topic_id}
                    onChange={e => setField('topic_id', e.target.value)}
                    isValid={form.topic_id !== undefined && form.topic_id !== '' && !errors.topic_id}
                    isInvalid={!!errors.topic_id}
                >
                    {[
                        { id: 0, name: 'Select topic' },
                        ...topics.map(el => ({ ...el }))
                    ].map((el, ind) => (
                        <option hidden={el.id === 0} key={ind} value={el.id}>{el.name}</option>
                    ))}
                </Form.Select>
                <Form.Group controlId="img" className={css.input}>
                    <Form.Label>Image*</Form.Label>
                    <Form.Control
                        type="file"
                        onChange={e => setField('img', e.target.files[0])}
                        isValid={form.img !== undefined && form.img !== '' && !errors.img}
                        isInvalid={!!errors.img}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">{errors.img}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="img_preview" className={css.input}>
                    <Form.Label>Image preview*</Form.Label>
                    <Form.Control
                        type="file"
                        onChange={e => setField('img_preview', e.target.files[0])}
                        isValid={form.img_preview !== undefined && form.img_preview !== '' && !errors.img_preview}
                        isInvalid={!!errors.img_preview}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">{errors.img_preview}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className={css.input} controlId="description">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                        as="textarea"
                        placeholder="Enter description"
                        value={form.description}
                        onChange={e => setField('description', e.target.value)}
                        isValid={form.description !== undefined && form.description !== '' && !errors.description}
                        isInvalid={!!errors.description}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">{errors.description}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className={css.input} controlId="user_id">
                    <Form.Label>User id*</Form.Label>
                    <Form.Control
                        type="number"
                        min="0"
                        placeholder="Enter user id"
                        value={form.user_id}
                        onChange={e => setField('user_id', e.target.value)}
                        isValid={form.user_id !== undefined && form.user_id !== '' && !errors.user_id}
                        isInvalid={!!errors.user_id}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">{errors.user_id}</Form.Control.Feedback>
                </Form.Group>
                <Button disabled={isCreatingFetch} className={css.btn_submit} type="submit" variant="primary"><div className={css.btn_submit_inner}>Create{isCreatingFetch && <Spinner size="sm"/>}</div></Button>
            </Form>
            :
            <div className={css.info_fetch}>
                <Spinner size="lg" />
            </div>
    )
}


export default TemplatesAdd