import React, { useContext } from "react"
import css from "./code_verification.module.css"
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Context } from "./code_verification_container.jsx";
import Spinner from "react-bootstrap/Spinner";
import VerificationInput from "react-verification-input";

const CodeVerification = () => {

    const { handleSubmit, code, handleCode, isRegisterVerificationFetch, error } = useContext(Context)

    return (
        <Form noValidate onSubmit={handleSubmit} className={css.form}>
            <h3 className={css.title}>Enter verification code</h3>
            <div className={css.code_wrap}>
                <VerificationInput
                    value={code}
                    placeholder="•"
                    validChars="0-9"
                    classNames={{
                        container: css.code,
                        character: error ? css.code_character_err : css.code_character,
                        characterSelected: error ? css.code_character_selected_err : css.code_character_selected
                    }}
                    onChange={n => handleCode(n)}
                />
                {!!error && <div className={css.err}>{error}</div>}
            </div>
            <Button disabled={isRegisterVerificationFetch} className={css.btn_submit} type="submit" variant="primary"><div className={css.btn_submit_inner}>Verify{isRegisterVerificationFetch && <Spinner size="sm" />}</div></Button>
            <Form.Text >
                <br />Session expires 10 minutes after registration.
            </Form.Text>
        </Form>
    )
}


export default CodeVerification