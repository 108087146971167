import React, { createContext, useEffect, useState } from "react"
import AccountEdit from "./edit.jsx"
import { useDispatch, useSelector } from "react-redux"
import { apiProfileEdit, apiGetProfileInfo } from "../../../../../store/slices/accountSlice.js"
import { apiIsUser } from "../../../../../store/slices/authSlice.js"
import useAuthRedirect from "../../../../../middleware/isAuth.jsx"
import { unwrapResult } from '@reduxjs/toolkit'
import { toast } from "../../../../../store/slices/toastsSlice.js"
import { useNavigate } from "react-router-dom"

const Context = createContext()

const AccountEditContainer = () => { 

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const { isAuthDispatch } = useAuthRedirect()
 
    const {
        profile: { 
            connection: { isEditingFetch, isInfoFetch }
        },
    } = useSelector((state) => state.account);

    const { 
        form: {
            max_user_name, min_user_name
        }
    } = useSelector((state) => state.constants)

    const [form, setForm] = useState({})
    const [errors, setErrors] = useState({})

    const [initData, setInitData] = useState({});

    const dispatchApiGetProfileInfo = () => {
        return isAuthDispatch(apiGetProfileInfo)
    }

    const dispatchApiIsUser = () => {
        isAuthDispatch(apiIsUser)
    }

    const pageLoad = async () => {
        const actionRes = await dispatchApiGetProfileInfo()
        const promiseRes = unwrapResult(actionRes)
        const info = promiseRes.data
        const initData = {
            avatar: new File([''], 'empty.jpeg', {
                type: 'image/jpeg',
                lastModified: new Date(),
            }),
            name: info.name,
        }
        setInitData(initData);
        setForm(initData);
    }

    useEffect(() => {
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
        pageLoad()
    }, [])

    const dispatchApiProfileEdit = (form) => {
        return isAuthDispatch(apiProfileEdit, form)
    }

    const setField = (field, value) => {
        setForm({
            ...form,
            [field]: value
        });

        const fieldErrors = validateField(field, value);

        setErrors({
            ...errors,
            [field]: fieldErrors[field]
        });
    }

    const validateField = (field, value) => {
        const newError = {};

        switch (field) {
            case 'avatar':
                if (!value.type.startsWith("image/")) newError.avatar = 'Only images accepted(.jpeg, .png, etc)'
                break;
            case 'name':
                if (value && value.length < min_user_name) newError.name = `Name must be more than ${min_user_name} characters.`
                else if (value && value.length > max_user_name) newError.name = `Name must be less than ${max_user_name} characters.`
                else if (value && value.match(/[^a-zA-Z0-9 ]/g)) newError.name = 'Only characters a-z, A-Z and 0-9 are allowed'
                break;
        }

        return newError;
    }

    const validateForm = () => { 
        const newErrors = {};

        const fields = ['avatar', 'name'];

        fields.forEach(field => {
            const fieldErrors = validateField(field, form[field]);
            Object.assign(newErrors, fieldErrors);
        });

        return newErrors;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        const formErrors = validateForm()

        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors) 
            dispatch(toast("warning", "Invalid form"))
        } else {
            if (JSON.stringify(form) == JSON.stringify(initData) && form.avatar.size == 0) {
                dispatch(toast("secondary", "Form did not change"))
            } else {
                const formData = new FormData(); 
                for (let key in form) {
                    switch (key) {
                        case 'avatar':
                            form[key].size != 0 && formData.append(key, form[key])
                            break
                        default:
                            form[key] != initData[key] && formData.append(key, form[key])
                            break
                    }
                }
                const actionRes = await dispatchApiProfileEdit(formData)
                const promiseRes = unwrapResult(actionRes)

                const setError = (err) => {
                    setErrors(err)
                    dispatch(toast("warning", "Invalid form"))
                }
    
                switch (promiseRes.status) {
                    case "name_already_exists":
                        setError({name: "This name already exists"})
                        break
                    case "author_already_exists":
                        setError({name: "The author must have a name"})
                        break
                    case "name_another_session":
                        setError({ name: "This name is currently taken by another session, please try again later or enter another name." })
                        break
                    case "success":
                        dispatchApiIsUser()
                        navigate('/account?likes=1')
                        break
                }
            }
        }
    }

    const contextData = {
        isEditingFetch, handleSubmit, form, errors, setField, isInfoFetch
    }

    return (
        <Context.Provider value={contextData}>
            <AccountEdit />
        </Context.Provider>
    )
}

export { Context }
export default AccountEditContainer