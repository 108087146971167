import React, { createContext, useEffect, useState } from "react"
import Support from "./support.jsx"
import { apiSupport } from "../../../store/slices/accountSlice.js"
import { useDispatch, useSelector } from "react-redux"
import useAuthRedirect from "../../../middleware/isAuth.jsx"
import { useNavigate } from "react-router-dom"
import { unwrapResult } from "@reduxjs/toolkit"
import { toast } from "../../../store/slices/toastsSlice.js"

const Context = createContext()

const SupportContainer = () => {

    const dispatch = useDispatch()

    const { isAuthDispatch } = useAuthRedirect()

    const navigate = useNavigate()

    const {
        support: { connection: { isSupportMessagingFetch } }
     } = useSelector((state) => state.account)

     const { 
        form: {
            max_description,
        }
    } = useSelector((state) => state.constants)

    const [form, setForm] = useState({})
    const [errors, setErrors] = useState({})

    const setField = (field, value) => {
        setForm({  
            ...form,
            [field]: value
        });

        const fieldErrors = validateField(field, value); 

        setErrors({
            ...errors,
            [field]: fieldErrors[field]
        });
    }

    const validateField = (field, value) => {
        const newError = {};

        switch (field) {  
            case 'email':
                if (value === undefined || value === '') newError.email = 'Please enter email'
                else if (value && !value.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) newError.email = 'The entered email is not valid'
                break;
            case 'description':
                if (value === undefined || value === '') newError.description = 'Please enter description'
                else if (value && value.length > max_description) newError.description = `Description must be less than ${max_description} characters.`
                break;
        }

        return newError;
    }

    const validateForm = () => {
        const newErrors = {};

        const fields = ['description', 'email'];

        fields.forEach(field => {
            const fieldErrors = validateField(field, form[field]);
            Object.assign(newErrors, fieldErrors);
        });
 
        return newErrors;
    }

    const dispatchApiSupport = () => { 
        return isAuthDispatch(apiSupport, form)
    } 

    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        const formErrors = validateForm()

        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors)
            dispatch(toast("warning", "Invalid form"))
        } else {
            const actionRes = await dispatchApiSupport()
            const promiseRes = unwrapResult(actionRes)
            if (promiseRes.status == "message_already_sent") {
                dispatch(toast("warning", "Message is already sent to support"))
            } else if(promiseRes.status == "success") {
                dispatch(toast("success", "Message sent to support"))
                navigate("/")
            }
        }
    }

    useEffect(() => {
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
    }, [])

    const contextData = { form, errors, setField, handleSubmit, isSupportMessagingFetch }

    return (
        <Context.Provider value={contextData}>
            <Support />
        </Context.Provider >
    )
}

export { Context }
export default SupportContainer