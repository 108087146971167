import React, { createContext, useState, useEffect } from "react"
import TemplatesCount from "./templates_count.jsx"
import { useSelector } from "react-redux"
import { apiGetProducts, apiDeleteProduct } from "../../../../../store/slices/adminSlice.js"
import { useSearchParams } from "react-router-dom"
import useAuthRedirect from "../../../../../middleware/isAdminAuth.jsx"

const Context = createContext()

const TemplatesCountContainer = () => {

    const { isAuthDispatch } = useAuthRedirect() 
    
    const {
        products: {
            products, limit, count, productsNotFound,
            connection: {
                isProductsFetch,
                isDeletingFetch
            }
        }
    } = useSelector((state) => state.admin)

    const [moreModalShow, setMoreModalShow] = useState({ id: "", show: false });
    const [confirmDeletion, setConfirmDeletion] = useState(false); 

    const onShowMoreModal = (id) => {
        setMoreModalShow({ id, show: true })
    }

    const onHideMoreModal = (id) => {
        setConfirmDeletion(false) 
        setMoreModalShow({ id, show: false })
    }

    const [searchParams, setSearchParams] = useSearchParams()

    let queryPage = searchParams.get("page")

    let pagesCount = Math.ceil(count / limit)

    const offset = (+queryPage - 1) * limit

    let pages = [];
    for (let i = 1; i <= pagesCount; i++) {
        pages.push(i);
    }

    function pageFirst() {
        setSearchParams({ page: 1 })
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
    }

    function pagePrev() {
        setSearchParams({ page: +queryPage - 1 })
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
    }

    function pageSelect(p) {
        setSearchParams({ page: queryPage = p })
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
    }

    function pageNext() {
        setSearchParams({ page: +queryPage + 1 })
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
    }

    function pageLast() {
        setSearchParams({ page: pagesCount })
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
    }

    const dispatchApiGetProducts = () => {
        return isAuthDispatch(apiGetProducts, { offset, limit })
    }

    const dispatchApiDeleteProduct = async (id) => {
        setConfirmDeletion(false)
        await isAuthDispatch(apiDeleteProduct, { id })
        setMoreModalShow({ id: "", show: false })
        dispatchApiGetProducts()
    }

    useEffect(() => { 
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
        dispatchApiGetProducts()
    }, [queryPage])

    const contextData = {
        products, limit, count, productsNotFound, isProductsFetch, queryPage, pages, pageFirst, pagePrev,
        pageSelect, pageNext, pageLast, dispatchApiDeleteProduct, moreModalShow, confirmDeletion, onShowMoreModal,
        onHideMoreModal, setMoreModalShow, setConfirmDeletion, isDeletingFetch
    }

    return (
        <Context.Provider value={contextData}>
            <TemplatesCount />
        </Context.Provider>
    )
}

export { Context }
export default TemplatesCountContainer