import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setIsAuth } from "../store/slices/adminSlice";
import { toast } from "../store/slices/toastsSlice"

const useAuthRedirect = () => {

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const isAuthDispatch = async (action, data) => {

        const resultAction = await dispatch(data != undefined ? action(data) : action()); 
    
        if (action.fulfilled.match(resultAction)) {
            const responseData = unwrapResult(resultAction)
 
            if(responseData.status == "not_authorized") {
                console.log(resultAction) 
                dispatch(setIsAuth(false))
                dispatch(toast("primary", "Not authorized"))
                navigate("/admin"); 
            } 
        }
    
        return resultAction;
    };

    return { isAuthDispatch }; 
};

export default useAuthRedirect;