import React from "react";
import Toast from 'react-bootstrap/Toast';
import css from './toasts.module.css';
import CloseButton from 'react-bootstrap/CloseButton';

const Toasts = (props) => { 
    return (
            props.toasts?.map(({ id, bg, text }, i) => (
                    <Toast key={id} className={css.toast} bg={bg} style={{ bottom: `${i == 0 ? 1 : i * 3.5 + 1}rem` }}>
                        <Toast.Body style={bg == "warning" || bg == "info" || bg == "light" ? { color: "black"} : { color: "white"}}>{text}</Toast.Body>
                        <CloseButton onClick={() => props.dispatchClose(id)} className={css.close_btn} variant={bg == "warning" || bg == "info" || bg == "light" ? "dark" : "white"} />
                    </Toast>
            ))
    );
} 

export default Toasts 
