import React, { useContext } from "react"
import css from "./admin_login.module.css"
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Context } from "./admin_login_container";
import Spinner from "react-bootstrap/Spinner";
import Alert from 'react-bootstrap/Alert';

const AdminLogin = () => {

  const { handleSubmit, form, errors, setField, isLoginingFetch, setShow } = useContext(Context)

  return (
    <>
      <div className={css.alert_wrap}>
        <Alert className={css.alert} variant="warning" onClose={() => setShow(false)} dismissible>
          <Alert.Heading>Warning!</Alert.Heading>
          <p className={css.alert_text}>
            Only administrators are allowed to log in. Please use administrator
            credentials to access. If you do not have the appropriate rights, please return back.
          </p>
        </Alert>
      </div>
      <Form noValidate onSubmit={handleSubmit} className={css.form} >
        <h3 className={css.title}>Login to the admin panel</h3>
        <Form.Floating controlId="name" className={css.input}>
          <Form.Control
            id="name"
            placeholder="Name"
            value={form.name}
            onChange={e => setField('name', e.target.value)}
            isValid={form.name && form.name != '' && !errors.name}
            isInvalid={!!errors.name}
          />
          <label htmlFor="name">Name</label>
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
        </Form.Floating>
        <Form.Floating controlId="password" className={css.input}>
          <Form.Control
            id="password"
            type="password"
            placeholder="Password"
            value={form.password}
            onChange={e => setField('password', e.target.value)}
            isValid={form.password && form.password != '' && !errors.password}
            isInvalid={!!errors.password}
          />
          <label htmlFor="password">Password</label>
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
        </Form.Floating>
        <Button disabled={isLoginingFetch} type="submit" variant="primary" className={css.btn_submit}><div className={css.btn_submit_inner}>Log in{isLoginingFetch && <Spinner size="sm" />}</div></Button>
      </Form>
    </>
  )
}

export default AdminLogin