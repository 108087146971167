import React, { createContext,  useEffect, useState  } from "react"
import Uploadtemplate from "./upload_template.jsx"
import { useDispatch, useSelector } from "react-redux"
import { apiAuthorUpload } from "../../../../../store/slices/accountSlice.js"
import { apiGetCategories } from "../../../../../store/slices/categoriesSlice.js"
import useAuthRedirect from "../../../../../middleware/isAuth.jsx"
import { useNavigate } from "react-router-dom"
import { unwrapResult } from "@reduxjs/toolkit"
import { toast } from "../../../../../store/slices/toastsSlice.js"

const Context = createContext()

const UploadTemplateContainer = () => {
 
    const dispatch = useDispatch()
 
    const { isAuthDispatch } = useAuthRedirect()
 
    const navigate = useNavigate()

    const {
        author: { connection: { isUploadingFetch } }
    } = useSelector((state) => state.account)

    const { 
        products: {
            navigation: { types, topics },
        }
    } = useSelector((state) => state.categories)

    const { 
        form: {
            max_price, min_price, max_description, max_file_bytes, max_file_megabytes, file_extension, max_product_name
        }
    } = useSelector((state) => state.constants)

    const dispatchApiAuthorUpload = () => {
        return isAuthDispatch(apiAuthorUpload, form)
    } 

    const dispatchApiGetCategories = () => {
        isAuthDispatch(apiGetCategories) 
    }

    useEffect(() => {
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
        dispatchApiGetCategories()
    }, []); 

    const [form, setForm] = useState({})
    const [errors, setErrors] = useState({}) 

    const setField = (field, value) => {
        setForm({
            ...form,
            [field]: value
        });

        const fieldErrors = validateField(field, value);
        
        setErrors({
            ...errors,
            [field]: fieldErrors[field]
        });
    }

    const validateField = (field, value) => {
        const newError = {};
     
        switch (field) {
            case 'name':
                if (value === undefined || value === '') newError.name = 'Please enter name'
                else if (value && value.length > max_product_name) newError.name = `The name must be less than ${max_product_name} characters.`
                break;
            case 'price':
                if (value === undefined || value === '') newError.price = 'Please enter price'
                else if (value && value > max_price) newError.price = `The price must be less than ${max_price} dollars.`
                else if (value && value < min_price) newError.price = `The price must be more than ${min_price} dollars.`
                else if (value && value <= 0) newError.price = 'The price cannot be equal to zero or less than it.'
                break;
            case 'file':
                if (value === undefined || value === '') newError.file = 'Please select file'
                else if (value) {
                    const even = (el) => value.name.split('.').pop() == el; 
                    if(!file_extension.some(even)) newError.file = 'Only certain archives accepted(.zip, .rar, .7z)'
                }
                else if (value && value.size > max_file_bytes) newError.file = `The file size should not be more than ${max_file_megabytes} megabytes`
                break;
            case 'description': 
                if (value && value.length > max_description) newError.description = `Description must be less than ${max_description} characters.`
                break;
        }
    
        return newError;
    }
    
    const validateForm = () => {
        const newErrors = {};
    
        const fields = ['name', 'price', 'file', 'description'];
    
        fields.forEach(field => {
            const fieldErrors = validateField(field, form[field]);
            Object.assign(newErrors, fieldErrors);
        });
    
        return newErrors;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        const formErrors = validateForm()

        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors)
            dispatch(toast("warning", "Invalid form"))
        } else { 
            const formData = new FormData();
            for (let key in form) {
                formData.append(key, form[key])
            }
            const actionRes = await dispatchApiAuthorUpload(formData) 
            const promiseRes = unwrapResult(actionRes)

            switch (promiseRes.status) { 
                case "already_uploaded":
                    dispatch(toast("warning", "Product is already uploaded")) 
                    break
                case "too_many_uploads":
                    dispatch(toast("warning", "Too many products in processing, try again later")) 
                    break
                case "success":
                    dispatch(toast("success", "Product uploaded"))
                    break
            }
            navigate("/account/author?page=1")
        }
    }

    const contextData = {
        types, topics, handleSubmit, errors, form, setField, isUploadingFetch
    }
    
    return (
        <Context.Provider value={contextData}>
            <Uploadtemplate />
        </Context.Provider>
    )
}

export { Context }
export default UploadTemplateContainer