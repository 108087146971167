import React, { createContext, useEffect } from "react";
import Header from "./header.jsx"
import { useDispatch, useSelector } from "react-redux"
import { apiIsUser } from "../../store/slices/authSlice"

const Context = createContext()

const HeaderContainer = () => {
 
    const dispatch = useDispatch()

    const {
        email, name, isAuth, 
        connection: {
            isAuthFetch
        }
    } = useSelector((state) => state.auth);
 
    const dispatchApiIsUser = () => {
        dispatch(apiIsUser())
    }

    useEffect(() => {
        dispatchApiIsUser() 
    }, [])

    const contextData = { name, email, isAuth, isAuthFetch }
  
    return (
        <Context.Provider value={contextData}>
            <Header />
        </Context.Provider>
    )
}

export { Context }
export default HeaderContainer