import React, { useEffect } from "react"
import css from "./about_us.module.css"
import { Telegram, EnvelopeAt } from 'react-bootstrap-icons';

const AboutUs = () => {

    useEffect(() => {
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
    }, [])

    return (
        <div className={css.about_us}>
            <h3 className={css.header}>About us</h3>
            <hr />
            <h4>Platform</h4>
            <p>Welcome to our platform — a space where innovation meets design! We offer the best digital solutions for your business: from online stores and blogs to portfolios and corporate websites.</p>
            <p>Our mission is to provide you with high-quality, easily customizable templates that will help you create a modern and functional website without the need for deep technical knowledge. We are proud that our products help businesses, creatives and bloggers around the world create unique projects, attracting customers and partners.</p>
            <p>On our platform, everyone can not only buy, but also sell their digital products. If you are a developer or designer, you have the opportunity to become an author and offer your templates and digital products to a wide audience. We give creative people and professionals the opportunity to monetize their skills and share their work with the world.</p>
            <p>Join us, start selling your templates or find perfect solutions for your online projects today!</p>
            <h4>Developer</h4>
            <p>The platform was developed and is still maintained by one developer, whose goal is to create a convenient and intuitive space where everyone can easily find or sell quality digital solutions.</p>
            <h4>Contacts</h4>
            <div className={css.contacts_inner}>
                <a className={`link ${css.telegram}`} href="https://t.me/iasgkoyd">Telegram<Telegram className={css.telegram_img} size="1.5rem"/></a>
                <a className={`link ${css.envelope}`}>da1l1s1v1@gmail.com<EnvelopeAt className={css.envelope_img}  size="1.5rem"/></a>
            </div>
        </div>
    )
}

export default AboutUs
