import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { adminApi } from "../../api/api.js"
import { toast } from "./toastsSlice.js"
import { apiGetCategories } from "./categoriesSlice.js"
import handleError from "./error/error.js"

const initialState = {
    isAuth: false,
    statistics: {
        statistics: {
            counts: {},
            charts: {
                months: [],
                intervals: {}
            }
        }, 
        connection: {
            isStatisticsFetch: true
        }
    },
    users: {
        users: [],
        limit: 24,
        count: 0,
        connection: {
            isUsersFetch: true,
            isUserAddingFetch: false,
            isUserDeletingFetch: false,
            isAuthorDeletingFetch: false
        },
        usersNotFound: false,
    },
    uploads: {
        uploads: [],
        limit: 24,
        count: 0,
        info: {
            id: 0,
            name: "",
            price: 0,
            file: "",
            version: "",
            type_id: 0,
            topic_id: 0,
            description: "",
            user_id: 0
        },
        connection: {
            isUploadInfoFetch: true,
            isUploadsFetch: true,
            isUploadRejectingFetch: false,
            isConfirmingFetch: false,
        },
        uploadsNotFound: false,
    },
    products: {
        products: [],
        limit: 24,
        count: 0,
        edit: {
            info: {
                id: 0,
                name: "",
                price: 0,
                discount: 0,
                file: "",
                version: "",
                type_id: 0,
                topic_id: 0,
                img: "",
                img_preview: "",
                description: "",
                user_id: 0,
            },
            connection: {
                isEditingFetch: false,
                isEditFetch: true,
            }
        },
        create: {
            connection: {
                isCreatingFetch: false
            }
        },
        categories: {
            categories: [],
            limit: 12,
            count: 0,
            connection: {
                isCategoriesFetch: true,
                isDeltetingFetch: false,
                isAddingFetch: false
            },
            categoriesNotFound: false,
        },
        connection: {
            isProductsFetch: true,
            isDeletingFetch: false
        },
        productsNotFound: false,
    },
    support: {
        messages: [],
        limit: 24, 
        count: 0,
        connection: {
            isSupportFetch: true,
            isDeletingFetch: false
        },
        messagesNotFound: false
    },
    connection: {
        isAuthFetch: true,
        isLoginingFetch: false
    }
}

export const adminSlice = createSlice({
    name: "admin",
    initialState,
    reducers: {
        setIsAuth: (state, action) => {
            state.isAuth = action.payload
        },
    },
    extraReducers(builder) {
        builder
            .addCase(apiIsAdmin.pending, (state, _) => {
                state.isAuth = false
                state.connection.isAuthFetch = true 
            })
            .addCase(apiIsAdmin.fulfilled, (state, action) => { 
                if(action.payload.status == "success") {
                    state.isAuth = true
                } 
                state.connection.isAuthFetch = false
            })
            .addCase(apiLogin.pending, (state, _) => {
                state.connection.isLoginingFetch = true
            })
            .addCase(apiLogin.fulfilled, (state, _) => { 
                state.connection.isLoginingFetch = false
            })
            .addCase(apiLogin.rejected, (state, _) => { 
                state.connection.isLoginingFetch = false
            })
            .addCase(apiGetStatistics.pending, (state, _) => {
                state.statistics.connection.isStatisticsFetch = true 
            })
            .addCase(apiGetStatistics.fulfilled, (state, action) => { 
                if(action.payload.status == "success") { 
                    Object.assign(state.statistics.statistics, action.payload.data);
                } 
                state.statistics.connection.isStatisticsFetch = false 
            })
            .addCase(apiGetUsers.pending, (state, _) => { 
                state.users.connection.isUsersFetch = true
                state.users.usersNotFound = false
            })
            .addCase(apiGetUsers.fulfilled, (state, action) => {
                switch(action.payload.status) {
                    case "no_users":
                        state.users.usersNotFound = true
                        break 
                    case "success":
                        state.users.users = action.payload.data.users 
                        state.users.count = action.payload.data.count
                        break
                }
                state.users.connection.isUsersFetch = false
            })
            .addCase(apiAddUser.pending, (state, _) => {
                state.users.connection.isUserAddingFetch = true
            })
            .addCase(apiAddUser.fulfilled, (state, _) => { 
                state.users.connection.isUserAddingFetch = false
            })
            .addCase(apiAddUser.rejected, (state, _) => { 
                state.users.connection.isUserAddingFetch = false
            }) 
            .addCase(apiDeleteUser.pending, (state, _) => {
                state.users.connection.isUserDeletingFetch = true
            })
            .addCase(apiDeleteUser.fulfilled, (state, _) => { 
                state.users.connection.isUserDeletingFetch = false
            })
            .addCase(apiDeleteUser.rejected, (state, _) => { 
                state.users.connection.isUserDeletingFetch = false
            }) 
            .addCase(apiDeleteAuthor.pending, (state, _) => {
                state.users.connection.isAuthorDeletingFetch = true
            })
            .addCase(apiDeleteAuthor.fulfilled, (state, _) => { 
                state.users.connection.isAuthorDeletingFetch = false
            })
            .addCase(apiDeleteAuthor.rejected, (state, _) => { 
                state.users.connection.isAuthorDeletingFetch = false
            }) 
            .addCase(apiGetUploads.pending, (state, _) => { 
                state.uploads.connection.isUploadsFetch = true
                state.uploads.uploadsNotFound = false
            })
            .addCase(apiGetUploads.fulfilled, (state, action) => {
                switch(action.payload.status) {
                    case "no_uploads":
                        state.uploads.uploadsNotFound = true
                        break 
                    case "success":
                        state.uploads.uploads = action.payload.data.uploads 
                        state.uploads.count = action.payload.data.count
                        break
                } 
                state.uploads.connection.isUploadsFetch = false
            })
            .addCase(apiRejectUpload.pending, (state, _) => {
                state.uploads.connection.isUploadRejectingFetch = true
            })
            .addCase(apiRejectUpload.fulfilled, (state, _) => { 
                state.uploads.connection.isUploadRejectingFetch = false
            })
            .addCase(apiRejectUpload.rejected, (state, _) => { 
                state.uploads.connection.isUploadRejectingFetch = false
            }) 
            .addCase(apiGetUploadInfo.pending, (state, _) => { 
                state.uploads.connection.isUploadInfoFetch = true
            })
            .addCase(apiGetUploadInfo.fulfilled, (state, action) => {
                if(action.payload.status == "success") {
                    Object.assign( state.uploads.info, action.payload.data);
                }
                state.uploads.connection.isUploadInfoFetch = false
            })
            .addCase(apiAddUpload.pending, (state, _) => {
                state.uploads.connection.isConfirmingFetch = true
            })
            .addCase(apiAddUpload.fulfilled, (state, _) => { 
                state.uploads.connection.isConfirmingFetch = false
            })
            .addCase(apiAddUpload.rejected, (state, _) => { 
                state.uploads.connection.isConfirmingFetch = false
            }) 
            .addCase(apiGetProducts.pending, (state, _) => { 
                state.products.connection.isProductsFetch = true
                state.products.productsNotFound = false
            })
            .addCase(apiGetProducts.fulfilled, (state, action) => {
                switch(action.payload.status) {
                    case "no_products":
                        state.products.productsNotFound = true
                        break 
                    case "success":
                        state.products.products = action.payload.data.products 
                        state.products.count = action.payload.data.count
                        break
                } 
                state.products.connection.isProductsFetch = false
            })
            .addCase(apiDeleteProduct.pending, (state, _) => {
                state.products.connection.isDeletingFetch = true
            })
            .addCase(apiDeleteProduct.fulfilled, (state, _) => { 
                state.products.connection.isDeletingFetch = false
            })
            .addCase(apiDeleteProduct.rejected, (state, _) => { 
                state.products.connection.isDeletingFetch = false
            }) 
            .addCase(apiGetEditInfo.pending, (state, _) => { 
                state.products.edit.connection.isEditFetch = true
            })
            .addCase(apiGetEditInfo.fulfilled, (state, action) => {
                if(action.payload.status == "success") {
                    Object.assign( state.products.edit.info, action.payload.data);
                }
                state.products.edit.connection.isEditFetch = false
            })
            .addCase(apiProductEdit.pending, (state, _) => {
                state.products.edit.connection.isEditingFetch = true
            })
            .addCase(apiProductEdit.fulfilled, (state, _) => { 
                state.products.edit.connection.isEditingFetch = false
            })
            .addCase(apiProductEdit.rejected, (state, _) => { 
                state.products.edit.connection.isEditingFetch = false
            }) 
            .addCase(apiAddProduct.pending, (state, _) => {
                state.products.create.connection.isCreatingFetch = true
            })
            .addCase(apiAddProduct.fulfilled, (state, _) => { 
                state.products.create.connection.isCreatingFetch = false
            })
            .addCase(apiAddProduct.rejected, (state, _) => { 
                state.products.create.connection.isCreatingFetch = false
            }) 
            .addCase(apiGeCategories.pending, (state, _) => { 
                state.products.categories.connection.isCategoriesFetch = true
                state.products.categories.categoriesNotFound = false
            })
            .addCase(apiGeCategories.fulfilled, (state, action) => {
                switch(action.payload.status) {
                    case "no_categories":
                        state.products.categories.categoriesNotFound = true
                        break 
                    case "success":
                        state.products.categories.categories = action.payload.data.categories
                    state.products.categories.count = action.payload.data.count 
                        break
                } 
                state.products.categories.connection.isCategoriesFetch = false
            })
            .addCase(apiDeleteCategorie.pending, (state, _) => {
                state.products.categories.connection.isDeltetingFetch = true
            })
            .addCase(apiDeleteCategorie.fulfilled, (state, _) => { 
                state.products.categories.connection.isDeltetingFetch = false 
            })
            .addCase(apiDeleteCategorie.rejected, (state, _) => { 
                state.products.categories.connection.isDeltetingFetch = false
            }) 
            .addCase(apiAddCategorie.pending, (state, _) => {
                state.products.categories.connection.isAddingFetch = true
            })
            .addCase(apiAddCategorie.fulfilled, (state, _) => { 
                state.products.categories.connection.isAddingFetch = false 
            })
            .addCase(apiAddCategorie.rejected, (state, _) => { 
                state.products.categories.connection.isAddingFetch = false
            }) 
            .addCase(apiGetSupport.pending, (state, _) => { 
                state.support.connection.isSupportFetch = true
                state.support.messagesNotFound = false
            })
            .addCase(apiGetSupport.fulfilled, (state, action) => {
                switch(action.payload.status) {
                    case "no_messages":
                        state.support.messagesNotFound = true
                        break 
                    case "success":
                        state.support.messages = action.payload.data.messages
                        state.support.count = action.payload.data.count 
                        break
                }  
                state.support.connection.isSupportFetch = false
            })
            .addCase(apiDeleteSupport.pending, (state, _) => {
                state.support.connection.isDeletingFetch = true
            })
            .addCase(apiDeleteSupport.fulfilled, (state, _) => { 
                state.support.connection.isDeletingFetch = false 
            })
            .addCase(apiDeleteSupport.rejected, (state, _) => { 
                state.support.connection.isDeletingFetch = false
            }) 
    } 
})

export const apiLogin = createAsyncThunk(
    "admin/apiLogin",
    async (data, { dispatch, rejectWithValue }) => {
        try {
            const res = await adminApi.Login(data)

            return res.data
        } catch (err) {
            handleError(dispatch, err)
            return rejectWithValue(err.message)
        }
    }
)

export const apiIsAdmin = createAsyncThunk(
    "admin/apiIsAdmin",
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const res = await adminApi.IsAdmin()

            return res.data
        } catch (err) {
            handleError(dispatch, err)
            return rejectWithValue(err.message)
        }
    }
)

export const apiGetStatistics = createAsyncThunk(
    "admin/apiGetStatistics",
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const res = await adminApi.GetStatistics()

            return res.data
        } catch (err) {
            handleError(dispatch, err)
            return rejectWithValue(err.message)
        }
    }
)

export const apiGetUsers = createAsyncThunk(
    "admin/apiGetUsers",
    async (data, { dispatch, rejectWithValue }) => {
        try {
            const res = await adminApi.GetUsers(JSON.stringify(data)) 

            return res.data
        } catch (err) {
            handleError(dispatch, err)
            return rejectWithValue(err.message)
        }
    }
)

export const apiAddUser = createAsyncThunk(
    "admin/apiAddUser",
    async (data, { dispatch, rejectWithValue }) => {
        try {
            const res = await adminApi.AddUser(JSON.stringify(data)) 

            return res.data
        } catch (err) {
            handleError(dispatch, err)
            return rejectWithValue(err.message)
        }
    }
)

export const apiDeleteUser = createAsyncThunk(
    "admin/apiDeleteUser",
    async (data, { dispatch, rejectWithValue }) => {
        try {
            const res = await adminApi.DeleteUser(JSON.stringify(data)) 

            if(res.data.status == "success") {
                dispatch(toast("success", "User deleted"))  
            }

            return res.data
        } catch (err) {
            handleError(dispatch, err)
            return rejectWithValue(err.message)
        }
    }
)

export const apiDeleteAuthor = createAsyncThunk(
    "admin/apiDeleteAuthor",
    async (data, { dispatch, rejectWithValue }) => {
        try { 
            const res = await adminApi.DeleteAuthor(JSON.stringify(data))

            if(res.data.status == "success") {
                dispatch(toast("success", "Author account deleted"))
            } else if(res.data.status == "author_not_exists") {
                dispatch(toast("warning", "Author does not exist"))
            } 

            return res.data
        } catch (err) {
            handleError(dispatch, err)
            return rejectWithValue(err.message)
        }
    }
)


export const apiGetUploads = createAsyncThunk(
    "admin/apiGetUploads",
    async (data, { dispatch, rejectWithValue }) => {
        try {
            const res = await adminApi.GetUploads(JSON.stringify(data)) 

            return res.data
        } catch (err) {
            handleError(dispatch, err)
            return rejectWithValue(err.message)
        }
    }
)

export const apiRejectUpload = createAsyncThunk(
    "admin/apiRejectUpload",
    async (data, { dispatch, rejectWithValue }) => { 
        try {
            const res = await adminApi.RejectUpload(JSON.stringify(data)) 

            if(res.data.status == "success") {
            dispatch(toast("success", "Upload rejected"))  
            }
 
            return res.data
        } catch (err) {
            handleError(dispatch, err)
            return rejectWithValue(err.message)
        }
    }
)

export const apiGetUploadInfo = createAsyncThunk(
    "admin/apiGetUploadInfo",
    async (data, { dispatch, rejectWithValue }) => {
        try {
            const res = await adminApi.GetUploadInfo(JSON.stringify(data)) 

            await dispatch(apiGetCategories())

            return res.data
        } catch (err) { 
            handleError(dispatch, err)
            return rejectWithValue(err.message)
        }
    }
)

export const apiAddUpload = createAsyncThunk(
    "admin/apiAddUpload",
    async (data, { dispatch, rejectWithValue }) => {
        try {
            const res = await adminApi.AddUpload(data) 

            return res.data
        } catch (err) {
            handleError(dispatch, err)
            return rejectWithValue(err.message)
        }
    }
)

export const apiGetProducts = createAsyncThunk(
    "admin/apiGetProducts",
    async (data, { dispatch, rejectWithValue }) => {
        try {
            const res = await adminApi.GetProducts(JSON.stringify(data)) 

            return res.data
        } catch (err) {
            handleError(dispatch, err)
            return rejectWithValue(err.message)
        }
    }
)

export const apiDeleteProduct = createAsyncThunk(
    "admin/apiDeleteProduct",
    async (data, { dispatch, rejectWithValue }) => {
        try {
            const res = await adminApi.DeleteProduct(JSON.stringify(data)) 

            if(res.data.status == "success") {
                dispatch(toast("success", "Product deleted"))  
            } 
 
            return res.data
        } catch (err) {
            handleError(dispatch, err)
            return rejectWithValue(err.message)
        }
    }
)

export const apiGetEditInfo = createAsyncThunk(
    "admin/apiGetEditInfo",
    async (data, { dispatch, rejectWithValue }) => {
        try {
            const res = await adminApi.GetEditInfo(JSON.stringify(data)) 

            await dispatch(apiGetCategories())
 
            return res.data
        } catch (err) {
            handleError(dispatch, err)
            return rejectWithValue(err.message)
        }
    }
)

export const apiProductEdit = createAsyncThunk(
    "admin/apiProductEdit",
    async (data, { dispatch, rejectWithValue }) => {
        try {
            const res = await adminApi.ProductEdit(data) 
 
            return res.data
        } catch (err) {
            handleError(dispatch, err)
            return rejectWithValue(err.message)
        }
    }
)

export const apiAddProduct = createAsyncThunk(
    "admin/apiAddProduct",
    async (data, { dispatch, rejectWithValue }) => {
        try {
            const res = await adminApi.AddProduct(data) 
 
            return res.data
        } catch (err) {
            handleError(dispatch, err)
            return rejectWithValue(err.message)
        }
    }
)

export const apiGetFile = createAsyncThunk(  
    "adminApi/apiGetFile",
    async (data, { dispatch, rejectWithValue }) => {
        try {
            const res = await adminApi.GetFile(JSON.stringify(data))

            const contentType = res.headers['content-type']

            if (contentType && contentType.includes('application/json')) {
                const reader = new FileReader()
                return new Promise((resolve) => {
                    reader.onload = () => {
                    const json = JSON.parse(reader.result);
                        resolve(json)
                    };
                    reader.readAsText(res.data)
                })
            } else {
                const href = URL.createObjectURL(res.data)

                const link = document.createElement('a')
                link.href = href
    
                const contentDispositionHeader = res.headers['content-disposition']
    
                var fileNameWithQuotes = contentDispositionHeader.split('=')[1]
    
                const fileName = fileNameWithQuotes.replace(/"/g, '')
    
                link.setAttribute('download', fileName)
    
                document.body.appendChild(link)
                link.click()
    
                document.body.removeChild(link)
                URL.revokeObjectURL(href)

                return res.data 
            }
        } catch (err) {
            handleError(dispatch, err)
            return rejectWithValue(err.message)
        } 
    } 
)

export const apiGeCategories = createAsyncThunk(
    "admin/apiGeCategories",
    async (data, { dispatch, rejectWithValue }) => {
        try {
            const res = await adminApi.GetCategories(JSON.stringify(data)) 
 
            return res.data
        } catch (err) {
            handleError(dispatch, err)
            return rejectWithValue(err.message)
        }
    }
)

export const apiDeleteCategorie = createAsyncThunk(
    "admin/apiDeleteCategorie",
    async (data, { dispatch, rejectWithValue }) => {
        try {
            const res = await adminApi.DeleteCategorie(JSON.stringify(data)) 

            if(res.data.status == "success") {
                dispatch(toast("success", "Categorie deleted"))    
            } 

            return res.data
        } catch (err) {
            handleError(dispatch, err)
            return rejectWithValue(err.message)
        }
    }
)

export const apiAddCategorie = createAsyncThunk(
    "admin/apiAddCategorie",
    async (data, { dispatch, rejectWithValue }) => {
        try {
            const res = await adminApi.AddCategorie(JSON.stringify(data)) 

            return res.data
        } catch (err) {
            handleError(dispatch, err)
            return rejectWithValue(err.message)
        }
    }
)

export const apiGetSupport = createAsyncThunk(
    "admin/apiGetSupportMessagesCount",
    async (data, { dispatch, rejectWithValue }) => {
        try {
            const res = await adminApi.GetSupport(JSON.stringify(data)) 

            console.log(res.data)
 
            return res.data
        } catch (err) {
            handleError(dispatch, err)
            return rejectWithValue(err.message)
        }
    }
)

export const apiDeleteSupport = createAsyncThunk(
    "admin/apiDeleteSupport",
    async (data, { dispatch, rejectWithValue }) => {
        try {
            const res = await adminApi.DeleteSupport(JSON.stringify(data)) 

            if(res.data.status == "success") {
                dispatch(toast("success", "Message deleted"))     
            } 

            return res.data
        } catch (err) {
            handleError(dispatch, err)
            return rejectWithValue(err.message)
        }
    }
)

export const { setIsAuth } = adminSlice.actions
export default adminSlice.reducer


