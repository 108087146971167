import React, { useContext } from "react"
import css from "./templates.module.css"
import { Link, Outlet } from "react-router-dom"
import Nav from 'react-bootstrap/Nav';
import { Context } from "./templates_container";

const Templates = () => {
 
    const { activeKey } = useContext(Context)

    return (
        <div className={css.templates}>
                <Nav variant="tabs" className={css.nav} activeKey={activeKey}>
                    <Nav.Item className={css.item}>
                        <Nav.Link as={Link} to="/admin/templates?page=1" eventKey="">Templates</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className={css.item}>
                        <Nav.Link as={Link} to="/admin/templates/add" eventKey="add">Create</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className={css.item}>
                        <Nav.Link as={Link} to="/admin/templates/typesandtopics?page=1" eventKey="typesandtopics">Categories</Nav.Link>
                    </Nav.Item>
                </Nav>
                    
                <div className={css.outlet_wrap}>
                    <Outlet className={css.outlet} />
                </div>
        </div>
    )
}
 

export default Templates 