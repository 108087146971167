import React, { createContext, useState, useEffect } from "react"
import TemplatesEdit from "./templates_edit.jsx"
import { useDispatch, useSelector } from "react-redux"
import { apiGetEditInfo, apiProductEdit, apiGetFile } from "../../../../../store/slices/adminSlice.js"
import { useNavigate, useSearchParams } from "react-router-dom"
import useAuthRedirect from "../../../../../middleware/isAdminAuth.jsx"
import { unwrapResult } from "@reduxjs/toolkit"
import { toast } from "../../../../../store/slices/toastsSlice.js"

const Context = createContext()
 
const TemplatesEditContainer = () => {

    const dispatch = useDispatch()

    const { isAuthDispatch } = useAuthRedirect()
 
    const navigate = useNavigate()

    const {
        products: {
            navigation: {
                types, topics
            }
        }
    } = useSelector((state) => state.categories);

    const {
        products: {
            edit: {
                info,
                connection: {
                    isEditingFetch, isEditFetch
                }
            }
        }
    } = useSelector((state) => state.admin);

    const { 
        form: {
            max_price, min_price, max_discount, max_description, max_file_bytes, max_file_megabytes, file_extension, max_product_name
        }
    } = useSelector((state) => state.constants)

    const [form, setForm] = useState({})
    const [errors, setErrors] = useState({}) 
    const [initData, setInitData] = useState({})

    const [modalShow, setModalShow] = useState({ show: false });
    
    const [searchParams] = useSearchParams()

    let id_params = Number(searchParams.get("id"))

    const dispatchApiGetEditInfo = () => {
        return isAuthDispatch(apiGetEditInfo, {id: id_params}) 
    } 

    const dispatchApiProductEdit = (form) => {
        return isAuthDispatch(apiProductEdit, form)
    }

    const dispatchApiGetFile = (file, id) => {
        return isAuthDispatch(apiGetFile, { file, id })
    }

    const pageLoad = async() => {
        const actionRes = await dispatchApiGetEditInfo()
        const promiseRes = await unwrapResult(actionRes)

        const info = promiseRes.data

        const file = new File([''], info.file, {
            type: 'text/plain',
            lastModified: new Date(),
        });

        const img = new File([''], info.img, {
            type: 'image/jpeg',
            lastModified: new Date(),
        });

        const img_preview = new File([''], info.img_preview, {
            type: 'image/jpeg',
            lastModified: new Date(), 
        });

        const initData = {
            name: info.name,
            price: info.price,
            discount: info.discount,
            file: file,
            version: info.version,
            type_id: info.type_id,
            topic_id: info.topic_id,
            description: info.description,
            img: img,
            img_preview: img_preview,
            user_id: info.user_id
        }

        setInitData(initData);

        setForm(initData);

        [
            { id: 'file', name: info.file, type: file },
            { id: 'img', name: info.img, type: img  },
            { id: 'img_preview', name: info.img_preview, type: img_preview }
        ].forEach((el) => {
            const input = document.getElementById(el.id); 

            const dataTransfer = new DataTransfer();
            dataTransfer.items.add(el.type);
            input.files = dataTransfer.files;

            if (input.webkitEntries.length) {
                input.dataset.file = `${dataTransfer.files[0].name}`;
            }
        })

    }

    const setField = (field, value) => {
        const normalizedValue = ["price", "discount", "user_id"].includes(field) ? value == "" ? "" : Number(value) : value;

        setForm({
            ...form,
            [field]: normalizedValue
        });

        const fieldErrors = validateField(field, value);

        setErrors({
            ...errors,
            [field]: fieldErrors[field]
        });
    }

    const validateField = (field, value) => {
        const newError = {};

        switch (field) {  
            case 'name':
                if (value === undefined || value === '') newError.name = 'Please enter name'
                else if (value && value.length > max_product_name) newError.name = `The name must be less than ${max_product_name} characters.`
                break;
            case 'price':
                if (value === undefined || value === '') newError.price = 'Please enter price'
                else if (value && value > max_price) newError.price = `The price must be less than ${max_price} dollars.`
                else if (value && value < min_price) newError.price = `The price must be more than ${min_price} dollars.`
                else if (value && value <= 0) newError.price = 'The price cannot be equal to zero or less than it.'
                break;
            case 'discount':
                if (value === undefined || value === '') newError.discount = 'Please select discount'
                else if (value && value > max_discount) newError.discount = `Discount must be less than ${max_discount}%.`
                else if (value && form.price - (form.price * value / 100) < min_price) newError.discount = `The price with discount must be more than ${min_price} dollars.`
                else if (value < 0) newError.discount = 'Discount cannot be less than zero.'
                break;
            case 'file':
                if (value === undefined || value === '') newError.file = 'Please select file'
                else if (value) {
                    const even = (el) => value.name.split('.').pop() == el; 
                    if(!file_extension.some(even)) newError.file = 'Only certain archives accepted(.zip, .rar, .7z)'
                }
                else if (value && value.size > max_file_bytes) newError.file = `The file size should not be more than ${max_file_megabytes} megabytes`
                break;
            case 'img':
                if (value === undefined || value === '') newError.img = 'Please select image' 
                else if (!value.type.startsWith("image/")) newError.img = 'Only images accepted(.jpeg, .png, etc)'
                break;
            case 'img_preview':
                if (value === undefined || value === '') newError.img_preview = 'Please select image preview'
                else if (!value.type.startsWith("image/")) newError.img_preview = 'Only images accepted(.jpeg, .png, etc)'
                break;
            case 'description':
                if (value && value.length > max_description) newError.description = `Description must be less than ${max_description} characters.`
                break;
            case 'user_id':
                if (value === undefined || value === '') newError.user_id = 'Please select user id'
                else if (value && value < 0) newError.user_id = 'User id cannot be less than zero.'
                break;
        }

        return newError;
    }

    const validateForm = () => {
        const newErrors = {};

        const fields = ['name', 'price', 'discount', 'file', 'img', 'img_preview', 'description', 'user_id'];

        fields.forEach(field => {
            const fieldErrors = validateField(field, form[field]);
            Object.assign(newErrors, fieldErrors);
        });

        return newErrors;
    }

    function additionalChecks() {
        return form.user_id != initData.user_id
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const formErrors = validateForm()

        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors)
            dispatch(toast("warning", "Invalid form")) 
        } else { 
            console.log("form:", JSON.stringify(form))
            console.log("init:", JSON.stringify(initData))
            if(JSON.stringify(form) == JSON.stringify(initData) && form.file.size == 0 && form.img.size == 0 && form.img_preview.size == 0) {
                dispatch(toast("secondary", "Form did not change"))
            } else {
                if(additionalChecks()) {
                    setModalShow({ show: true })
                } else {
                    submitForm()
                }
            }
        }
    }

    const submitForm = async () => { 
        setModalShow({ show: false })
        const formData = new FormData(); 
        formData.append('id', id_params)
            for (let key in form) {
                console.log(form[key])
                switch (key) {
                    case 'file':
                    case 'img':
                    case 'img_preview':
                        form[key].size != 0 && formData.append(key, form[key])
                        break
                    default:
                        form[key] != initData[key] && formData.append(key, form[key])
                        break 
                }
            }
            const actionRes = await dispatchApiProductEdit(formData)
            const promiseRes = unwrapResult(actionRes)

            const setError = (err) => {
                setErrors(err)
                dispatch(toast("warning", "Invalid form"))
            }

            switch (promiseRes.status) {
                case "user_id_do_not_exists":
                    setError({user_id: "User do not exists"})
                    break
                case "success":
                    navigate('/admin/templates?page=1')
                    dispatch(toast("success", "Product changed")) 
                    break 
            }
    }
    
    useEffect(() => {
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
        pageLoad()
    }, []);

    const contextData = { handleSubmit, form, errors, setField, types, topics, info, isEditingFetch, isEditFetch, 
        submitForm, modalShow, setModalShow, dispatchApiGetFile
     }
 
    return (
        <Context.Provider value={contextData}>
            <TemplatesEdit />
        </Context.Provider>
    )
}

export { Context }
export default TemplatesEditContainer