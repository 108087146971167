import React, { createContext, useEffect, useState } from "react"
import AdminLogin from "./admin_login.jsx"
import { apiLogin, apiIsAdmin } from "../../../store/slices/adminSlice.js"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { toast } from "../../../store/slices/toastsSlice.js"
import { unwrapResult } from "@reduxjs/toolkit"

const Context = createContext()

const AdminLoginContainer = () => {

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const {
       connection: { isLoginingFetch }
    } = useSelector((state) => state.admin);

    const { 
        form: {
            max_user_name, min_user_name, max_password, min_password,
        }
    } = useSelector((state) => state.constants)

    const [form, setForm] = useState({})
    const [errors, setErrors] = useState({}) 

    const [_, setShow] = useState(true);

    const setField = (field, value) => {
        setForm({
            ...form,
            [field]: value
        });

        const fieldErrors = validateField(field, value);
        
        setErrors({
            ...errors,
            [field]: fieldErrors[field]
        });
    }

    const validateField = (field, value) => {
        const newError = {};
     
        switch (field) {
            case 'name':
                if (value == undefined || value == '') newError.name = 'Please enter name'
                else if (value && value.length < min_user_name) newError.name = `Name must be more than ${min_user_name} characters.`
                else if (value && value.length > max_user_name) newError.name = `Name must be less than ${max_user_name} characters.`
                break;
            case 'password':
                if (value == undefined  || value == '') newError.password = 'Please enter password' 
                else if (value && value.length < min_password) newError.password = `Old password must be more than ${min_password} characters.`
                else if (value && value.length > max_password) newError.password = `Old password must be less than ${max_password} characters.`
                break;
        }
    
        return newError;
    }
    
    const validateForm = () => {
        const newErrors = {};
    
        const fields = ['name', 'password'];
    
        fields.forEach(field => {
            const fieldErrors = validateField(field, form[field]);
            Object.assign(newErrors, fieldErrors);
        });
    
        return newErrors;
    }

    const dispatchApiLogin = () => { 
        return dispatch(apiLogin(form))
    }

    const dispatchApiIsAdmin = () => { 
        dispatch(apiIsAdmin())
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        const formErrors = validateForm()

        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors)
            dispatch(toast("warning", "Invalid form"))
        } else { 
            const actionRes = await dispatchApiLogin() 
            const promiseRes = unwrapResult(actionRes)

            const setError = (err) => {
                setErrors(err)
                dispatch(toast("warning", "Invalid form"))
            }

            switch (promiseRes.status) {
                case "name_not_found":
                    setError({name: "Name not found"})
                    break
                case "incorrect_password":
                    setError({password: "Incorrect password"})
                    break
                case "success":
                    dispatchApiIsAdmin()
                    navigate('/admin')
                    break
            }
        }
    }

    useEffect(() => {
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
    }, [])

    const contextData = { handleSubmit, form, errors, setField, isLoginingFetch, setShow }

    return (
        <Context.Provider value={contextData}>
            <AdminLogin />
        </Context.Provider>
    )
}

export { Context }
export default AdminLoginContainer