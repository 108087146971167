import React, { useContext } from "react";
import { Context } from "./basket_container.jsx";
import css from "./basket.module.css"
import BasketProductsMap from "./basket_products_map/basket_products_map.jsx"
import Button from 'react-bootstrap/Button';
import { CurrencyDollar } from 'react-bootstrap-icons';
import Badge from 'react-bootstrap/Badge';
import { Link } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import Placeholder from 'react-bootstrap/Placeholder';

const Basket = () => { 
    
    const { products, totalPrice, isBasketEmpty, isBasketFetch, isRemovingFetch, isPaymentFetch, dispatchApiRemoveAllProductsFromBasket,
        dispatchApiPayForProduct } = useContext(Context)

    return ( 
        <div className={css.basket}>
            <div className={css.products}> 
                <div className={css.control}>
                    <Button as={Link} to="/categories?page=1" className="btn-prime">Continue Shopping</Button>
                    <Button onClick={() => dispatchApiRemoveAllProductsFromBasket()} disabled={isBasketEmpty || isRemovingFetch || isBasketFetch} variant="secondary" className={css.clear_btn}>Clear Cart</Button>
                </div>
                {!isBasketEmpty ?
                    <>
                        <BasketProductsMap
                        />
                        <div className={css.counter}>Items in the cart: {!isRemovingFetch && !isBasketFetch ? <Badge  className={css.counter_badge}>{products?.length}</Badge> : <Placeholder className={css.counter_badge} as={Badge}>0</Placeholder>}</div>
                    </>
                    : 
                    <div className={css.empty_wrap}>
                        <h4>Cart is empty.</h4> 
                    </div>
                }
            </div>
            <div className={css.checkout_wrap}>
                <div className={css.checkout}>
                    <h4>Your Cart Total:</h4> 
                    {isRemovingFetch || isBasketFetch ?
                        <Placeholder as="h4" className={css.total_price} animation="glow">
                            <Placeholder className={css.total_price_placeholder}></Placeholder>
                        </Placeholder>  
                        :
                        <h4 className={css.total_price}>US<CurrencyDollar size={"1.5rem"} />{totalPrice.toString().replace(".", ",")}</h4>
                    }
                    <Button onClick={() => dispatchApiPayForProduct()} variant="primary" size="lg" disabled={isBasketEmpty || isRemovingFetch || isBasketFetch || isPaymentFetch} className={css.checkout_btn}>Checkout{isPaymentFetch && <Spinner className={css.spinner} size="sm" />}</Button>
                    <div><i>Price displayed excludes any applicable taxes and a handling fee.</i></div>
                </div>
            </div>
        </div>

    )
}

export default Basket 