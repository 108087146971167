import React, { useContext } from "react"
import css from "./register.module.css"
import GoogleImg from "../../../../images/google.png"
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import Image from 'react-bootstrap/Image';
import { Link } from 'react-router-dom'
import { Context } from "./register_container";
import Spinner from "react-bootstrap/Spinner";

const Register = () => {

  const { handleSubmit, form, errors, setField, isRegisteringFetch, googleRegister } = useContext(Context)

  return (
    <Form className={css.form} noValidate onSubmit={handleSubmit}>
      <h3 className={css.title}>Create your account</h3> 
      <Form.Floating controlId="name" className={css.input}>
        <Form.Control
          id="name" 
          placeholder="Name"
          value={form.name}
          onChange={e => setField('name', e.target.value)}
          isValid={form.name && form.name != '' && !errors.name}
          isInvalid={!!errors.name}
        />
        <label htmlFor="name">Name - optional</label>
        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
      </Form.Floating>
      <Form.Floating controlId="email" className={css.input}>
        <Form.Control
          id="email"
          type="email"
          placeholder="Email"
          value={form.email}
          onChange={e => setField('email', e.target.value)}
          isValid={form.email && form.email != '' && !errors.email}
          isInvalid={!!errors.email}
        />
        <label htmlFor="email">Email</label>
        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
      </Form.Floating>
      <Form.Floating controlId="password" className={css.input}>
        <Form.Control
          id="password"
          type="password"
          placeholder="Password"
          onChange={e => setField('password', e.target.value)}
          value={form.password}
          isValid={form.password && form.password != '' && !errors.password}
          isInvalid={!!errors.password}
        />
        <label htmlFor="password">Password</label>
        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
      </Form.Floating>
      <Form.Group>
        <Form.Check
          label={<div>By registering I agree to the <a className={`link ${css.link}`} target="_blank" href="/privacy-policy">Privacy Policy</a> and <a className={`link ${css.link}`} target="_blank" href="/terms-and-conditions">Terms & Conditions</a></div>}
          feedback={errors.agreement}
          feedbackType="invalid"
          onChange={e => setField('agreement', e.target.checked)}
          isValid={form.agreement && form.agreement == true && !errors.agreement}
          isInvalid={!!errors.agreement}
        />
      </Form.Group>
      <div className={css.btns_wrap}>
        <Button disabled={isRegisteringFetch} type="submit" variant="primary" className={css.btn_submit}>Register{isRegisteringFetch && <Spinner size="sm" />}</Button>
        <Button disabled={isRegisteringFetch} onClick={googleRegister} className={`btn-second ${css.btn_google}`}><Image className={css.google_img} src={GoogleImg}></Image>Continue with Google</Button>
      </div>
      <div>Already have an account?<br /><Nav.Link as={Link} className={`link ${css.link}`} to="/login">Log in</Nav.Link></div>
    </Form>
    )
}

export default Register