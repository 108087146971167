import React, { useContext, useState } from "react"
import css from "./templates_map.module.css"
import Button from 'react-bootstrap/Button';
import { ThreeDotsVertical } from "react-bootstrap-icons"
import Modal from 'react-bootstrap/Modal';
import { Link } from "react-router-dom";
import { Context } from "../templates_count_container";
import Spinner from "react-bootstrap/Spinner";
import Nav from 'react-bootstrap/Nav';

const TemplatesMap = () => {

    const { products, dispatchApiDeleteProduct, moreModalShow, confirmDeletion, onShowMoreModal,
         onHideMoreModal, setConfirmDeletion, isDeletingFetch } = useContext(Context)

    const MoreModal = () => {
        return (
            <Modal 
                show={moreModalShow.show}
                onHide={() => onHideMoreModal("")}
                size="md"
                className={css.modal}
                centered
                animation={false} 
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Information
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={css.item}>Product <Nav.Link className={`link ${css.item_link}`}>#{moreModalShow.id}</Nav.Link></div>
                    <div className={css.action}>
                        {!confirmDeletion ?
                        <>
                        <div>Delete product</div>
                        <div className={css.action_btn_wrap}>
                        <Button disabled={isDeletingFetch} onClick={() => setConfirmDeletion(true)} className={css.del_btn} variant="danger">Delete{isDeletingFetch && <Spinner size="sm"/>}</Button>
                        </div>
                        </>
                        :
                        <>
                            <div>Confim deletion?</div>
                            <div className={css.action_btn_wrap}>
                            <Button onClick={() => dispatchApiDeleteProduct(moreModalShow.id)} className={css.del_btn} variant="danger">Confirm</Button>
                            <Button onClick={() => setConfirmDeletion(false)} className={`btn-second ${css.cancel_btn}`}>Cancel</Button>
                            </div>  
                        </>
                        } 
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-prime" onClick={() => onHideMoreModal("")}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    return (
        <>
            {products.map(p =>
                <tr key={p.id}>
                    <td className="align-middle">{p.id}</td>
                    <td className="align-middle">{p.name}</td>
                    <td className="align-middle"><div className={css.inner}>{p.price.toString().replace(".", ",")}<div className={css.btn_wrap}><Button as={Link} to={`/admin/templates/edit?id=${p.id}`} className={css.edit_btn} variant="outline-secondary">Edit</Button><Button onClick={() => onShowMoreModal(p.id)} className={`btn-second ${css.more_btn}`}><ThreeDotsVertical size={"1.25rem"} /></Button></div></div></td>
                </tr>
            )} 
 
            <MoreModal/>
        </>
    )
}

export default TemplatesMap