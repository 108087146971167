import React, { createContext, useEffect } from "react";
import Basket from "./basket.jsx"
import { useSelector } from "react-redux"
import { apiGetProductsToBasket, apiRemoveProductsFromBasket, apiRemoveAllProductsFromBasket, apiPayForProduct } from "../../../store/slices/basketSlice"
import useAuthRedirect from "../../../middleware/isAuth.jsx"

const Context = createContext();

const BasketContainer = () => {

    const { isAuthDispatch } = useAuthRedirect()

    const {
        products, totalPrice, isBasketEmpty, 
        connection: { isBasketFetch, isRemovingFetch, isPaymentFetch },
        placeholders: { productsPlaceholders }
    } = useSelector((state) => state.basket);

    const dispatchApiGetProductsToBasket = () => {
        isAuthDispatch(apiGetProductsToBasket) 
    }

    const dispatchApiRemoveProductsFromBasket = (id) => {
        isAuthDispatch(apiRemoveProductsFromBasket, { id })  
    }

    const dispatchApiRemoveAllProductsFromBasket = () => {
        isAuthDispatch(apiRemoveAllProductsFromBasket)  
    } 

    const dispatchApiPayForProduct = () => {
        isAuthDispatch(apiPayForProduct) 
    }

    useEffect(() => {
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
        dispatchApiGetProductsToBasket() 
    }, []) 

    const contextData = { products, totalPrice, isBasketEmpty, isBasketFetch, isRemovingFetch, isPaymentFetch, productsPlaceholders,
        dispatchApiRemoveProductsFromBasket, dispatchApiRemoveAllProductsFromBasket, dispatchApiPayForProduct }

    return (
        <Context.Provider value={contextData}>
            <Basket/>
        </Context.Provider>
    ) 
}
 
export default BasketContainer
export { Context }