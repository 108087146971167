import React, { createContext, useEffect, useState } from "react";
import Authors from "./authors.jsx"
import { apiGetUploads, apiRejectUpload, apiGetFile } from "../../../../store/slices/adminSlice.js"
import { useSelector } from "react-redux";
import useAuthRedirect from "../../../../middleware/isAdminAuth.jsx";
import { useSearchParams } from "react-router-dom";

const Context = createContext()

const AuthorsContainer = () => {

    const { isAuthDispatch } = useAuthRedirect()

    const { 
        uploads: {
            uploads, limit, count, uploadsNotFound, 
            connection: {
                 isUploadsFetch,
                 isUploadRejectingFetch
             }
        }
     } = useSelector((state) => state.admin)

     const [moreModalShow, setMoreModalShow] = useState({ id: '', user_id: '', show: false });
     const [confirmRejection, setConfirmRejection] = useState(false); 

     const onShowMoreModal = (id, user_id) => {
         setMoreModalShow({ id, user_id, show: true })
     }
 
     const onHideMoreModal = (id, user_id) => {
         setConfirmRejection(false) 
         setMoreModalShow({ id, user_id, show: false })
     }
 
     const [searchParams, setSearchParams] = useSearchParams()

     let queryPage = searchParams.get("page")
 
     let pagesCount = Math.ceil(count / limit)
 
     const offset = (+queryPage - 1) * limit
 
     let pages = [];
     for (let i = 1; i <= pagesCount; i++) {
         pages.push(i);
     } 
 
     function pageFirst() {
         setSearchParams({ page: 1 })
         setTimeout(() => {window.scrollTo(0, 0)}, 0)
     }
 
     function pagePrev() {
         setSearchParams({ page: +queryPage - 1 })
         setTimeout(() => {window.scrollTo(0, 0)}, 0)
     }
 
     function pageSelect(p) {
         setSearchParams({ page: queryPage = p })
         setTimeout(() => {window.scrollTo(0, 0)}, 0)
     }
 
     function pageNext() {
         setSearchParams({ page: +queryPage + 1 })
         setTimeout(() => {window.scrollTo(0, 0)}, 0)
     }
 
     function pageLast() {
         setSearchParams({ page: pagesCount })
         setTimeout(() => {window.scrollTo(0, 0)}, 0)
     }
 
     const dispatchApiGetUploads = () => {
         return isAuthDispatch(apiGetUploads, {offset, limit})
     }

     const dispatchApiRejectUpload = async(id) => {
        setConfirmRejection(false)
        await isAuthDispatch(apiRejectUpload, { id })
        setMoreModalShow({ id: '', user_id: '', show: false })
        dispatchApiGetUploads()
    }

    const dispatchApiGetFile = (file, id) => {
        return isAuthDispatch(apiGetFile, { file, id })
    }
 
     useEffect(() => {
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
        dispatchApiGetUploads()
     }, [queryPage]) 

     const contextData = { uploads, uploadsNotFound, isUploadsFetch, pages, queryPage, pageFirst, pagePrev, pageSelect, pageNext, pageLast, 
        isUploadRejectingFetch, moreModalShow, confirmRejection, onShowMoreModal, onHideMoreModal, dispatchApiRejectUpload,
        setConfirmRejection, dispatchApiGetFile
     }
 
    return (
        <Context.Provider value={contextData}>
            <Authors/> 
        </Context.Provider>
    )
}

export { Context }
export default AuthorsContainer 