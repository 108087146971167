import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { basketApi } from "../../api/api.js"
import { toast } from "./toastsSlice.js"
import handleError from "./error/error.js"

const initialState = {
    products: [],
    totalPrice: 0,
    isBasketEmpty: true,
    addingId: 0, 
    connection: {
        isBasketFetch: true,
        isRemovingFetch: false,
        isAddingFetch: false,
        isPaymentFetch: false
    },
    placeholders: {
        productsPlaceholders: 3,
    }
}

export const basketSlice = createSlice({
    name: "basket",
    initialState,
    reducers: {
        setAddingId: (state, action) => {
            state.addingId = action.payload
        },
    },
    extraReducers(builder) {
        builder
            .addCase(apiGetProductsToBasket.pending, (state, _) => {
                state.connection.isBasketFetch = true
                state.isBasketEmpty = false
            })
            .addCase(apiGetProductsToBasket.fulfilled, (state, action) => {
                switch (action.payload.status) {
                    case "no_products":
                        state.isBasketEmpty = true
                        state.totalPrice = 0
                        break
                    case "success":
                        state.products = action.payload.data.products
                        state.totalPrice = action.payload.data.totalPrice
                        break
                }
                state.connection.isBasketFetch = false
            })
            .addCase(apiAddProductToBasket.pending, (state, _) => {
                state.connection.isAddingFetch = true
            })
            .addCase(apiAddProductToBasket.fulfilled, (state, _) => {
                state.connection.isAddingFetch = false
            })
            .addCase(apiAddProductToBasket.rejected, (state, _) => {
                state.connection.isAddingFetch = false
            })
            .addCase(apiRemoveProductsFromBasket.pending, (state, _) => {
                state.connection.isRemovingFetch = true
            })
            .addCase(apiRemoveProductsFromBasket.fulfilled, (state, _) => {
                state.connection.isRemovingFetch = false
            })
            .addCase(apiRemoveProductsFromBasket.rejected, (state, _) => {
                state.connection.isRemovingFetch = false
            })
            .addCase(apiRemoveAllProductsFromBasket.pending, (state, _) => {
                state.connection.isRemovingFetch = true
            })
            .addCase(apiRemoveAllProductsFromBasket.fulfilled, (state, _) => {
                state.connection.isRemovingFetch = false
            })
            .addCase(apiRemoveAllProductsFromBasket.rejected, (state, _) => {
                state.connection.isRemovingFetch = false
            })
            .addCase(apiPayForProduct.pending, (state, _) => {
                state.connection.isPaymentFetch = true
            })
            .addCase(apiPayForProduct.fulfilled, (state, _) => {
                state.connection.isPaymentFetch = false
            })
            .addCase(apiPayForProduct.rejected, (state, _) => {
                state.connection.isPaymentFetch = false
            })
    }
})

export const apiGetProductsToBasket = createAsyncThunk(
    "basket/apiGetProductsToBasket",
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const res = await basketApi.GetProductsToBasket()

            return res.data
        } catch (err) {
            handleError(dispatch, err)
            return rejectWithValue(err.message)
        }
    }
)

export const apiAddProductToBasket = createAsyncThunk(
    "basket/apiAddProductToBasket",
    async (data, { dispatch, rejectWithValue }) => {
        try {
            const res = await basketApi.AddProductToBasket(JSON.stringify(data))

            switch (res.data.status) {
                case "already_in_cart":
                    dispatch(toast("warning", "Already in cart"))
                    break
                case "already_purchased":
                    dispatch(toast("warning", "Already purchased"))
                    break
                case "success":
                    dispatch(toast("success", "Added to cart"))
                    break
            }

            return res.data
        } catch (err) {
            handleError(dispatch, err)
            return rejectWithValue(err.message)
        }
    }
)

export const apiRemoveProductsFromBasket = createAsyncThunk(
    "basket/apiRemoveProductsFromBasket",
    async (data, { dispatch, rejectWithValue }) => {
        try {
            const res = await basketApi.RemoveProductsFromBasket(JSON.stringify(data))

            if(res.data.status == "success") {
                await dispatch(apiGetProductsToBasket()) 
            }

            return res.data
        } catch (err) {
            handleError(dispatch, err)
            return rejectWithValue(err.message)
        }
    }
)

export const apiRemoveAllProductsFromBasket = createAsyncThunk(
    "basket/apiRemoveAllProductsFromBasket",
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const res = await basketApi.RemoveAllProductsFromBasket()

            if(res.data.status == "success") {
                await dispatch(apiGetProductsToBasket())
            }

            return res.data
        } catch (err) {
            handleError(dispatch, err)
            return rejectWithValue(err.message)
        }
    }
)

export const apiPayForProduct = createAsyncThunk(
    "basket/apiPayForProduct",
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const res = await basketApi.AddProductToPurchases()

            if (res.data.status == "success") {
                window.location.href = res.data.data
            }

            return res.data
        } catch (err) {
            handleError(dispatch, err)
            return rejectWithValue(err.message)
        }
    }
)

export const { setAddingId } = basketSlice.actions
export default basketSlice.reducer