import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { productsApi } from "../../api/api.js"
import { toast } from "./toastsSlice.js"
import handleError from "./error/error.js"

//test
import axios from "axios"

const initialState = {
    products: { 
        products: [],
        limit: 24,
        count: 0,
        setting: {
            categories: [],
            search: "",
            sort: "The newest",
        },
        navigation: { 
            types: [],
            topics: [],
        }, 
        placeholders: {
            productsPlaceholders: 24,
            typesPlaceholders: 6,
            topicsPlaceholders: 6,
        },
        connection: { 
            isProductsFetch: true,
            isCategoriesFetch: true
        },
        productsNotFound: false
    },
    info: {
        id: 0,
        name: "",
        price: 0,
        price_with_discount: 0,
        discount: 0,
        img_preview: "",
        version: "",
        type: "",
        topic: "", 
        created: 0,
        description: "",
        author: "",
        liked: false, 
        likingId: 0,
        infoNotFound: false,
        connection: {  
            isLikingFetch: false,
            isInfoFetch: true,
        },
    },
}

export const categorieslice = createSlice({
    name: "categories",
    initialState,
    reducers: {
        setSearch: (state, action) => {
            state.products.setting.search = action.payload
        },
        setSort: (state, action) => { 
            state.products.setting.sort = action.payload 
        },
        addCategorie: (state, action) => {
            if (!state.products.setting.categories.some(c => c.name === action.payload.name && c.categorie === action.payload.categorie && c.categorie_id === action.payload.categorie_id)) {
                state.products.setting.categories.push(action.payload);
            }
        },
        deleteCategorie: (state, action) => {
            state.products.setting.categories = state.products.setting.categories.filter((c) => (c.name !== action.payload))
        },
        setLikingId: (state, action) => {
            state.info.likingId = action.payload  
        }
    },
    extraReducers(builder) {
        builder
            .addCase(apiGetProducts.pending, (state, _) => {
                state.products.connection.isProductsFetch = true
                state.products.productsNotFound = false
            })
            .addCase(apiGetProducts.fulfilled, (state, action) => {
                if (action.payload.status == "no_products") {
                    state.products.productsNotFound = true
                } else {
                    state.products.products = action.payload.data.products
                    state.products.count = action.payload.data.count
                }
                state.products.connection.isProductsFetch = false
            })
            .addCase(apiGetCategories.pending, (state, _) => {
                state.products.connection.isCategoriesFetch = true
            })
            .addCase(apiGetCategories.fulfilled, (state, action) => { 
                state.products.navigation.types = action.payload.data.types
                state.products.navigation.topics = action.payload.data.topics
                state.products.connection.isCategoriesFetch = false
            })

            //test
            .addCase(apiGetCategoriesTest.pending, (state, _) => {
                state.products.connection.isCategoriesFetch = true
            })
            .addCase(apiGetCategoriesTest.fulfilled, (state, action) => { 
                state.products.navigation.types = action.payload.data.types
                state.products.navigation.topics = action.payload.data.topics
                state.products.connection.isCategoriesFetch = false
            }) 

            .addCase(apiGetProductInfo.pending, (state, _) => {
                state.info.connection.isInfoFetch = true
                state.info.infoNotFound = false
            })
            .addCase(apiGetProductInfo.fulfilled, (state, action) => { 
                if (action.payload.status == "no_info") {
                    state.info.infoNotFound = true
                } else {
                    if(action.payload.data.liked == "liked") {
                        action.payload.data.liked = true
                         
                    } else {
                        action.payload.data.liked = false
                    }
                    Object.assign(state.info, action.payload.data);
                    console.log(action.payload.data)
                }
                state.info.connection.isInfoFetch = false
            })
            .addCase(apiLikeProduct.pending, (state, _) => {
                state.info.connection.isLikingFetch = true
            }) 
            .addCase(apiLikeProduct.fulfilled, (state, action) => { 
                switch (action.payload.status) {
                    case "liked": 
                        state.info.liked = false 
                        break
                    case "not_liked":
                        state.info.liked = true
                        break
                }
                state.info.connection.isLikingFetch = false
            })
            .addCase(apiLikeProduct.rejected, (state, _) => {
                state.info.connection.isLikingFetch = false
            }) 
    }
})

export const apiGetProducts = createAsyncThunk(
    "categories/apiGetProducts",
    async (data, { dispatch, rejectWithValue }) => {

        console.log(data)
        try {
            const res = await productsApi.GetProducts(JSON.stringify(data)) 

            console.log(res)

            return res.data 
        } catch (err) {
            handleError(dispatch, err)
            return rejectWithValue(err.message)
        } 
    } 
)

export const apiGetCategories = createAsyncThunk(
    "categories/apiGetCategories",
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const res = await productsApi.GetCategories()

            console.log(res)
            
            return res.data 
        } catch (err) {
            handleError(dispatch, err)
            return rejectWithValue(err.message)
        } 
    }
)


//test
export const apiGetCategoriesTest = createAsyncThunk(
    'categories/apiGetCategoriesTest',
    async function (_, { rejectWithValue }) { 
        try {
            const response = await axios.get('http://localhost:8080/products/categories/get');

            if (!response.ok) {
                throw new Error("Can't fetch");
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);
 
export const apiGetProductInfo = createAsyncThunk(
    "categories/apiGetProductInfo",
    async (data, { dispatch, rejectWithValue }) => {
        try {
            const res = await productsApi.GetProductInfo(JSON.stringify(data)) 
            
            return res.data 
        } catch (err) {
            handleError(dispatch, err)
            return rejectWithValue(err.message)
        } 
    }
)

export const apiLikeProduct = createAsyncThunk(
    "categories/apiLikeProduct",
    async (data, { dispatch, rejectWithValue }) => {
        try {
            const res = await productsApi.LikeProduct(JSON.stringify(data))  

            switch (res.data.status) {
                case "liked":
                    dispatch(toast("secondary", "Deleted from favorites"))   
                    break
                case "not_liked":
                    dispatch(toast("success", "Added to favorites")) 
                    break
            }

            return res.data 
        } catch (err) {
            handleError(dispatch, err)
            return rejectWithValue(err.message)
        } 
    }
)

export const { 
    setSearch, setSort, addCategorie, deleteCategorie , setLikingId
} = categorieslice.actions
export default categorieslice.reducer 