import { toast } from "../toastsSlice"

const handleError = (dispatch, err) => {
    if(window.navigator.onLine) {
        switch (err.response.status) {
            case 500:
                dispatch(toast("danger", "Server error"))
                break
            case 429:
                dispatch(toast("warning", "Too many requests"))
                break
            default:
                dispatch(toast("danger", "Unknown error"))
                break
        }
    } else {
        dispatch(toast("secondary", "Internet disconnected"))
    }
}

export default handleError