import React, { useContext } from "react"
import { Context } from "./purchased_container.jsx"
import css from "./purchased.module.css"
import PurchasedMap from "./purchased_map/purchased_map.jsx"
import Badge from 'react-bootstrap/Badge';
import Placeholder from 'react-bootstrap/Placeholder';

const Purchased = () => {

    const { productsNotFound, products, isPurchasesFetch, isPurchaseDeletingFetch } = useContext(Context)

    return (
        !productsNotFound ?
        <>
           <div className={css.info}>
                <div className={css.count}>Items purchased: {!isPurchasesFetch && !isPurchaseDeletingFetch ? <Badge bg="primary">{products?.length}</Badge> : <Placeholder as={Badge}>0</Placeholder>}</div>
            </div> 
            <PurchasedMap />
        </>
        : 
        <div className={css.not_found}>
            <h4>You have no purchased products.</h4>
        </div>
    )
}


export default Purchased