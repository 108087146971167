import React, { useContext } from "react";
import { Context } from "./product_info_container.jsx"
import css from "./product_info.module.css"
import { CartFill, CurrencyDollar, Heart, HeartFill, Share, Upload, FileEarmarkText, Person, ListCheck } from 'react-bootstrap-icons';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Placeholder from 'react-bootstrap/Placeholder';
import Ratio from 'react-bootstrap/Ratio';
import Alert from 'react-bootstrap/Alert';
import ListGroup from 'react-bootstrap/ListGroup';

const ProductInfo = () => {

    const { id, name, price, discount, img_preview, type, topic, description, author, infoNotFound, isInfoFetch,
        liked, dispatchApiLikeProduct, isLikingFetch, likingId, dispatchSetLikingId, dispatchApiAddProductToBasket,
        isAddingFetch, addingId, dispatchSetAddingId, price_with_discount, dispatchToast,
        months, date, year, month, version } = useContext(Context)

    return (
        !infoNotFound ?
            !isInfoFetch ?
                <div className={css.info}>
                    <div className={css.head}>
                        <div>
                            <h4 className={css.name}>{name}</h4>
                            <div className={css.updated}><Upload />Uploaded: {date} {months[month]}, {year}</div>
                        </div>
                        <div className={css.btns_wrap}>
                            <Button disabled={isAddingFetch} onClick={() => { dispatchApiAddProductToBasket(); dispatchSetAddingId() }} className={css.basket_btn}>Add to cart{isAddingFetch ? addingId == id ? <Spinner className={css.basket_spinner} size="sm" animation="border" /> : <CartFill size="1.25rem" className={css.basket_img} /> : <CartFill size="1.25rem" className={css.basket_img} />}</Button>
                            <Button onClick={() => (navigator.clipboard.writeText(window.location.href), dispatchToast("success", "Link copied"))} className={`btn-second ${css.share_btn}`}>Share<Share className={css.share_img} size="1.25rem" /></Button>
                            {!liked
                                ?
                                <Button disabled={isLikingFetch} onClick={() => { dispatchApiLikeProduct(); dispatchSetLikingId() }} className={`btn-second ${css.heart_btn}`}>{isLikingFetch ? likingId == id ? <Spinner size="sm" animation="border" /> : <Heart size="1.25rem" /> : <Heart size="1.25rem" />}</Button>
                                :
                                <Button disabled={isLikingFetch} onClick={() => { dispatchApiLikeProduct(); dispatchSetLikingId() }} className={`btn-second ${css.heart_btn}`}>{isLikingFetch ? likingId == id ? <Spinner size="sm" animation="border" /> : <HeartFill className={css.heart_img} size="1.25rem" /> : <HeartFill className={css.heart_img} size="1.25rem" />}</Button>
                            }
                        </div>
                    </div>
                    <div className={css.content}>
                        <div className={css.visual}>
                            <Image className={css.img} src={`https://storageplatform0.blob.core.windows.net/products/${id}/image_preview/${img_preview}?${new Date().getTime()}`} />
                        </div>
                        <div className={css.data}>
                            <div className={css.foremost}>
                                {discount != 0 ?
                                    <h5 className={css.price_discount_wrap}>
                                        <span className={css.price_crossed}>{price.toString().replace(".", ",")}</span><span className={css.price_new}>{price_with_discount.toString().replace(".", ",")}<CurrencyDollar size={"1.25rem"} /></span>
                                    </h5>
                                    :
                                    <h5 className={css.price}>{price.toString().replace(".", ",")}<CurrencyDollar size="1.25rem" /></h5>
                                }
                                {description && <p className={css.description}>{description}</p>}
                            </div>
                            <ListGroup>
                                <ListGroup.Item className={css.list_item}><div><Person />Author</div><div>{author != "" ?  author : "-"}</div></ListGroup.Item>
                                <ListGroup.Item className={css.list_item}><div>Type</div><div>{type != "" ? type : "-"}</div></ListGroup.Item>
                                <ListGroup.Item className={css.list_item}><div>Topic</div><div>{topic != "" ? topic : "-"}</div></ListGroup.Item>
                                <ListGroup.Item className={css.list_item}><div><ListCheck />Version</div><div>{version != "" ? version : "-"}</div></ListGroup.Item>
                                <ListGroup.Item className={css.list_item}><div><FileEarmarkText />License</div><div>GPL</div></ListGroup.Item>
                                <ListGroup.Item className={css.list_item}><div>#ID</div><div>{id}</div></ListGroup.Item>
                            </ListGroup>
                            <Alert className={css.alert} variant="info">
                                This product is licensed under the GPL (General Public License). Please note that this product is provided as-is and does not include developer support. Updates and support are only available with direct purchase from the original vendor.
                            </Alert>
                        </div>
                    </div>
                </div>
                :
                <div className={css.info}>
                    <div className={css.head}>
                        <div>
                            <Placeholder className={css.name} as="h4" animation="glow">
                                <Placeholder xs={8} />
                            </Placeholder>
                            <Placeholder className={css.updated} as="div" animation="glow">
                                <Placeholder xs={3} />
                            </Placeholder>
                        </div>
                        <div className={css.btns_wrap}>
                            <Placeholder.Button className={css.basket_btn}>Add to cart<CartFill size="1.25rem" className={css.basket_img} /></Placeholder.Button>
                            <Placeholder.Button className={`btn-second ${css.share_btn}`}>Share<Share className={css.share_img} size="1.25rem" /></Placeholder.Button>
                            <Placeholder.Button className={`btn-second ${css.heart_btn}`}><Heart size="1.25rem" /></Placeholder.Button>
                        </div>
                    </div>
                    <div className={css.content}>
                        <div className={css.visual}>
                            <Placeholder animation="glow">
                                <Ratio>
                                    <Placeholder className={css.ratio_plaseholder} />
                                </Ratio>
                            </Placeholder>
                        </div>
                        <div className={css.data}>
                            <div className={css.foremost}>
                                {discount != 0 ?
                                    <Placeholder className={css.price_discount_wrap} as="h5" animation="glow">
                                        <Placeholder xs={3} />
                                    </Placeholder>
                                    :
                                    <Placeholder className={css.price} as="h5" animation="glow">
                                        <Placeholder xs={3} />
                                    </Placeholder>
                                }
                                <Placeholder className={css.description} as="p" animation="glow">
                                    <Placeholder xs={12} />
                                    <Placeholder xs={12} />
                                    <Placeholder xs={12} />
                                    <Placeholder xs={12} />
                                    <Placeholder xs={12} />
                                    <Placeholder xs={5} />
                                </Placeholder>
                            </div>
                            <ListGroup>
                                <ListGroup.Item className={css.list_item}>
                                    <Placeholder animation="glow">
                                        <Placeholder xs={3} />
                                    </Placeholder>
                                    <Placeholder animation="glow">
                                        <Placeholder xs={5} />
                                    </Placeholder>
                                </ListGroup.Item>
                                <ListGroup.Item className={css.list_item}>
                                    <Placeholder animation="glow">
                                        <Placeholder xs={6} />
                                    </Placeholder>
                                    <Placeholder animation="glow">
                                        <Placeholder xs={3} />
                                    </Placeholder>
                                </ListGroup.Item>
                                <ListGroup.Item className={css.list_item}>
                                    <Placeholder animation="glow">
                                        <Placeholder xs={4} />
                                    </Placeholder>
                                    <Placeholder animation="glow">
                                        <Placeholder xs={2} />
                                    </Placeholder>
                                </ListGroup.Item>
                                <ListGroup.Item className={css.list_item}>
                                    <Placeholder animation="glow">
                                        <Placeholder xs={3} />
                                    </Placeholder>
                                    <Placeholder animation="glow">
                                        <Placeholder xs={6} />
                                    </Placeholder>
                                </ListGroup.Item>
                                <ListGroup.Item className={css.list_item}>
                                    <Placeholder animation="glow">
                                        <Placeholder xs={5} />
                                    </Placeholder>
                                    <Placeholder animation="glow">
                                        <Placeholder xs={3} />
                                    </Placeholder>
                                </ListGroup.Item>
                                <ListGroup.Item className={css.list_item}>
                                <Placeholder animation="glow">
                                    <Placeholder xs={3} /> 
                                </Placeholder>
                                <Placeholder animation="glow">
                                    <Placeholder xs={4} />
                                </Placeholder>
                            </ListGroup.Item>
                            </ListGroup>
                        </div>
                    </div>
                </div>
            :
            <div className={css.not_found_wrap}>
                <h4>Info not found.</h4>
            </div>
    )
}

export default ProductInfo