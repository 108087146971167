import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

const initialState = {
   toasts: [],
   timeout: 5000,
   maxToasts: 3
}

export const toastsSlice = createSlice({
    name: "toasts",
    initialState,
    reducers: { 
        show: (state, action) => {
            if(state.toasts.length >= state.maxToasts) {
                state.toasts.pop()
            }
            state.toasts.unshift({id: action.payload.id, bg: action.payload.data.bg, text: action.payload.data.text})
        }, 
        close: (state, action) => {
            state.toasts = state.toasts.filter(toast => toast.id != action.payload)
        }
    }
})  

export const toast = (bg, text) => (dispatch) => {
    dispatch(toastTransformedParams({ bg, text }));
}

export const toastTransformedParams = createAsyncThunk(
    "toasts/toastTransformedParams",
    async (data, { dispatch, getState }) => { 
        const id = Date.now() 
        dispatch(show({ id, data }))

        const { toasts } = getState()

        setTimeout(() => dispatch(close(id)), toasts.timeout)  
    } 
)

export const { show, close } = toastsSlice.actions
export default toastsSlice.reducer 