import React, { createContext, useEffect, useState } from "react"
import OptionAdd from "./option_add.jsx"
import { useDispatch, useSelector } from "react-redux"
import { unwrapResult } from '@reduxjs/toolkit'
import { apiAddCategorie } from "../../../../../store/slices/adminSlice.js"
import useAuthRedirect from "../../../../../middleware/isAdminAuth.jsx"
import { toast } from "../../../../../store/slices/toastsSlice.js"
import { useNavigate } from "react-router-dom"

const Context = createContext()

const OptionAddAddContainer = () => {

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const { isAuthDispatch } = useAuthRedirect()
    
    const {
        products: {
            categories: {
                connection: {
                    isAddingFetch
                },
            },
        }
    } = useSelector((state) => state.admin)

    const { 
        form: {
            max_categorie
        }
    } = useSelector((state) => state.constants)

    const [form, setForm] = useState({})
    const [errors, setErrors] = useState({})

    const setField = (field, value) => {
        setForm({
            ...form,
            [field]: value
        });

        const fieldErrors = validateField(field, value); 

        setErrors({
            ...errors,
            [field]: fieldErrors[field]
        });
    }

    const validateField = (field, value) => {
        const newError = {};

        switch (field) {
            case 'name':
                if (value  == undefined || value == '') newError.name = 'Please enter name'
                if (value && value.length > max_categorie) newError.name = `Name must be less than ${max_categorie} characters.`
                break;
            case 'categorie':
                if (value == undefined || value == '') newError.categorie = 'Please select categorie'
                break;
        }

        return newError;
    }

    const validateForm = () => {
        const newErrors = {};

        const fields = ['name', 'categorie'];

        fields.forEach(field => {
            const fieldErrors = validateField(field, form[field]);
            Object.assign(newErrors, fieldErrors);
        });

        return newErrors;
    }

    const dispatchApiAddCategorie = () => {
        return isAuthDispatch(apiAddCategorie, form)
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        const formErrors = validateForm()

        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors)
            dispatch(toast("warning", "Invalid form"))
        } else {
            const formData = new FormData(); 
            for (let key in form) {
                formData.append(key, form[key])
            }
            const actionRes = await dispatchApiAddCategorie(formData) 
            const promiseRes = unwrapResult(actionRes) 

            const setError = (err) => {
                setErrors(err)
                dispatch(toast("warning", "Invalid form"))
            }

            switch (promiseRes.status) {
                case "already_exists":
                    setError({name: "This name already exists"})
                    break
                case "success":
                    navigate("/admin/templates/typesandtopics?page=1")
                    dispatch(toast("success", "Categorie added"))
                    break
            }
        } 
    }

    useEffect(() => {
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
    }, [])

    const contextData = { form, handleSubmit, setField, errors, isAddingFetch }

    return (
        <Context.Provider value={contextData}>
            <OptionAdd/>
        </Context.Provider>
    )
} 

export { Context }
export default OptionAddAddContainer 