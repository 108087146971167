import React, { useContext } from "react";
import css from "./support.module.css";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Pen, Send } from 'react-bootstrap-icons';
import { Context } from "./support_container";
import Spinner from "react-bootstrap/Spinner";

const Support = () => {

    const { form, errors, setField, handleSubmit, isSupportMessagingFetch } = useContext(Context)

    return ( 
        <div className={css.form_wrap}>
            <h4 className={css.title}>Write to us on any question of interest. <Pen size="1.5rem" /></h4>
            <Form noValidate onSubmit={handleSubmit} className={css.form} > 
            <Form.Group className={css.input} controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                    type="email"
                    id="email"
                    value={form.email}
                    onChange={e => setField('email', e.target.value)}
                    isValid={form.email !== undefined && form.email !== '' && !errors.email}
                    isInvalid={!!errors.email}
                    placeholder="Enter email"
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className={css.input} controlId="description">
                <Form.Label>Description</Form.Label> 
                <Form.Control
                    as="textarea" 
                    placeholder="Enter description"
                    value={form.description}
                    onChange={e => setField('description', e.target.value)}
                    isValid={form.description !== undefined && form.description !== '' && !errors.description}
                    isInvalid={!!errors.description}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">{errors.description}</Form.Control.Feedback> 
            </Form.Group>
                <Button disabled={isSupportMessagingFetch} variant="primary" className={css.btn_submit} type="submit"><div className={css.btn_submit_inner}>Send{isSupportMessagingFetch ? <Spinner size="sm" /> : <Send sise="1.25rem"/>}</div></Button>
                <Form.Text >
                <br/>You can also write to us by corporate mail: <div style={{display: "inline"}} className="link">support@producks.pro</div>
            </Form.Text> 
            </Form>
        </div> 
    );
}

export default Support;