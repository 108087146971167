import React, { useContext } from "react"
import { Context } from "./edit_container";
import css from "./edit.module.css"
import Button from 'react-bootstrap/Button';
import { PencilSquare, Person } from 'react-bootstrap-icons';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';

const AccountEdit = () => {

    const { isEditingFetch, handleSubmit, form, errors, setField, isInfoFetch } = useContext(Context)

    return (
        <> 
            {!isInfoFetch ?
                <Form onSubmit={handleSubmit} noValidate className={css.form} >
                    <h3 className={css.title}>Edit profile<Person size="1.75rem" /></h3>
                    <Form.Group controlId="avatar" className={css.input}> 
                        <Form.Label>Avatar</Form.Label>
                        <Form.Control
                            type="file"
                            id="avatar"
                            onChange={e => setField("avatar", e.target.files[0])}
                            isValid={form.avatar !== undefined && form.avatar.size != 0 && !errors.avatar}
                            isInvalid={!!errors.avatar}
                        />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">{errors.avatar}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className={css.input} controlId="name">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            value={form.name}
                            onChange={e => setField('name', e.target.value)}
                            isValid={form.name !== undefined && form.name !== '' && !errors.name}
                            isInvalid={!!errors.name}
                            placeholder="Enter name"
                        />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                    </Form.Group>
                    <Button disabled={isEditingFetch} type="submit" variant="primary" className={css.btn_submit}><div className={css.btn_submit_inner}>Edit{isEditingFetch ? <Spinner size="sm" /> : <PencilSquare size="1.25rem" />}</div></Button>
                </Form>
                :
                <div className={css.edit_fetch}>
                    <Spinner size="lg" />
                </div> 
            }
        </>
    )
}


export default AccountEdit
