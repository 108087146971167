import React from "react"
import css from "./footer.module.css"
import LogoImg from '../../images/logo_grey.png'
import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <footer className={css.footer}>
            <div className={css.footer_inner}>
                <div className={css.content}>
                    <div className={css.links}>
                        <div className={css.shop}>
                            <h5>Shop</h5>
                            <Nav.Link as={Link} to="/about-us">About us</Nav.Link>
                            <Nav.Link as={Link} to="/privacy-policy">Privacy Policy</Nav.Link>
                            <Nav.Link as={Link} to="/terms-and-conditions">Terms & Conditions</Nav.Link>
                            <Nav.Link as={Link} to="/cookie-concent">Cookie Concent</Nav.Link>
                        </div>
                        <div className={css.help}>
                            <h5>Help</h5>
                            <Nav.Link as={Link} to="/faq">FAQ</Nav.Link>
                            <Nav.Link as={Link} to="/support">Support</Nav.Link>
                        </div>
                        <div className={css.not_involved}>ProDucks is not involved in the development of any item that is listed. These items are developed by third-party developers and are distributed by ProDucks. All items are falls under the GPL. We at ProDucks are not linked and endorsed with the products of developer listed in our directory.</div>
                    </div>
                    <Image src={LogoImg} className={css.logo_img}></Image>
                </div>
                <div className={css.brand}>© 2024 ProDucks. Trademarks and brands are the property of their respective owners.</div>
            </div>
        </footer>
    )
}

export default Footer
