import React, { useContext } from "react"
import css from "./authors_map.module.css"
import Button from 'react-bootstrap/Button';
import { ThreeDotsVertical, CloudDownload } from "react-bootstrap-icons"
import Modal from 'react-bootstrap/Modal';
import { Link } from "react-router-dom";
import { Context } from "../authors_container";
import Spinner from "react-bootstrap/Spinner";
import Nav from 'react-bootstrap/Nav';

const AuthorsMap = () => {

  const { uploads, isUploadRejectingFetch, moreModalShow, confirmRejection, onShowMoreModal, onHideMoreModal, setConfirmRejection,
    dispatchApiRejectUpload, dispatchApiGetFile
   } = useContext(Context)

  const MoreModal = () => {
    return (
      <Modal 
        show={moreModalShow.show}
        onHide={() => onHideMoreModal("", "")}
        size="md"
        className={css.modal} 
        animation={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Information
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={css.item}>Upload <Nav.Link className={`link ${css.item_link}`}>#{moreModalShow.id}</Nav.Link> from author <Nav.Link className={`link ${css.item_link}`}>#{moreModalShow.user_id}</Nav.Link></div>
          <div className={css.action}>
            {!confirmRejection ?
            <>
               <div>Reject upload</div>
               <div className={css.action_btn_wrap}>
               <Button disabled={isUploadRejectingFetch} onClick={() => setConfirmRejection(true)} className={css.rej_btn} variant="warning">Reject{isUploadRejectingFetch && <Spinner size="sm"/>}</Button>
               </div>
            </>
              :
              <>
                <div>Confim rejection?</div>
                <div className={css.action_btn_wrap}>
                <Button onClick={() => dispatchApiRejectUpload(moreModalShow.id)} className={css.rej_btn} variant="warning">Confirm</Button>
                <Button onClick={() => setConfirmRejection(false)} className={`btn-second ${css.cancel_btn}`}>Cancel</Button>
                </div>  
              </>
            } 
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-prime" onClick={() => onHideMoreModal("", "")}>Close</Button>
        </Modal.Footer>
      </Modal>
    ); 
  }

  return (
    <> 
      {uploads.map(u => 
        <tr key={u.id}>
          <td className="align-middle">{u.id}</td> 
          <td className="align-middle">{u.user_id}</td>
          <td onClick={() => dispatchApiGetFile("upload", u.user_id)} className="align-middle link"><CloudDownload size="1rem" /> {u.file}</td> 
          <td className="align-middle"><div className={css.inner}>{u.price.toString().replace(".", ",")}<div className={css.btn_wrap}><Button as={Link} to={`/admin/authors/view?id=${u.id}`} className={css.info_btn} variant="info">View</Button><Button onClick={() => onShowMoreModal(u.id, u.user_id)} className={`btn-second ${css.more_btn}`}><ThreeDotsVertical size={"1.25rem"} /></Button></div></div></td>
        </tr>
      )} 
      
      <MoreModal/>
    </>
  )
}

export default AuthorsMap