import React, { useContext } from "react"
import css from "./main.module.css"
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import { Search, ArrowRightShort, ShieldCheck, ArrowsFullscreen, Headset } from 'react-bootstrap-icons';
import { Link } from "react-router-dom";
import { Context } from "./main_container";
import Card from 'react-bootstrap/Card';

const Main = () => {

    const { dispatchSetSearch, localSearch, setLocalSearch } = useContext(Context)

    return (
        <div className={css.main}>
            <section className={css.search_section}>
                <div className={css.search_overview}>
                    <div className={css.search_overview_inner}>
                        <div className={css.search_header}>
                            <h2>Premium GPL Wordpress themes for any project</h2>
                            <p>Discover many of easily customizable themes or become an author.</p>
                        </div>
                        <InputGroup className={css.search_input}>
                            <Form.Control
                                value={localSearch}
                                onChange={e => setLocalSearch(e.target.value)}
                                placeholder="I'm looking for.."
                            />
                            <Button as={Link} to="/categories?page=1" onClick={() => dispatchSetSearch()} className={css.search_btn}>
                                Search<Search size="1rem"></Search>
                            </Button>
                        </InputGroup>
                    </div>
                </div>
            </section>
            <section className={css.trust_section}>
                <h3>Why do you have to trust us:</h3>
                <div className={css.cards}>
                    <Card className={css.card} >
                        <Card.Body>
                            <Card.Title className={css.card_title}>Authentic, Verified Products<ShieldCheck size="1.25rem" /></Card.Title>
                            <Card.Text>
                                ProDucks is trusted by a large community of satisfied customers. Our platform holds a hight rating on the renowned consumer review websites.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className={css.card}>
                        <Card.Body>
                            <Card.Title className={css.card_title}>Reliable Customer Support<Headset size="1.25rem" /></Card.Title>
                            <Card.Text>
                                We are proud to offer 24/7 customer support. Whatever issue you face, we treat each client with respect and go the extra mile to resolve any problems. Our dedication makes ProDucks a popular WordPress GPL platform.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className={css.card} >
                        <Card.Body>
                            <Card.Title className={css.card_title}>Extensive Product Selection<ArrowsFullscreen size="1.25rem" /></Card.Title>
                            <Card.Text>
                                ProDucks offers a lot of collections of Premium GPL themes. Whatever you need for your website, you can find it here.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
            </section>
            <section className={css.categories_section}>
                <Button className={css.categories_btn} as={Link} variant="primary" to="/categories?page=1">Go to categories<ArrowRightShort size="1.25rem" /></Button>
            </section>
        </div>
    )
}

export default Main
