import axios from 'axios';

const instance = axios.create({
    baseURL: "/api",
    withCredentials: true
})

export var adminApi = {

    Login(dataJson) { 
        return instance.post("auth/admin/login", dataJson)
    },

    IsAdmin() {
        return instance.get("admin/user")
    }, 

    GetStatistics() {
        return instance.get("/admin/statistics")
    }, 
 
    GetUsers(dataJson) {
        return instance.post("/admin/users/get", dataJson)
    },

    AddUser(dataJson) {
        return instance.post("/admin/users/add", dataJson)
    },

    DeleteUser(dataJson) {
        return instance.delete("/admin/users/delete", { data: dataJson })
    },

    DeleteAuthor(dataJson) {
        return instance.delete("admin/users/author-delete", { data: dataJson })
    },

    GetUploads(dataJson) {
        return instance.post("/admin/authors/get", dataJson)
    },

    RejectUpload(dataJson) {
        return instance.post("/admin/authors/delete", dataJson)
    },

    GetUploadInfo(dataJson) {
        return instance.post("/admin/authors/info/get", dataJson)
    },

    AddUpload(formData) {
        return instance.post("/admin/authors/product/add", formData, {
            headers: { 
                "Content-Type": "multipart/form-data"
            }
        }
    )
    },

    GetProducts(dataJson) {
        return instance.post("/admin/products/get", dataJson)
    },

    DeleteProduct(dataJson) {
        return instance.delete("/admin/products/delete", { data: dataJson })
    },

    GetEditInfo(dataJson) {
        return instance.post("/admin/product/info/get", dataJson)
    },

    
    ProductEdit(formData) {
        return instance.post("/admin/product/edit", formData, {
            headers: { 
                "Content-Type": "multipart/form-data"
            }
        })
    },

    AddProduct(formData) {
        return instance.post("/admin/product/add", formData, {
            headers: { 
                "Content-Type": "multipart/form-data"
            }
        })
    },

    GetFile(dataJson) {
        return instance.post("/admin/file/get", dataJson, {
            responseType: 'blob'
        })
    },

    GetCategories(dataJson) {
        return instance.post("/admin/categories/get", dataJson)
    },

    DeleteCategorie(dataJson) {
        return instance.delete("/admin/categories/delete", { data: dataJson })
    },

    AddCategorie(dataJson) {
        return instance.post("/admin/categories/add", dataJson)
    },

    GetSupport(dataJson) {
        return instance.post("/admin/support/get", dataJson
    )
    },

    DeleteSupport(dataJson) {
        return instance.delete("/admin/support/delete", { data: dataJson })  
    }, 
}
 
export var authApi = {

    Register(dataJson) {
        return instance.post("auth/register", dataJson)
    },

    RegisterVerification(dataJson) {
        return instance.post("auth/register-verification", dataJson)
    },

    EmailVerification(dataJson) {
        return instance.post("auth/email-verification", dataJson)
    },

    RecoverPassword(dataJson) {
        return instance.post("auth/recover-password", dataJson)
    },

    Login(dataJson) {
        return instance.post("auth/login", dataJson)
    },

    Logout() {
        return instance.get("auth/logout")
    },
}

export var productsApi = {

    GetProducts(dataJson) {
        return instance.post("products/get", dataJson)
    },

    GetProductInfo(dataJson) {
        return instance.post("products/info/get", dataJson)
    },

    GetCategories() {
        return instance.get("products/categories/get")
    },

    LikeProduct(dataJson) {
        return instance.post("products/like", dataJson)
    },
}

export var accountApi = {

    IsUser() {
        return instance.get("account/user")
    },
    
    getProfileInfo() { 
        return instance.get("account/profile/get")
    },
 
    GetLikes(dataJson) {
        return instance.post("/account/profile/likes/get", dataJson)
    }, 

    AddHistory(dataJson) {
        return instance.post("account/profile/history/add", dataJson)
    },

    GetHistory() {
        return instance.get("account/profile/history/get")
    },

    ProfileEdit(formData) { 
        return instance.post("account/profile/edit", formData, {
            headers: { 
                "Content-Type": "multipart/form-data" 
            }
        })
    },

    GetPurchases(dataJson) {
        return instance.post("account/purchases/get", dataJson)
    },

    PurchaseDelete(dataJson) {
        return instance.delete("account/purchases/delete", { data: dataJson })
    },

    GetBody(dataJson) {
        return instance.post("account/template/get-body", dataJson, {
            responseType: 'blob'
        })
    },
    
    AuthorUpload(formData) {
        return instance.post("account/author/upload", formData, {
            headers: { 
                "Content-Type": "multipart/form-data" 
            }
        })   
    },

    RegisterAuthor(dataJson) {
        return instance.post("account/author/register", dataJson)
    },

    GetAuthorTemplates(dataJson) {
        return instance.post("account/author/templates/get", dataJson)
    },

    DeleteAuthorProduct(dataJson) {
        return instance.post("account/author/templates/delete", dataJson)
    },

    AuthorGetInfo() {
        return instance.get("account/author/info/get" )
    },

    apiGetAuthorOfferUploadsStatus() {
        return instance.get("account/author/uploads-status",
    )
    },

    GetAuthorStripeLink() {
        return instance.get("account/author/stripe-link",)
    },

    GetAuthorStatus() {
        return instance.get("account/author/status/get",)
    },

    DeleteAccount() {
        return instance.delete("account/delete")
    },

    DeleteAuthor() {
        return instance.delete("account/author/delete")
    },

    ChangePassword(dataJson) {
        return instance.post("account/password/change", dataJson)
    },

    Support(dataJson) {
        return instance.post("account/support", dataJson)
    },
}

export var basketApi = {

    AddProductToBasket(dataJson) {
        return instance.post("basket/add", dataJson)
    },

    GetProductsToBasket() {
        return instance.get("basket/get")
    },

    RemoveProductsFromBasket(dataJson) {
        return instance.delete("basket/delete", { data: dataJson })
    },

    RemoveAllProductsFromBasket() {
        return instance.delete("basket/delete-all")
    },

    AddProductToPurchases() {
        return instance.post("basket/pay")
    }
}
