import React, { createContext, useEffect, useState } from "react"
import NewPassword from "./new_password.jsx"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "../../../../../../store/slices/toastsSlice.js"
import { apiRecoverPassword } from "../../../../../../store/slices/authSlice.js"
import { unwrapResult } from "@reduxjs/toolkit"
import { useNavigate, useSearchParams } from "react-router-dom"

const Context = createContext()

const NewPasswordContainer = () => {
 
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const [searchParams, _] = useSearchParams()
 
    let token = searchParams.get("token")

    const {
        connection: { isPasswordRecoveringFetch }
    } = useSelector((state) => state.auth); 

    const { 
        form: {
            max_password, min_password,
        }
    } = useSelector((state) => state.constants)

    const [form, setForm] = useState({})
    const [errors, setErrors] = useState({})

    const dispatchApiRecoverPassword = () => { 
        return dispatch(apiRecoverPassword(form))
    }

    const setField = (field, value) => {
        setForm({
            ...form,
            [field]: value
        });

        const fieldErrors = validateField(field, value);

        setErrors({
            ...errors,
            [field]: fieldErrors[field]
        });
    }

    const validateField = (field, value) => {
        const newError = {};

        switch (field) {
            case "new_password":
                if (value == undefined || value == "") newError.new_password = 'Please enter new password' 
                else if (value && value.length < min_password) newError.new_password = `New password must be more than ${min_password} characters.`
                else if (value && value.length > max_password) newError.new_password = `New password must be less than ${max_password} characters.`
                break;
            case "repeat_new_password":
                if (value == undefined || value == "") newError.repeat_new_password = 'Please enter new password' 
                else if (value && value.length < min_password) newError.repeat_new_password = `New password must be more than ${min_password} characters.`
                else if (value && value.length > max_password) newError.repeat_new_password = `New password must be less than ${max_password} characters.`
                break;
        }

        return newError;
    }

    const validateForm = () => {
        const newErrors = {};

        if (form.new_password != form.repeat_new_password) {
            Object.assign(newErrors, { repeat_new_password: "Passwords must match" });
        } else {
            delete errors.new_password
            delete errors.repeat_new_password
        }

        const fields = ['new_password', 'repeat_new_password'];

        fields.forEach(field => {
            const fieldErrors = validateField(field, form[field]);
            Object.assign(newErrors, fieldErrors);
        });

        return newErrors;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation()

        const formErrors = validateForm()
      
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors)
            dispatch(toast("warning", "Invalid form"))
        }
         else {
            if( token != null) {
                Object.assign(form, { token });
            } else {
                dispatch(toast("warning", "Session expired")) 
                navigate('/login') 
                return
            }
            const actionRes = await dispatchApiRecoverPassword()
            const promiseRes = unwrapResult(actionRes)

            switch (promiseRes.status) {
                case "session_expired":
                    dispatch(toast("warning", "Session expired"))
                    navigate('/login')
                    break
                case "attempts_exceeded":
                    dispatch(toast("warning", "Number of attempts exceeded. Try again later"))
                    navigate('/login')
                    break
                case "success":
                    dispatch(toast("success", "Password recovered"))
                    navigate('/login')
                    break
            }
        }
    }

    useEffect(() => {
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
    }, [])

    const contextData = { handleSubmit, form, errors, setField, isPasswordRecoveringFetch }

    return (
        <Context.Provider value={contextData}>
            <NewPassword />
        </Context.Provider>
    )
}

export { Context }
export default NewPasswordContainer