import React, { useContext } from "react";
import css from "./statistics.module.css"
import Ratio from 'react-bootstrap/Ratio';
import Badge from 'react-bootstrap/Badge';
import { Context } from "./statistics_container";
import Spinner from "react-bootstrap/Spinner";

const Statistics = () => {

    const { counts, isStatisticsFetch, chartUsersRef, chartProductsRef, chartPurchasesRef, chartUploadsRef } = useContext(Context)

    return (
        <div className={css.statistics}> 
            {!isStatisticsFetch ?
                <>
                    <div className={css.counts}>
                        <div className={css.count}>Registered users: <Badge bg="primary">{counts.users}</Badge></div>
                        <div className={css.count}>Created templates: <Badge bg="primary">{counts.products}</Badge></div>
                        <div className={css.count}>Purchases: <Badge bg="primary">{counts.purchases}</Badge></div>
                        <div className={css.count}>Author uploads: <Badge bg="primary">{counts.uploads}</Badge></div>
                    </div>
                    <Ratio className={css.ratio} aspectRatio="21x9"><canvas ref={chartUsersRef} /></Ratio>
                    <Ratio className={css.ratio} aspectRatio="21x9"><canvas ref={chartProductsRef} /></Ratio>
                    <Ratio className={css.ratio} aspectRatio="21x9"><canvas ref={chartPurchasesRef} /></Ratio>
                    <Ratio className={css.ratio} aspectRatio="21x9"><canvas ref={chartUploadsRef} /></Ratio>
                </>
                :
                <div className={css.spinner_wrap}> 
                    <Spinner size="lg" />
                </div>}
        </div>
    )
}

export default Statistics