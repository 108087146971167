import React, { useContext } from "react"
import css from "./login.module.css"
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom'
import { Context } from "./login_container";
import Spinner from "react-bootstrap/Spinner";
import GoogleImg from "../../../../images/google.png"
import Image from 'react-bootstrap/Image';

const Login = () => {

  const { handleSubmit, form, errors, setField, isLoginingFetch, googleLogin } = useContext(Context)

    return (
        <Form noValidate onSubmit={handleSubmit} className={css.form} >
          <h3 className={css.title}>Login to your account</h3>
            <Form.Floating controlId="email" className={css.input}>
              <Form.Control
                id="email" 
                type="email"
                placeholder="Email"  
                value={form.email}
                onChange={e => setField('email', e.target.value)}
                isValid={form.email && form.email != '' && !errors.email}
                isInvalid={!!errors.email} 
              />  
              <label htmlFor="email">Email</label> 
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback> 
              <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
            </Form.Floating>
            <Form.Floating controlId="password" className={css.input}> 
              <Form.Control 
                id="password"
                type="password"
                placeholder="Password" 
                value={form.password}
                onChange={e => setField('password', e.target.value)}
                isValid={form.password && form.password != '' && !errors.password} 
                isInvalid={!!errors.password}
              /> 
              <label htmlFor="password">Password</label> 
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback> 
            </Form.Floating>
            <div className={css.btns_wrap}>
             <Button disabled={isLoginingFetch} type="submit" variant="primary" className={css.btn_submit}>Log in{isLoginingFetch && <Spinner size="sm" />}</Button>
              <Button disabled={isLoginingFetch} onClick={googleLogin} className={`btn-second ${css.btn_google}`}><Image className={css.google_img} src={GoogleImg}></Image>Continue with Google</Button>
            </div>
            <div className={css.links_wrap}>
              <div>Don't have an account yet?<br /><Nav.Link as={Link}className={`link ${css.link}`} to="/register">Register</Nav.Link></div>
              <div>Forgot your password?<br /><Nav.Link as={Link}className={`link ${css.link}`} to="/login/verify">Recover</Nav.Link></div>
            </div>
        </Form> 
    ) 
} 
 
export default Login