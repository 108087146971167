import React, { useContext } from "react"
import css from "./types_and_topics_map.module.css"
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { ThreeDotsVertical } from "react-bootstrap-icons"
import { Context } from "../types_and_topics_container";
import Spinner from "react-bootstrap/Spinner";
import Nav from 'react-bootstrap/Nav';

const TypesAndTopicsMap = () => {

    const { isDeltetingFetch, onShowMoreModal, onHideMoreModal, moreModalShow, confirmDeletion, setConfirmDeletion, categories, categorie, 
        dispatchApiDeleteCategorie
     } = useContext(Context)

    const MoreModal = () => {
        return (
            <Modal 
                show={moreModalShow.show}
                onHide={() => onHideMoreModal("", "")}
                size="md"
                className={css.modal}
                centered
                animation={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Information
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={css.item}>Categorie {categorie}<Nav.Link className={`link ${css.item_link}`}>#{moreModalShow.id}</Nav.Link><Nav.Link className={`link ${css.item_link}`}>{moreModalShow.name}</Nav.Link></div>
                    <div className={css.action}>
                        {!confirmDeletion ?
                        <>
                        <div>Delete categorie</div>
                        <div className={css.action_btn_wrap}>
                        <Button disabled={isDeltetingFetch} onClick={() => setConfirmDeletion(true)} className={css.del_btn} variant="danger">Delete{isDeltetingFetch && <Spinner size="sm"/>}</Button>
                        </div>
                        </>
                        :
                        <>
                            <div>Confim deletion?</div> 
                            <div className={css.action_btn_wrap}>
                            <Button onClick={() => dispatchApiDeleteCategorie(categorie, moreModalShow.id)} className={css.del_btn} variant="danger">Confirm</Button>
                            <Button onClick={() => setConfirmDeletion(false)} className={`btn-second ${css.cancel_btn}`}>Cancel</Button>
                            </div>  
                        </>
                        } 
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-prime" onClick={() => onHideMoreModal("", "")}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    return ( 
        <>
            {categories.map(c =>
                <tr key={c.id}>
                    <td className="align-middle">{c.id}</td>
                    <td className="align-middle"><div className={css.inner}>{c.name}<Button onClick={() => onShowMoreModal(c.id, c.name, true)} className={`btn-second ${css.more_btn}`}><ThreeDotsVertical size={"1.25rem"} /></Button></div></td>
                </tr>
            )}

            <MoreModal/>
        </>
    )
} 

export default TypesAndTopicsMap