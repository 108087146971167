import React, { useContext } from "react"
import { Context } from "./profile_container";
import css from "./profile.module.css"
import ProfileHistoryMap from "./profile_history_map/profile_history_map.jsx"
import LikesMap from "./likes_map/likes_map.jsx"
import Image from 'react-bootstrap/Image';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import { PencilSquare, Heart, ClockHistory, BoxArrowLeft } from 'react-bootstrap-icons';
import { Link } from "react-router-dom";
import Placeholder from 'react-bootstrap/Placeholder';
import Ratio from 'react-bootstrap/Ratio';
import Spinner from "react-bootstrap/Spinner";

const Profile = () => {

    const { id, name, email, avatar, historyNotFound, likesNotFound, months, year, month, isInfoFetch, isFetch,
        purchased, products_created, sold, isLogoutingFetch, dispatchApiLogout } = useContext(Context)

    return (
        <>
            <div className={css.profile}>
                {!isInfoFetch && !isFetch ?
                    <section className={css.info}>
                        <div className={css.data}>
                            {avatar != '' &&
                                <Image className={css.avatar} src={`https://storageplatform0.blob.core.windows.net/avatars/${avatar}?${new Date().getTime()}`} thumbnail />
                            }
                            <div className={css.details}>
                                <h5>Information</h5>
                                {name != "" && <div><span>Name </span>{name}</div>}
                                <div><span>Email </span>{email}</div>
                                <div><span>Member since </span>{months[month]}, {year}</div>
                                <div><span>#ID </span>{id}</div>
                            </div>
                            <div className={css.statistics}>
                                <h5>Statistics</h5>
                                <div><span>Purchased </span>{purchased}</div>
                                <div><span>Created </span>{products_created}</div>
                                <div><span>Sold </span>{sold}</div>
                            </div>
                        </div>
                        <div className={css.btns_wrap}>
                            <Button as={Link} to="/account/edit" variant="secondary" className={css.edit_btn}>Edit<PencilSquare size="1.25rem" /></Button>
                            <Button disabled={isLogoutingFetch} onClick={() => dispatchApiLogout()} className={`btn-second ${css.logout_btn}`}>{isLogoutingFetch ? <Spinner size="sm" /> : <BoxArrowLeft size="1.25rem" />}Log out</Button>
                        </div>
                    </section>
                    :
                    <section className={css.info}>
                        <div className={css.data}>
                            <Placeholder className={css.avatar} animation="glow">
                                <Ratio>
                                    <Placeholder className={css.avatar_plaseholder} />
                                </Ratio>
                            </Placeholder>
                            <div className={css.details}>
                                <h5>Information</h5>
                                <Placeholder as="div" animation="glow">
                                    <Placeholder xs={9} />
                                </Placeholder>
                                <Placeholder as="div" animation="glow">
                                    <Placeholder xs={10} />
                                </Placeholder>
                                <Placeholder as="div" animation="glow">
                                    <Placeholder xs={8} />
                                </Placeholder>
                            </div>
                            <div className={css.statistics}>
                                <h5>Statistics</h5>
                                <Placeholder as="div" animation="glow">
                                    <Placeholder xs={9} /> 
                                </Placeholder>
                                <Placeholder as="div" animation="glow">
                                    <Placeholder xs={10} />
                                </Placeholder>
                                <Placeholder as="div" animation="glow">
                                    <Placeholder xs={8} />
                                </Placeholder>
                            </div>
                        </div>
                        <div className={css.btns_wrap}>
                            <Button disabled variant="secondary" className={css.edit_btn}>Edit<PencilSquare size="1.25rem" /></Button>
                            <Button disabled className={`btn-second ${css.logout_btn}`}><BoxArrowLeft size="1.25rem" />Log out</Button>
                        </div>
                    </section>
                }
                <section className={css.likes}>
                    <h5>Favorites<Heart className={css.heart_img} size="1.25rem" /></h5>
                    {!likesNotFound || isFetch ?
                        <LikesMap />
                        :
                        <div className={css.no_likes_wrap}>
                            <h4>You have no liked products.</h4>
                        </div>
                    }
                </section>
                <section className={css.history}>
                    <h5>Viewing history<ClockHistory className={css.history_img} size="1.25rem" /></h5>
                    {!historyNotFound || isFetch ?
                        <ListGroup>
                            <ProfileHistoryMap />
                        </ListGroup> :
                        <div className={css.no_history_wrap}>
                            <h4>You have no visited products.</h4>
                        </div>
                    }
                </section>
            </div>
        </>
    )
}


export default Profile
