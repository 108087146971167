import React, { createContext, useEffect } from "react";
import ProductInfo from "./product_info"
import { useDispatch, useSelector } from "react-redux"
import { apiGetProductInfo, apiLikeProduct, setLikingId } from "../../../../store/slices/categoriesSlice"
import { apiAddProductToBasket, setAddingId } from "../../../../store/slices/basketSlice"
import { apiAddHistory } from "../../../../store/slices/accountSlice"
import { useSearchParams } from "react-router-dom";
import useAuthRedirect from "../../../../middleware/isAuth.jsx"
import { toast } from "../../../../store/slices/toastsSlice.js";

const Context = createContext()

const ProductInfoContainer = () => {

    const { isAuthDispatch } = useAuthRedirect()

    const dispatch = useDispatch()
    
    const [searchParams, _] = useSearchParams()
 
    let id_params = Number(searchParams.get("id")) 

    const {
        info: { id, name, price, price_with_discount, discount, img_preview, type, topic, created, description, author,
            infoNotFound, liked, likingId, version,
            connection: { isInfoFetch, isLikingFetch } 
        },
    } = useSelector((state) => state.categories);

    const { addingId, connection: { isAddingFetch } } = useSelector((state) => state.basket)

    const dispatchApiGetProductInfo = () => {
        dispatch(apiGetProductInfo({id: id_params}))
    }

    const dispatchApiAddHistory = () => {
        dispatch(apiAddHistory({id: id_params}))
    }

    const dispatchApiAddProductToBasket = () => {
        isAuthDispatch(apiAddProductToBasket, { id: id_params }) 
    }

    const dispatchApiLikeProduct = () => {
        isAuthDispatch(apiLikeProduct, {id: id_params})
    }

    const dispatchSetLikingId = () => { 
        dispatch(setLikingId(id_params))
    }

    const dispatchSetAddingId = () => { 
        dispatch(setAddingId(id_params))
    }
 
    useEffect(() => {
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
        dispatchApiGetProductInfo() 
        dispatchApiAddHistory() 
    }, [])

    const dispatchToast = (bg, text) => {
        dispatch(toast(bg, text))
    }

    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

    const parsedDate = new Date(created);
    const date = parsedDate.getDate();
    const year = parsedDate.getFullYear();
    const month = parsedDate.getMonth();

    const contextData = { id, name, price, discount, img_preview, type, topic, description, author,
        infoNotFound, isInfoFetch, liked, dispatchApiLikeProduct, isLikingFetch, dispatchSetLikingId, likingId,
        addingId, isAddingFetch, dispatchApiAddProductToBasket, dispatchSetAddingId, price_with_discount, dispatchToast,
        months, date, year, month, version
    }

    return (
        <Context.Provider value={contextData}>
            <ProductInfo/>
        </Context.Provider>
    )
}

export { Context }
export default ProductInfoContainer