import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { accountApi } from "../../api/api.js"
import { toast } from "./toastsSlice.js"
import handleError from "./error/error.js"

const initialState = {
    profile: {
        id: 0,
        name: "",
        email: "",
        avatar: "",
        purchased: 0,
        products_created: 0, 
        sold: 0,
        created: 0,
        history: {
            history: [],
            historyNotFound: false,
        },
        likes: {
            likes: [],
            limit: 12,
            count: 0,
            likesNotFound: false,
        },
        connection: { 
            isFetch: true,
            isEditingFetch: false,
            isInfoFetch: true,
            isHistoryFetch: true,
            isLikesFetch: true,
        },
        placeholders: {
            likesPlaceholders: 6,
            historyPlaceholders: 6,
        }
    },
    purchases: {
        products: [],
        limit: 6,
        count: 0,
        productsNotFound: false,
        downloadingId: 0,
        connection: {
            isPurchasesFetch: true,
            isDownloadingFetch: false,
            isPurchaseDeletingFetch: false,
        },
        placeholders: {
            purchasesPlaceholders: 4,
        }
    },
    author: {
        status: "",
        products: [],
        limit: 6,
        count: 0,
        uploadStatus: "",
        balance: 0,
        currency: "",
        details: { 
            individual: {
                account: "dwq",
            },
            created: 0,
            settings: {
                payouts: {
                    schedule: {
                        delay_days: 0,
                        interval: ""
                    }
                }
            },
        },
        productsNotFound: false,
        connection: { 
            isFetch: true,
            isProductsFetch: true,
            isUploadStatusFetch: true,
            isStatusFetch: true,
            isInfoFetch: true,
            isUploadingFetch: false,
            isRegisteringFetch: false,
            isProductDeletingFetch: false,
            isOnboardingFetch: false,
            isEditingFetch: false
        },
        placeholders: {
        productsPlaceholders: 4,
        }
    },
    settings: {
        connection: {
            isChangingPasswordFetch: false,
            isAccountDeletingFetch: false, 
            isAuthorDeletingFetch: false 
        }
    },
    support: {
        connection: {
            isSupportMessagingFetch: false
        }
    }
}

export const accountSlice = createSlice({
    name: "account",
    initialState,
    reducers: {
        setIsProfileFetch: (state, action) => {
            state.profile.connection.isFetch = action.payload
        }, 
        setDownloadingId: (state, action) => { 
            state.purchases.downloadingId = action.payload  
        },
        setIsAuthorFetch: (state, action) => {
            state.author.connection.isFetch = action.payload
        }, 
    },
    extraReducers(builder) {
        builder
            .addCase(apiGetProfileInfo.pending, (state, _) => {
                state.profile.connection.isInfoFetch = true
            })
            .addCase(apiGetProfileInfo.fulfilled, (state, action) => {
                if(action.payload.status == "success") {
                    Object.assign(state.profile, action.payload.data)
                }
                state.profile.connection.isInfoFetch = false
            })
            .addCase(apiGetHistory.pending, (state, _) => {
                state.profile.connection.isHistoryFetch = true
                state.profile.history.historyNotFound = false
            })
            .addCase(apiGetHistory.fulfilled, (state, action) => {
                switch(action.payload.status) {
                    case "no_history":
                        state.profile.history.historyNotFound = true
                        break
                    case "success":
                        state.profile.history.history = action.payload.data
                        break
                }
                state.profile.connection.isHistoryFetch = false
            })
            .addCase(apiGetLikes.pending, (state, _) => { 
                state.profile.connection.isLikesFetch = true
                state.profile.likes.likesNotFound = false
            })
            .addCase(apiGetLikes.fulfilled, (state, action) => {
                switch(action.payload.status) {
                    case "no_likes":
                        state.profile.likes.likesNotFound = true
                        break
                    case "success":
                        state.profile.likes.likes = action.payload.data.products 
                        state.profile.likes.count = action.payload.data.count
                        break
                }
                state.profile.connection.isLikesFetch = false
            })
            .addCase(apiProfileEdit.pending, (state, _) => {
                state.profile.connection.isEditingFetch = true
            })
            .addCase(apiProfileEdit.fulfilled, (state, _) => {
                state.profile.connection.isEditingFetch = false
            })
            .addCase(apiProfileEdit.rejected, (state, _) => {
                state.profile.connection.isEditingFetch = false
            })
            .addCase(apiGetPurchases.pending, (state, _) => {
                state.purchases.connection.isPurchasesFetch = true
                state.purchases.productsNotFound = false
            })
            .addCase(apiGetPurchases.fulfilled, (state, action) => {
                switch(action.payload.status) {
                    case "no_products":
                        state.purchases.productsNotFound = true
                        break
                    case "success":
                        state.purchases.products = action.payload.data.products 
                        state.purchases.count = action.payload.data.count
                        break
                }
                state.purchases.connection.isPurchasesFetch = false
            })
            .addCase(apiPurchaseDelete.pending, (state, _) => {
                state.purchases.connection.isPurchaseDeletingFetch = true
            })
            .addCase(apiPurchaseDelete.fulfilled, (state, _) => {
                state.purchases.connection.isPurchaseDeletingFetch = false
            })
            .addCase(apiPurchaseDelete.rejected, (state, _) => {
                state.purchases.connection.isPurchaseDeletingFetch = false
            })
            .addCase(apiGetBody.pending, (state, _) => {
                state.purchases.connection.isDownloadingFetch = true
            })
            .addCase(apiGetBody.fulfilled, (state, _) => {
                state.purchases.connection.isDownloadingFetch = false
            })
            .addCase(apiGetBody.rejected, (state, _) => {
                state.purchases.connection.isDownloadingFetch = false
            })
            .addCase(apiGetAuthorTemplates.pending, (state, _) => {
                state.author.connection.isProductsFetch = true
                state.author.productsNotFound = false
            })
            .addCase(apiGetAuthorTemplates.fulfilled, (state, action) => {
                switch(action.payload.status) {
                    case "no_products":
                        state.author.productsNotFound = true
                        break
                    case "success":
                        state.author.products = action.payload.data.products
                        state.author.count = action.payload.data.count
                        break
                }
                state.author.connection.isProductsFetch = false
            })
            .addCase(apiGetAuthorOfferUploadsStatus.pending, (state, _) => {
                state.author.connection.isUploadStatusFetch = true
            })
            .addCase(apiGetAuthorOfferUploadsStatus.fulfilled, (state, action) => {
                if(action.payload.status == "success") {
                    state.author.uploadStatus = action.payload.data
                }
                state.author.connection.isUploadStatusFetch = false
            }) 
            .addCase(apiAuthorGetInfo.pending, (state, _) => {
                state.author.connection.isInfoFetch = true
            })
            .addCase(apiAuthorGetInfo.fulfilled, (state, action) => {
                if(action.payload.status == "success") {
                    state.author.balance = (action.payload.data.balance.available[0].amount + action.payload.data.balance.pending[0].amount) / 100
                    state.author.currency = action.payload.data.balance.available[0].currency
                    Object.assign(state.author.details, action.payload.data.info);
                    console.log(action.payload.data.info)
                }
                state.author.connection.isInfoFetch = false 
            })
            .addCase(apiRegisterAuthor.pending, (state, _) => {
                state.author.connection.isRegisteringFetch = true
            })
            .addCase(apiRegisterAuthor.fulfilled, (state, _) => {
                state.author.connection.isRegisteringFetch = false 
            })
            .addCase(apiRegisterAuthor.rejected, (state, _) => {
                state.author.connection.isRegisteringFetch = false 
            })
            .addCase(apiDeleteAuthorProduct.pending, (state, _) => {
                state.author.connection.isProductDeletingFetch = true
            })
            .addCase(apiDeleteAuthorProduct.fulfilled, (state, _) => {
                state.author.connection.isProductDeletingFetch = false 
            })
            .addCase(apiDeleteAuthorProduct.rejected, (state, _) => {
                state.author.connection.isProductDeletingFetch = false 
            })
            .addCase(apiGetAuthorStripeLink.pending, (state, action) => {
                switch (action.meta.arg.point) {
                    case "notification":
                        state.author.connection.isOnboardingFetch = true
                        break;
                    case "edit":
                        state.author.connection.isEditingFetch = true
                        break;
                }
            })
            .addCase(apiGetAuthorStripeLink.fulfilled, (state, action) => {
                switch (action.payload.point) {
                    case "notification":
                        state.author.connection.isOnboardingFetch = false
                        break;
                    case "edit":
                        state.author.connection.isEditingFetch = false
                        break;
                }
            }) 
            .addCase(apiGetAuthorStripeLink.rejected, (state, action) => {
                switch (action.payload.point) {
                    case "notification":
                        state.author.connection.isOnboardingFetch = false
                        break;
                    case "edit":
                        state.author.connection.isEditingFetch = false
                        break;
                }
            })
            .addCase(apiGetAuthorStatus.pending, (state, _) => {
                state.author.connection.isStatusFetch = true
            })
            .addCase(apiGetAuthorStatus.fulfilled, (state, action) => {
                if(action.payload.status == "success") {
                    state.author.status = action.payload.data 
                }
                state.author.connection.isStatusFetch = false 
            })
            .addCase(apiAuthorUpload.pending, (state, _) => {
                state.author.connection.isUploadingFetch = true
            })
            .addCase(apiAuthorUpload.fulfilled, (state, _) => {
                state.author.connection.isUploadingFetch = false 
            })
            .addCase(apiAuthorUpload.rejected, (state, _) => {
                state.author.connection.isUploadingFetch = false 
            })
            .addCase(apiSupport.pending, (state, _) => {
                state.support.connection.isSupportMessagingFetch = true
            })
            .addCase(apiSupport.fulfilled, (state, _) => {
                state.support.connection.isSupportMessagingFetch = false 
            })
            .addCase(apiSupport.rejected, (state, _) => {
                state.support.connection.isSupportMessagingFetch = false 
            })
            .addCase(apiDeleteAccount.pending, (state, _) => {
                state.settings.connection.isAccountDeletingFetch = true
            })
            .addCase(apiDeleteAccount.fulfilled, (state, _) => {
                state.settings.connection.isAccountDeletingFetch = false 
            })
            .addCase(apiDeleteAccount.rejected, (state, _) => {
                state.settings.connection.isAccountDeletingFetch = false 
            })
            .addCase(apiDeleteAuthor.pending, (state, _) => {
                state.settings.connection.isAuthorDeletingFetch = true
            })
            .addCase(apiDeleteAuthor.fulfilled, (state, _) => {
                state.settings.connection.isAuthorDeletingFetch = false 
            })
            .addCase(apiDeleteAuthor.rejected, (state, _) => {
                state.settings.connection.isAuthorDeletingFetch = false 
            })
            .addCase(apiChangePassword.pending, (state, _) => {
                state.settings.connection.isChangingPasswordFetch = true
            })
            .addCase(apiChangePassword.fulfilled, (state, _) => {
                state.settings.connection.isChangingPasswordFetch = false 
            })
            .addCase(apiChangePassword.rejected, (state, _) => {
                state.settings.connection.isChangingPasswordFetch = false 
            })
    }
})

export const apiGetProfileInfo = createAsyncThunk( 
    "account/apiGetProfileInfo",
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const res = await accountApi.getProfileInfo()

            return res.data 
        } catch (err) {
            handleError(dispatch, err)
            return rejectWithValue(err.message)
        } 
    } 
)

export const apiProfileEdit = createAsyncThunk(
    "account/apiProfileEdit",
    async (data, { dispatch, rejectWithValue }) => {
        try {
            const res = await accountApi.ProfileEdit(data) 

            if (res.data.status == "success") {
                dispatch(toast("success", "Profile data changed")) 
            }

            return res.data 
        } catch (err) {
            handleError(dispatch, err)
            return rejectWithValue(err.message)
        } 
    } 
)

export const apiGetLikes = createAsyncThunk(
    "account/apiGetLikes",
    async (data, { dispatch, rejectWithValue }) => {
        try {
            const res = await accountApi.GetLikes(JSON.stringify(data))  

            return res.data 
        } catch (err) {
            handleError(dispatch, err)
            return rejectWithValue(err.message)
        } 
    } 
)

export const apiGetHistory = createAsyncThunk(
    "account/apiGetHistory",
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const res = await accountApi.GetHistory()

            return res.data 
        } catch (err) {
            handleError(dispatch, err)
            return rejectWithValue(err.message)
        } 
    } 
)

export const apiAddHistory = createAsyncThunk(
    "account/apiAddHistory", 
        async (data, { dispatch, rejectWithValue }) => {
            try {
                const res = await accountApi.AddHistory(JSON.stringify(data))

                return res.data 
            } catch (err) {
                handleError(dispatch, err)
                return rejectWithValue(err.message)
            } 
        } 
)

export const apiGetPurchases = createAsyncThunk(
    "account/apiGetPurchases",
    async (data, { dispatch, rejectWithValue }) => {
        try {
            const res = await accountApi.GetPurchases(JSON.stringify(data))

            return res.data 
        } catch (err) {
            handleError(dispatch, err)
            return rejectWithValue(err.message)
        } 
    } 
)

export const apiPurchaseDelete = createAsyncThunk(
    "account/apiPurchaseDelete",
    async (data, { dispatch, rejectWithValue }) => {
        try {
            const res = await accountApi.PurchaseDelete(JSON.stringify(data))

            if(res.data.status == "success") {
                dispatch(toast("success", "Deleted from purchases")) 
            }

            return res.data 
        } catch (err) {
            handleError(dispatch, err) 
            return rejectWithValue(err.message)
        } 
    } 
)

export const apiGetBody = createAsyncThunk(  
    "account/apiGetBody",
    async (data, { dispatch, rejectWithValue }) => {
        try {
            const res = await accountApi.GetBody(JSON.stringify(data))

            const contentType = res.headers['content-type']

            if (contentType && contentType.includes('application/json')) {
                const reader = new FileReader()
                return new Promise((resolve) => {
                    reader.onload = () => {
                    const json = JSON.parse(reader.result);
                        resolve(json)
                    };
                    reader.readAsText(res.data)
                })
            } else {
                const href = URL.createObjectURL(res.data)

                const link = document.createElement('a')
                link.href = href
    
                const contentDispositionHeader = res.headers['content-disposition']
    
                var fileNameWithQuotes = contentDispositionHeader.split('=')[1]
    
                const fileName = fileNameWithQuotes.replace(/"/g, '')
    
                link.setAttribute('download', fileName)
    
                document.body.appendChild(link)
                link.click()
    
                document.body.removeChild(link)
                URL.revokeObjectURL(href)

                return res.data 
            }
        } catch (err) {
            handleError(dispatch, err)
            return rejectWithValue(err.message)
        } 
    } 
)

export const apiAuthorUpload = createAsyncThunk( 
    "account/apiAuthorUpload",
    async (data, { dispatch, rejectWithValue }) => {
        console.log(data) 
        try { 
            const res = await accountApi.AuthorUpload(data)

            return res.data 
        } catch (err) {
            handleError(dispatch, err)
            return rejectWithValue(err.message) 
        } 
    }  
)

export const apiRegisterAuthor = createAsyncThunk( 
    "account/apiRegisterAuthor",
    async (data, { dispatch, rejectWithValue }) => {
        try {
            const res = await accountApi.RegisterAuthor(JSON.stringify(data)) 
            console.log(res.data)

            return res.data 
        } catch (err) {
            handleError(dispatch, err)
            return rejectWithValue(err.message)
        } 
    } 
)

export const apiGetAuthorTemplates = createAsyncThunk(
    "account/apiGetAuthorTemplates",
    async (data, { dispatch, rejectWithValue }) => {
        console.log(JSON.stringify(data))
        try {
            const res = await accountApi.GetAuthorTemplates(JSON.stringify(data))
            console.log("apiGetAuthorTemplates", res.data)

            return res.data 
        } catch (err) {
            handleError(dispatch, err) 
            return rejectWithValue(err.message)
        } 
    } 
)

export const apiGetAuthorStatus = createAsyncThunk(
    "account/apiGetAuthorStatus",
    async (_, { dispatch, rejectWithValue }) => {
        try {
            console.log("GettingAuthorStatus")
            const res = await accountApi.GetAuthorStatus()
            console.log("GetAuthorStatus", res.data)

            return res.data 
        } catch (err) {
            handleError(dispatch, err)
            return rejectWithValue(err.message)
        } 
    } 
)

export const apiDeleteAuthorProduct = createAsyncThunk(
    "account/apiDeleteAuthorProduct",
    async (data, { dispatch, rejectWithValue }) => {
        try {
            const res = await accountApi.DeleteAuthorProduct(JSON.stringify(data))
 
            if(res.data.status == "success") {
                dispatch(toast("success", "Deleted from uploads"))  
            }

            return res.data 
        } catch (err) {
            handleError(dispatch, err)
            return rejectWithValue(err.message)
        } 
    } 
)

export const apiAuthorGetInfo = createAsyncThunk(
    "account/apiAuthorGetInfo",
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const res = await accountApi.AuthorGetInfo()
            console.log("apiAuthorGetInfo", res.data)

            return res.data 
        } catch (err) {
            handleError(dispatch, err)
            return rejectWithValue(err.message)
        } 
    } 
)

export const apiGetAuthorOfferUploadsStatus = createAsyncThunk(
    "account/apiGetAuthorOfferUploadsStatus",
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const res = await accountApi.apiGetAuthorOfferUploadsStatus()
            console.log("apiGetAuthorOfferUploadsStatus", res.data)

            return res.data 
        } catch (err) {
            handleError(dispatch, err)
            return rejectWithValue(err.message)
        } 
    } 
)

export const apiGetAuthorStripeLink = createAsyncThunk(
    "account/apiPayForProduct",
    async (data, { dispatch, rejectWithValue }) => {
        try {
            const res = await accountApi.GetAuthorStripeLink()

            window.location.href = res.data.data.url

            return ({res: res.data, point: data.point})
        } catch (err) {
            handleError(dispatch, err)
            return rejectWithValue({message: err.message, point: data.point})
        } 
    } 
)


export const apiDeleteAccount = createAsyncThunk(
    "account/apiDeleteAccount",
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const res = await accountApi.DeleteAccount()

            if(res.data.status == "success") {
                dispatch(toast("secondary", "Account deleted"))
            }

            return res.data
        } catch (err) {
            handleError(dispatch, err)
            return rejectWithValue(err.message)
        }
    }
)

export const apiDeleteAuthor = createAsyncThunk(
    "account/apiDeleteAuthor",
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const res = await accountApi.DeleteAuthor()

            if(res.data.status == "success") {
                dispatch(toast("secondary", "Author account deleted"))
            }

            return res.data
        } catch (err) {
            handleError(dispatch, err)
            return rejectWithValue(err.message)
        }
    }
)

export const apiChangePassword = createAsyncThunk(
    "account/apiChangePassword",
    async (data, { dispatch, rejectWithValue }) => {
        try {
            const res = await accountApi.ChangePassword(data)

            return res.data
        } catch (err) {
            handleError(dispatch, err)
            return rejectWithValue(err.message)
        }
    }
)

export const apiSupport = createAsyncThunk(
    "account/apiSupport",
    async (data, { dispatch, rejectWithValue }) => {
        try {
            const res = await accountApi.Support(data)

            return res.data
        } catch (err) {
            handleError(dispatch, err)
            return rejectWithValue(err.message)
        }
    }
)

export const { setIsProfileFetch, setDownloadingId, setIsAuthorFetch } = accountSlice.actions
export default accountSlice.reducer