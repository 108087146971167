import React, { createContext, useEffect, useState } from "react"
import Purchased from "./purchased.jsx"
import { useDispatch, useSelector } from "react-redux"
import { apiGetPurchases, apiPurchaseDelete, apiGetBody, setDownloadingId } from "../../../../store/slices/accountSlice.js"
import useAuthRedirect from "../../../../middleware/isAuth.jsx"
import { useSearchParams } from "react-router-dom"

const Context = createContext()

const PurchasedContainer = () => {

    const { isAuthDispatch } = useAuthRedirect()

    const dispatch = useDispatch()

    const [moreModalShow, setMoreModalShow] = useState({ id: "", name: "", show: false });
    const [confirmDeletion, setConfirmDeletion] = useState(false); 

    const onShowMoreModal = (id, name) => {
        setMoreModalShow({ id, name, show: true })
    }

    const onHideMoreModal = (id, name) => {
        setConfirmDeletion(false) 
        setMoreModalShow({ id, name, show: false })
    }

    const {
        purchases: { products, limit, count, productsNotFound, downloadingId,
            connection: { isPurchasesFetch, isPurchaseDeletingFetch, isDownloadingFetch },
            placeholders: { purchasesPlaceholders }
        },
    } = useSelector((state) => state.account);

    const [searchParams, setSearchParams] = useSearchParams()

    let queryPage = searchParams.get("page")

    let pagesCount = Math.ceil(count / limit) 

    const offset = (+queryPage - 1) * limit

    let pages = [];
    for (let i = 1; i <= pagesCount; i++) {
        pages.push(i);
    }

    function pageFirst() {
        setSearchParams({ page: 1 })
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
    }

    function pagePrev() {
        setSearchParams({ page: +queryPage - 1 })
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
    }

    function pageSelect(p) {
        setSearchParams({ page: queryPage = p })
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
    }

    function pageNext() {
        setSearchParams({ page: +queryPage + 1 })
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
    }

    function pageLast() { 
        setSearchParams({ page: pagesCount })
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
    }

    const dispatchApiGetPurchases = () => {
        isAuthDispatch(apiGetPurchases, {limit, offset})
    }

    const dispatchApiPurchaseDelete = async (id) => {
        setConfirmDeletion(false)
        await isAuthDispatch(apiPurchaseDelete, { id })
        setMoreModalShow({ id: '', name: '', show: false })
        dispatchApiGetPurchases() 
    }

    const dispatchApiGetBody = (id) => { 
        dispatch(setDownloadingId(id)) 
        isAuthDispatch(apiGetBody, { id })
    }

    useEffect(() => {
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
        dispatchApiGetPurchases()
    }, [queryPage])

    const contextData = {
        products, productsNotFound, isPurchasesFetch, moreModalShow, setMoreModalShow, pages,
        pageFirst, pagePrev, pageSelect, pageNext, pageLast, queryPage, dispatchApiPurchaseDelete,
        isPurchaseDeletingFetch, purchasesPlaceholders, confirmDeletion, setConfirmDeletion,
        onShowMoreModal, onHideMoreModal, isDownloadingFetch, setDownloadingId, dispatchApiGetBody, downloadingId
    }

    return (
        <Context.Provider value={contextData}>
            <Purchased />
        </Context.Provider>
    )
}

export { Context }
export default PurchasedContainer