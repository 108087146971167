import React, { createContext, useEffect, useState } from "react"
import TemplatesAuthor from "./templates_author.jsx"
import { useDispatch, useSelector } from "react-redux"
import { apiGetUploadInfo, apiAddUpload, apiRejectUpload, apiGetFile } from "../../../../../store/slices/adminSlice.js"
import useAuthRedirect from "../../../../../middleware/isAdminAuth.jsx"
import { useNavigate, useSearchParams } from "react-router-dom"
import { unwrapResult } from "@reduxjs/toolkit"
import { toast } from "../../../../../store/slices/toastsSlice.js"

const Context = createContext()
 
const TemplatesAuthorContainer = () => {

    const dispatch = useDispatch()

    const { isAuthDispatch } = useAuthRedirect()
 
    const navigate = useNavigate()
 
    const {
        products: {
            navigation: {
                types, topics
            }
        }
    } = useSelector((state) => state.categories);

    const {
        uploads: {
            info,
            connection: {
                isUploadInfoFetch,
                isConfirmingFetch,
                isUploadRejectingFetch
            }
        }
    } = useSelector((state) => state.admin);

    const { 
        form: {
            max_price, min_price, max_discount, max_description, max_file_bytes, max_file_megabytes, file_extension, max_product_name
        }
    } = useSelector((state) => state.constants)

    const [searchParams] = useSearchParams()

    let id_params = Number(searchParams.get("id")) 
 
    const [form, setForm] = useState({})
    const [errors, setErrors] = useState({})
 
    const dispatchApiGetUploadInfo = () => {
        return isAuthDispatch(apiGetUploadInfo, {id: id_params})
    }
 
    const dispatchApiAddUpload = (form) => {
        return isAuthDispatch(apiAddUpload, form)
    }

    const dispatchApiGetFile = (file, id) => {
        return isAuthDispatch(apiGetFile, { file, id })
    }

    const dispatchApiRejectUpload = async (id) => { 
        await isAuthDispatch(apiRejectUpload, { id })
        navigate('/admin/authors?page=1')
    }

    const pageLoad = async () => {
        const actionRes = await dispatchApiGetUploadInfo()
        const promiseRes = await unwrapResult(actionRes)

        const info = promiseRes.data

        const file = new File([''], info.file, {
            type: 'text/plain',
            lastModified: new Date(),
        }) 

        setForm({
            name: info.name, 
            price: info.price,
            file: file,
            version: info.version,
            discount: 0,
            type_id: info.type_id,
            topic_id: info.topic_id,
            description: info.description,
            user_id: info.user_id
        });

        const input = document.getElementById('file');

        const dataTransfer = new DataTransfer();
        dataTransfer.items.add(file);
        input.files = dataTransfer.files;

        if (input.webkitEntries.length) {
            input.dataset.file = `${dataTransfer.files[0].name}`;
        }
    }

    const setField = (field, value) => {
        setForm({
            ...form,
            [field]: value
        });

        const fieldErrors = validateField(field, value);

        setErrors({
            ...errors,
            [field]: fieldErrors[field]
        });
    }

    const validateField = (field, value) => {
        const newError = {};

        switch (field) {  
            case 'name':
                if (value === undefined || value === '') newError.name = 'Please enter name'
                else if (value && value.length > max_product_name) newError.name = `The name must be less than ${max_product_name} characters.`
                break;
            case 'price':
                if (value === undefined || value === '') newError.price = 'Please enter price'
                else if (value && value > max_price) newError.price = `The price must be less than ${max_price} dollars.`
                else if (value && value < min_price) newError.price = `The price must be more than ${min_price} dollars.`
                else if (value && value <= 0) newError.price = 'The price cannot be equal to zero or less than it.'
                break;
            case 'discount':
                if (value === undefined || value === '') newError.discount = 'Please select discount'
                else if (value && value > max_discount) newError.discount = `Discount must be less than ${max_discount}%.`
                else if (value && form.price - (form.price * value / 100) < min_price) newError.discount = `The price with discount must be more than ${min_price} dollars.`
                else if (value < 0) newError.discount = 'Discount cannot be less than zero.'
                break;
            case 'file':
                if (value === undefined || value === '') newError.file = 'Please select file'
                else if (value) {
                    const even = (el) => value.name.split('.').pop() == el; 
                    if(!file_extension.some(even)) newError.file = 'Only certain archives accepted(.zip, .rar, .7z)'
                }
                else if (value && value.size > max_file_bytes) newError.file = `The file size should not be more than ${max_file_megabytes} megabytes`
                break;
            case 'img':
                if (value === undefined || value === '') newError.img = 'Please select image' 
                else if (!value.type.startsWith("image/")) newError.img = 'Only images accepted(.jpeg, .png, etc)'
                break;
            case 'img_preview':
                if (value === undefined || value === '') newError.img_preview = 'Please select image preview'
                else if (!value.type.startsWith("image/")) newError.img_preview = 'Only images accepted(.jpeg, .png, etc)'
                break;
            case 'description':
                if (value && value.length > max_description) newError.description = `Description must be less than ${max_description} characters.`
                break;
            case 'user_id':
                if (value === undefined || value === '') newError.user_id = 'Please select user id'
                else if (value && value < 0) newError.user_id = 'User id cannot be less than zero.'
                break;
        }

        return newError;
    }

    const validateForm = () => {
        const newErrors = {};

        const fields = ['name', 'price', 'discount', 'file', 'img', 'img_preview', 'description'];

        fields.forEach(field => {
            const fieldErrors = validateField(field, form[field]);
            Object.assign(newErrors, fieldErrors);
        });

        return newErrors;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation(); 

        const formErrors = validateForm()

        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors)
            dispatch(toast("warning", "Invalid form"))
        } else {
            const formData = new FormData();
            for (let key in form) {
                if (key == 'file') {
                    form[key].size != 0 && formData.append(key, form[key])
                } else formData.append(key, form[key]) 
            }
            const actionRes = await dispatchApiAddUpload(formData)
            const promiseRes = unwrapResult(actionRes)
            if(promiseRes.status == "success") {
                navigate('/admin/authors?page=1')
                dispatch(toast("success", "Upload confirmed"))
            } 
        } 
    }

    useEffect(() => {
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
        pageLoad()
    }, [])

    const contextData = { handleSubmit, form, errors, setField, types, topics, info, isUploadInfoFetch, isConfirmingFetch,
        dispatchApiRejectUpload, isUploadRejectingFetch, dispatchApiGetFile
     }
   
    return (
        <Context.Provider value={contextData}>
            <TemplatesAuthor />
        </Context.Provider>
    )
}

export { Context }
export default TemplatesAuthorContainer