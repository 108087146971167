import React, { createContext, useEffect, useState } from "react"
import Settings from "./settings.jsx"
import { apiDeleteAccount, apiChangePassword, apiDeleteAuthor } from "../../../../store/slices/accountSlice.js"
import { useDispatch, useSelector } from "react-redux"
import useAuthRedirect from "../../../../middleware/isAuth.jsx"
import { unwrapResult } from "@reduxjs/toolkit"
import { useNavigate } from "react-router-dom"
import { setIsAuth, setIsAuthorRegistered } from "../../../../store/slices/authSlice.js"
import { toast } from "../../../../store/slices/toastsSlice.js"

const Context = createContext() 

const useChangePassword = (isAuthDispatch, dispatch, max_password, min_password, auth_method) => {
   
    const [form, setForm] = useState({})
    const [errors, setErrors] = useState({})

    const [isCollapseShow, setCollapseShow] = useState(false);

    const handleCollapseShow = (bool) => {
        if(auth_method != "default") {
            dispatch(toast("warning", "This account's authorization method does not allow changing passwords"))
        } else {
            setCollapseShow(bool)
        }
    }

    const setField = (field, value) => {
        setForm({
            ...form,
            [field]: value
        });

        const fieldErrors = validateField(field, value);

        setErrors({
            ...errors,
            [field]: fieldErrors[field]
        });
    }

    const validateField = (field, value) => {
        const newError = {};

        switch (field) {
            case "old_password":
                if (value == undefined || value == "") newError.old_password = 'Please enter old password' 
                else if (value && value.length < min_password) newError.old_password = `Old password must be more than ${min_password} characters.`
                else if (value && value.length > max_password) newError.old_password = `Old password must be less than ${max_password} characters.`
                break; 
            case "new_password":
                if (value == undefined || value == "") newError.new_password = 'Please enter new password' 
                else if (value && value.length < min_password) newError.new_password = `Old password must be more than ${min_password} characters.`
                else if (value && value.length > max_password) newError.new_password = `Old password must be less than ${max_password} characters.`
                break;
            case "repeat_new_password":
                if (value == undefined || value == "") newError.repeat_new_password = 'Please enter new password' 
                else if (value && value.length < min_password) newError.repeat_new_password = `New password must be more than ${min_password} characters.`
                else if (value && value.length > max_password) newError.repeat_new_password = `New password must be less than ${max_password} characters.`
                break;
        }

        return newError;
    }

    const validateForm = () => {
        const newErrors = {};

        if (form.new_password == form.old_password && form.old_password != undefined && form.old_password != "" && form.new_password != undefined && form.new_password != "") {
            Object.assign(newErrors, { new_password: "Old and new password must not match" });
        } else if ((form.old_password != undefined && form.old_password != "") && (form.new_password == undefined || form.new_password == "")) {
            Object.assign(newErrors, { new_password: "Please enter new password" });
        } else if ((form.new_password != undefined && form.new_password != "") && (form.old_password == undefined || form.old_password == "")) {
            Object.assign(newErrors, { old_password: "Please enter old password" });
        } else if (form.new_password != form.repeat_new_password) {
            Object.assign(newErrors, { repeat_new_password: "New passwords must match" });
        } else {
            delete errors.new_password
            delete errors.old_password
            delete errors.repeat_new_password
        }

        const fields = ["new_password", "old_password", "repeat_new_password"];

        fields.forEach(field => {
            const fieldErrors = validateField(field, form[field]);
            Object.assign(newErrors, fieldErrors);
        });

        return newErrors;
    }

    const dispatchApiChangePassword = () => { 
        return isAuthDispatch(apiChangePassword, form)
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        const formErrors = validateForm()

        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors)
            dispatch(toast("warning", "Invalid form"))
        } else {
            const actionRes = await dispatchApiChangePassword()
            const promiseRes = unwrapResult(actionRes)

            const setError = (err) => {
                setErrors(err)
                dispatch(toast("warning", "Invalid form"))
            }

            switch (promiseRes.status) {
                case "incorrect_password":
                    setError({old_password: "Incorrect password"})
                    break
                case "success":
                    handleCollapseShow(false)
                    dispatch(toast("success", "Password changed"))
                    break
            }
        } 
    }

    useEffect(() => {
        if (!isCollapseShow) { 
            setForm({old_password: "", new_password: "", repeat_new_password: ""})
            setErrors({}) 
        }
        
    }, [isCollapseShow])
    
    return {
        formChangePassword: form,
        errorsChangePassword: errors,
        handleSubmitChangePassword: handleSubmit,
        handleCollapseShowChangePassword: handleCollapseShow,
        setFieldChangePassword: setField,
        isCollapseShowChangePassword: isCollapseShow
    }
}

const useDeleteAuthor = (isAuthDispatch, dispatch, navigate, email, isAuthorRegistered) => {

    const [form, setForm] = useState({})
    const [errors, setErrors] = useState({})

    const setField = (field, value) => {
        setForm({
            ...form,
            [field]: value
        });

        const fieldErrors = validateField(field, value);

        setErrors({
            ...errors,
            [field]: fieldErrors[field]
        });
    }

    const validateField = (field, value) => {
        const newError = {};

        switch (field) {
            case 'email':
                if (value == undefined || value == '') newError.email = 'Please enter email'
                else if (value && !value.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) newError.email = 'The entered email is not valid'
                break;
        }

        return newError;
    }

    const validateForm = () => {
        const newErrors = {};

        const fields = ['email'];

        fields.forEach(field => {
            const fieldErrors = validateField(field, form[field]);
            Object.assign(newErrors, fieldErrors);
        });

        return newErrors;
    }
    
    const [confirmAuthorDeletion, setConfirmAuthorDeletion] = useState(false);

    const handleConfirmAuthorDeletion = (bool) => {
        if(!isAuthorRegistered) {
            dispatch(toast("warning", "The author's account does not exist"))
        } else {
            setConfirmAuthorDeletion(bool)
        } 
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation(); 

        const formErrors = validateForm()

        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors)
            dispatch(toast("warning", "Invalid form"))
        } else if(form.email == email) {
            setConfirmAuthorDeletion(false)
            const actionRes = await isAuthDispatch(apiDeleteAuthor)
            const promiseRes = unwrapResult(actionRes)
            if(promiseRes.status == "success") { 
                dispatch(setIsAuthorRegistered(false))
                navigate("/account?likes=1")
            }
        } else {
            setErrors({email: "Email does not match"})
            dispatch(toast("warning", "Invalid form"))
        }
    }

    useEffect(() => {
        if (!confirmAuthorDeletion) { 
            setForm({email: ""})
            setErrors({}) 
        }
        
    }, [confirmAuthorDeletion])

    return {
        formDeleteAuthor: form,
        errorsDeleteAuthor: errors,
        handleSubmitDeleteAuthor: handleSubmit,
        setFieldDeleteAuthor: setField,
        confirmAuthorDeletion,
        handleConfirmAuthorDeletion,
    }
}

const useDeleteAccount = (isAuthDispatch, dispatch, navigate, email) => {

    const [form, setForm] = useState({})
    const [errors, setErrors] = useState({})

    const setField = (field, value) => {
        setForm({
            ...form,
            [field]: value
        });

        const fieldErrors = validateField(field, value);

        setErrors({
            ...errors,
            [field]: fieldErrors[field]
        });
    }

    const validateField = (field, value) => {
        const newError = {};

        switch (field) {
            case 'email':
                if (value == undefined || value == '') newError.email = 'Please enter email'
                else if (value && !value.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) newError.email = 'The entered email is not valid'
                break;
        }

        return newError;
    }

    const validateForm = () => {
        const newErrors = {};

        const fields = ['email'];

        fields.forEach(field => {
            const fieldErrors = validateField(field, form[field]);
            Object.assign(newErrors, fieldErrors);
        });

        return newErrors;
    }
    
    const [confirmAccountDeletion, setConfirmAccountDeletion] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        const formErrors = validateForm()

        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors)
            dispatch(toast("warning", "Invalid form"))
        } else if(form.email == email) {
            setConfirmAccountDeletion(false)
            const actionRes = await isAuthDispatch(apiDeleteAccount)
            const promiseRes = unwrapResult(actionRes)
            if(promiseRes.status == "success") { 
                dispatch(setIsAuth(false))
                navigate("/register")
            }
        } else {
            setErrors({email: "Email does not match"})
            dispatch(toast("warning", "Invalid form"))
        }
    }

    useEffect(() => {
        if (!setConfirmAccountDeletion) { 
            setForm({email: ""})
            setErrors({}) 
        }
        
    }, [setConfirmAccountDeletion])

    return {
        formDeleteAccount: form,
        errorsDeleteAccount: errors,
        handleSubmitDeleteAccount: handleSubmit,
        setFieldDeleteAccount: setField,
        confirmAccountDeletion,
        setConfirmAccountDeletion,
    }
}

const SettingsContainer = () => { 

    const { isAuthDispatch } = useAuthRedirect()

    const navigate = useNavigate()

    const dispatch = useDispatch()

    const {
        settings: { connection: { isAccountDeletingFetch, isAuthorDeletingFetch, isChangingPasswordFetch } }
    } = useSelector((state) => state.account);

    const { 
        form: {
            max_password, min_password,
        }
    } = useSelector((state) => state.constants)

    const {
        auth_method, email, isAuthorRegistered
    } = useSelector((state) => state.auth) 

    const {  formChangePassword, errorsChangePassword, handleSubmitChangePassword, handleCollapseShowChangePassword,
        setFieldChangePassword, isCollapseShowChangePassword } = useChangePassword(isAuthDispatch, dispatch, max_password, min_password, auth_method) 

    const { formDeleteAuthor, errorsDeleteAuthor, handleSubmitDeleteAuthor, setFieldDeleteAuthor, confirmAuthorDeletion, handleConfirmAuthorDeletion } =
    useDeleteAuthor(isAuthDispatch, dispatch, navigate, email, isAuthorRegistered)

    const { formDeleteAccount, errorsDeleteAccount, handleSubmitDeleteAccount, setFieldDeleteAccount, confirmAccountDeletion, setConfirmAccountDeletion } =
    useDeleteAccount(isAuthDispatch, dispatch, navigate, email)

    const contextData = { isAccountDeletingFetch, isChangingPasswordFetch, isAuthorDeletingFetch,
        formChangePassword, errorsChangePassword, handleSubmitChangePassword, handleCollapseShowChangePassword, setFieldChangePassword, isCollapseShowChangePassword,
        formDeleteAuthor, errorsDeleteAuthor, handleSubmitDeleteAuthor, setFieldDeleteAuthor, confirmAuthorDeletion, handleConfirmAuthorDeletion,
        formDeleteAccount, errorsDeleteAccount, handleSubmitDeleteAccount, setFieldDeleteAccount, confirmAccountDeletion, setConfirmAccountDeletion
     }

     useEffect(() => {
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
    }, [])

    return (
        <Context.Provider value={contextData}>
            <Settings />
        </Context.Provider>
    )
}

export { Context }
export default SettingsContainer