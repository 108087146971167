import React, { useEffect, useState, createContext } from "react";
import Support from "./support.jsx"
import { apiGetSupport, apiDeleteSupport } from "../../../../store/slices/adminSlice.js"
import { useDispatch, useSelector } from "react-redux";
import { toast } from "../../../../store/slices/toastsSlice.js";
import useAuthRedirect from "../../../../middleware/isAdminAuth.jsx";
import { useSearchParams } from "react-router-dom";

const Context = createContext()

const SupportContainer = () => {

    const { isAuthDispatch } = useAuthRedirect() 

    const dispatch = useDispatch()

    const {
        support: {
            messages, limit, count, messagesNotFound,
            connection: {
                isSupportFetch,
                isDeletingFetch
            }
        }
    } = useSelector((state) => state.admin)

    const [messageModalShow, setMessageModalShow] = useState({ id: '', email: '', description: '', user_id: "", show: false });

    const [moreModalShow, setMoreModalShow] = useState({ id: "", email: "", user_id: "", show: false });
    const [confirmDeletion, setConfirmDeletion] = useState(false); 

    const onShowMoreModal = (id, email, user_id) => {
        setMoreModalShow({ id, email, user_id, show: true })
    }

    const onHideMoreModal = (id, email, user_id) => {
        setConfirmDeletion(false) 
        setMoreModalShow({ id, email, user_id, show: false })
    }

    const [searchParams, setSearchParams] = useSearchParams()

    let queryPage = searchParams.get("page")

    let pagesCount = Math.ceil(count / limit)

    const offset = (+queryPage - 1) * limit

    let pages = [];
    for (let i = 1; i <= pagesCount; i++) {
        pages.push(i);
    }

    function pageFirst() {
        setSearchParams({ page: 1 })
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
    }

    function pagePrev() {
        setSearchParams({ page: +queryPage - 1 })
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
    }

    function pageSelect(p) {
        setSearchParams({ page: queryPage = p })
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
    }

    function pageNext() {
        setSearchParams({ page: +queryPage + 1 })
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
    }

    function pageLast() {
        setSearchParams({ page: pagesCount })
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
    }

    const dispatchApiGetSupport = () => {
        return isAuthDispatch(apiGetSupport, { offset, limit })
    }

    const dispatchApiDeleteSupport = async (id) => {
        setConfirmDeletion(false)
        await isAuthDispatch(apiDeleteSupport, { id })
        setMoreModalShow({ id: "", email: "", user_id: "", show: false })
        setMessageModalShow({ id: "", email: "", description: "", user_id: "", show: false })
        dispatchApiGetSupport()
    }  

    const dispatchToast = (bg, text) => {
        dispatch(toast(bg, text))
    }

    useEffect(() => { 
        setTimeout(() => {window.scrollTo(0, 0)}, 0)
        dispatchApiGetSupport()  
    }, [queryPage])

    const contextData = {
        dispatchApiDeleteSupport, pageFirst, pagePrev, pageSelect, pageNext, pageLast, queryPage, pages, messages, 
        messagesNotFound, isDeletingFetch, isSupportFetch, moreModalShow, confirmDeletion, setConfirmDeletion, 
        onShowMoreModal, onHideMoreModal, messageModalShow, setMessageModalShow, dispatchToast, setMoreModalShow
    }

    return (
        <Context.Provider value={contextData}>
            <Support />
        </Context.Provider>
    )
}

export { Context }
export default SupportContainer

