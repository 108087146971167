import React, { useContext } from "react"
import { Context } from "../templates_container.jsx"
import css from "./notification.module.css"
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { Link } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';

const Notification = () => {

    const { dispatchApiGetAuthorStripeLink, status, isOnboardingFetch } = useContext(Context)
    
    switch (status) {
        case "information_is_required_to_enable_capabilities":
            return (
                <Alert className={css.alert} variant="primary">
                    Information is required to enable capabilities.
                    <Button disabled={isOnboardingFetch} className={css.btn} onClick={() => dispatchApiGetAuthorStripeLink("notification")} variant="primary">Get started{isOnboardingFetch && <Spinner size="sm"/>}</Button>
                </Alert>
            ) 
        case "enabled.information_is_required":
            return (
                <Alert className={css.alert} variant="warning">
                    Information is required.
                    <Button disabled={isOnboardingFetch} className={css.btn} onClick={() => dispatchApiGetAuthorStripeLink("notification")} variant="warning">Get started{isOnboardingFetch && <Spinner size="sm"/>}</Button>
                </Alert> 
            )
        case "checking_the_list_of_prohibited_persons_or_companies":
            return (
                <Alert className={css.alert} variant="info">
                    Checking the list of prohibited persons or companies. It will take some time.
                    <Button as={Link} to="/support" className={css.btn} variant="info">Support</Button>
                </Alert>
            )
        case "information_is_verified":
            return (
                <Alert className={css.alert} variant="info">
                    Information is verified. It will take some time.
                    <Button as={Link} to="/support" className={css.btn} variant="info">Support</Button>
                </Alert>
            )
        case "information_is_under_review":
            return (
                <Alert className={css.alert} variant="info">
                    Information is under review. It will take some time.
                    <Button as={Link} to="/support" className={css.btn} variant="info">Support</Button>
                </Alert>
            )
        case "platform_paused":
            return (
                <Alert className={css.alert} variant="info">
                    Platform paused. It will take some time.
                    <Button as={Link} to="/support" className={css.btn} variant="info">Support</Button>
                </Alert>
            )
        case "rejected":
            return (
                <Alert className={css.alert} variant="danger">
                    The author's account has been rejected.
                    <Button as={Link} to="/support" className={css.btn} variant="danger">Support</Button>
                </Alert>
            )
    }
}


export default Notification   