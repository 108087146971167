import React, { useContext } from "react"
import { Context } from "../categories_container"
import css from "./categories_products_map.module.css"
import { CartFill, Percent } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Ratio from 'react-bootstrap/Ratio';
import { CurrencyDollar } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom' 
import Placeholder from 'react-bootstrap/Placeholder';
import Spinner from 'react-bootstrap/Spinner';
import Pagination from 'react-bootstrap/Pagination';

const CategoriesProductsMap = () => {

    const { dispatchApiAddProductToBasket, dispatchSetAddingId, products, isProductsFetch, productsPlaceholders,
        addingId, isAddingFetch, pagePrev, pageSelect, pageNext, pageLast, pages, pageFirst, queryPage } = useContext(Context)

    return ( 
        !isProductsFetch ?
            <>
                <section className={css.cards}>
                    {products?.map((p, i) =>
                        <Card key={i} className={css.card}>
                            <Ratio aspectRatio="16x9">
                                <Card.Img variant="top" src={`https://storageplatform0.blob.core.windows.net/products/${p.id}/image/${p.img}?${new Date().getTime()}`} />
                            </Ratio>
                            <Card.Body> 
                                <Card.Title className={css.name}>{p.name}</Card.Title>
                                <Card.Text className={css.author}><i>{p.author != "" ? "by " + p.author : "no author specified"}</i></Card.Text>
                                <div className={css.info_wrap}>
                                    <Button as={Link} className="btn-prime" to={`/categories/product?id=${p.id}`}>Info</Button>
                                    <Button disabled={isAddingFetch} onClick={() => { dispatchApiAddProductToBasket(p.id); dispatchSetAddingId(p.id) }} className={`btn-second ${css.basket_btn}`}>{isAddingFetch ? addingId == p.id ? <Spinner size="sm" animation="border" /> : <CartFill size="1.25rem" className={css.basket_img} /> : <CartFill size="1.25rem" className={css.basket_img} />}</Button>
                                    <div className={css.price}>{p.discount != 0 ? p.price_with_discount.toString().replace(".", ",") : p.price.toString().replace(".", ",")}<CurrencyDollar size={"1rem"} /></div>
                                </div>
                            </Card.Body>
                            {p.discount != 0 && <span className={css.discount}>-{p.discount}<Percent size={"1.25rem"} /></span>}
                        </Card>
                    )}
                </section>
                <section className={css.pagination}>
                    <Pagination>
                        {queryPage > 1 &&
                            <>
                                <Pagination.First className={css.pag_item} onClick={() => pageFirst()} /> 
                                <Pagination.Prev className={css.pag_item} onClick={() => pagePrev()} />
                            </>
                        } 
                        {pages
                            .filter(p => p >= +queryPage - 2 && p <= +queryPage + 2)
                            .map(p => (
                                <Pagination.Item className={queryPage == p ? css.pag_item_active : css.pag_item} onClick={() => pageSelect(p)} key={p}>{p}</Pagination.Item>
                            ))
                        } 
                        {queryPage < pages.length &&
                            <>
                                <Pagination.Next className={css.pag_item} onClick={() => pageNext()} />
                                <Pagination.Last className={css.pag_item} onClick={() => pageLast()} />
                            </>
                        }
                    </Pagination>
                </section>
            </>
            :
            <>
                <section className={css.cards}>
                    {Array.from({ length: productsPlaceholders }).map((_, i) =>
                        <Card key={i} className={css.card}>
                            <Placeholder animation="glow">
                                <Ratio aspectRatio="16x9">
                                    <Placeholder className={css.img_placeholder} />
                                </Ratio>
                            </Placeholder>
                            <Card.Body>
                                <Placeholder className={css.name} as={Card.Title} animation="glow">
                                    <Placeholder xs={9} />
                                </Placeholder>
                                <Placeholder className={css.author} as={Card.Text} animation="glow">
                                    <Placeholder xs={5} />
                                </Placeholder>
                                <div className={css.info_wrap}>
                                    <Placeholder.Button className="btn-prime">Info</Placeholder.Button>
                                    <Placeholder.Button className={`btn-second ${css.basket_btn}`}><CartFill size="1.25rem" className={css.basket_img} /></Placeholder.Button>
                                    <Placeholder className={css.price} animation="glow">
                                        <Placeholder className={css.price_placeholder} />
                                    </Placeholder>
                                </div>
                            </Card.Body>
                        </Card>
                    )}
                </section>
                <section className={css.pagination}>
                    <Pagination>
                        <Pagination.Item disabled className={css.pag_item}>1</Pagination.Item>
                    </Pagination>
                </section>
            </>
    )
}

export default CategoriesProductsMap